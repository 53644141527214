import { ModalNL } from "@components/modal/Modal"
import LinePlot from "@components/plot/LinePlot"
import PlotConfig from "@components/plot/plotConfig/PlotConfig"
import { ImportantIcon } from "@helpers/ImportantIcon"
import MultiSelectWrapper from "@pages/DatabasePage/MultiSelectWrapper"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { getStateAliases } from "@redux/features/stateAliases/stateAliasesSlice"
import { useSensorsGroupContext } from "@sop/HistoricalDataPage/sensorsGroupContext"
import { IconButton, LoadingCircle, Tooltip } from "@ui-lib/src"
import { TimeseriesDataRange } from "@utilityTypes/timeseriesDefs"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { common } from "@utils/common"
import { getSensorsByAsset } from "@utils/getSensorsByAsset"
import { requestApi2 } from "@utils/http"
import {
	getTimeseriesQueryKeys,
	LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS,
	useGetTimeseriesQuery,
} from "@utils/queries/useGetTimeseriesQuery"
import {
	AggregationConfig,
	AggregationMethod,
	createIntervalOffset,
	createIntervalOffsetV2,
	endpoints,
	GetOpModeEventsArgs,
	GetTimeseriesDataArgs,
	PlantPlot,
	PlantPlotSignal,
	Timeseries,
	toIntervalV2,
} from "api"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
	MdCheck,
	MdClose,
	MdDelete,
	MdInfoOutline,
	MdSettings,
	MdStackedLineChart,
} from "react-icons/md"
import { useQueryClient } from "react-query"
import PlotHeading from "./PlotHeading"
import PlotRangeSelection from "./PlotRangeSelection"
import { RDP } from "./decimation"
import {
	getOpModeIntervalsQueryKeys,
	useGetOpModeIntervalsQuery,
} from "./useGetOpModeIntervalsQuery"
import { PlotData } from "./utils"

const MAX_TIMESERIES_SIZE = 5000
export type RangeType = "12h" | "24h" | "7days" | "custom"

export type DataDbType = {
	x: number
	y: number
}[][][]

export type SeriesCopyType = {
	x: number
	y: number
}[][]

export type SeriesConfigObj = {
	name: string
	unit: string
	series: {}
}

export type MultiSelectItem = {
	value: string
	label: string
}

export type PlotCardProps = {
	companyName: string
	componentName: string
	fetchPlantPlots: () => Promise<void>
	fetch_interval?: number
	margin?: string
	plantName: string
	plotData: PlantPlot
	width?: string
	sensorsGroupId?: number | null
	hideRangeSelector?: boolean
	listOfModeColors?: {
		[modeName: string]: string
	}
	from?: Date
	to?: Date
}

const MAX_NUMBER_SIGNALS = 6

export default function PlotCard({
	companyName,
	componentName,
	fetchPlantPlots,
	fetch_interval = 60000,
	margin = "calc(50vw - 470px)",
	plantName,
	plotData,
	width = "940px",
	sensorsGroupId = null,
	hideRangeSelector = false,
	listOfModeColors,
	from,
	to,
}: PlotCardProps) {
	const queryClient = useQueryClient()

	const { t } = useTranslation()
	const c = useSensorsGroupContext()
	const currentPlotRangesData = c?.plotRanges?.find((pr) => pr.plotId === plotData.id)
	const fetchRange = currentPlotRangesData?.range
	const fetchFrom = currentPlotRangesData?.from
	const fetchTo = currentPlotRangesData?.to

	const stateAliases = useAppSelector(getStateAliases)
	const appStruct = useAppSelector(getAppStructure)

	const [isPlotConfigOpen, setIsPlotConfigOpen] = useState<boolean>(false)
	// const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const [isQuickAddOpen, setIsQuickAddOpen] = useState<boolean>(false)
	const [quickAddChosenAlternatives, setQuickAddChosenAlternatives] = useState<string[]>()
	const [quickAddChosenAlternativesTemp, setQuickAddChosenAlternativesTemp] = useState<string[]>()

	const [showOpModeBg, setShowOpModeBg] = useState<boolean | undefined>(true)
	const [showRawData, setShowRawData] = useState<boolean | undefined>(false)

	const [isDataDecimated, setIsDataDecimated] = useState<boolean | undefined>(false)
	const [showDataDecimated, setShowDataDecimeted] = useState<boolean | undefined>(true)

	const [series, setSeries] = useState<{ x: Date; y: number | null }[][]>([])
	const [newRangeLoaded, setNewRangeLoaded] = useState<boolean>(false)
	const [loaded, setLoaded] = useState(false)

	const [range, setRange] = useState<TimeseriesDataRange>(
		fetchRange !== undefined
			? fetchRange
			: to !== undefined || from !== undefined
			? TimeseriesDataRange.Custom
			: TimeseriesDataRange.Last24h
	)
	useEffect(() => {
		if (fetchRange === undefined) {
			return
		}
		setRange(fetchRange)
	}, [fetchRange])

	useEffect(() => {
		if (fetchFrom === undefined) {
			return
		}
		setCustomFrom(fetchFrom)
	}, [fetchFrom])

	useEffect(() => {
		if (fetchTo === undefined) {
			return
		}
		setCustomTo(fetchTo)
	}, [fetchTo])

	const [dataForPlot, setDataForPlot] = useState<PlotData>({
		plotId: -1,
		plotName: "",
		series: [],
	})

	const oneDayInMinutes = 24 * 60
	let minutes = oneDayInMinutes * 0.5
	if (range === TimeseriesDataRange.Last24h) {
		minutes = oneDayInMinutes * 1
	} else if (range === TimeseriesDataRange.LastWeek) {
		minutes = oneDayInMinutes * 7
	}

	const [firstTimestamp, setFirstTimestamp] = useState<number>()
	const [customFrom, setCustomFrom] = useState<Date>(
		fetchFrom !== undefined
			? fetchFrom
			: from ?? new Date(new Date().getTime() - minutes * 60 * 1000)
	)
	const [selectedCustomFrom, setSelectedCustomFrom] = useState<boolean>(false)
	const [sensorsIdWithNoDataInInterval, setSensorsIdWithNoDataInInterval] = useState<
		{ sensorLabel: string; sensorId: number; timestampLastDataPoint: Date }[]
	>([])

	const [previousSelectedCustomFrom, setPreviousSelectedCustomFrom] = useState<boolean>(false)
	const [customTo, setCustomTo] = useState<Date>(
		fetchTo !== undefined ? fetchTo : to ?? new Date()
	)

	const fetchListOfSensors = (plantName: string, assetName: string) => {
		if (appStruct !== null) {
			const listOfSensorsTemp = getSensorsByAsset(
				appStruct,
				companyName,
				plantName,
				assetName !== "All Assets" ? assetName : undefined,
				sensorsGroupId !== null ? sensorsGroupId : undefined
			)
			const returnList: MultiSelectItem[] = []
			for (const sensor of listOfSensorsTemp.sensors) {
				returnList.push({ label: sensor.display_name, value: sensor.id.toString() })
			}
			return returnList
		} else {
			return []
		}
	}

	const tmpChosenSensors: string[] = []

	const sensorIdTemp: number[] = []
	for (const signal of plotData.signals) {
		const sensorId = signal.sensorId
		tmpChosenSensors.push(sensorId.toString())
		if (sensorId !== undefined) {
			if (signal.aggregationMethod !== AggregationMethod.PREDICTION) {
				sensorIdTemp.push(sensorId)
			}
		}
	}
	useEffect(() => {
		const sensorIdTemp: number[] = []
		for (const signal of plotData.signals) {
			const sensorId = signal.sensorId
			tmpChosenSensors.push(sensorId.toString())
			if (sensorId !== undefined) {
				if (signal.aggregationMethod !== AggregationMethod.PREDICTION) {
					sensorIdTemp.push(sensorId)
				}
			}
		}
		setGetTimeseriesArgs((prev) => {
			return { ...prev, sensorIds: sensorIdTemp }
		})
	}, [plotData.signals])

	const id = common.plantDataFromName.get(plantName)?.id

	const [getTimeseriesArgs, setGetTimeseriesArgs] = useState<
		GetTimeseriesDataArgs & { plantId: number }
	>({
		sensorIds: sensorIdTemp,
		plantId: id ?? -1,
		interval:
			range === TimeseriesDataRange.Custom
				? toIntervalV2(customFrom, customTo)
				: createIntervalOffsetV2(-minutes),
	})

	const [getOpModeIntervalsArgs, setGetOpModeIntervalsArgs] = useState<GetOpModeEventsArgs>({
		plantId: id ?? -1,
		from:
			range === TimeseriesDataRange.Custom
				? customFrom
				: new Date(createIntervalOffset(-minutes).from),
		to:
			range === TimeseriesDataRange.Custom
				? customTo
				: new Date(createIntervalOffset(-minutes).to),
	})

	useEffect(() => {
		if (range !== TimeseriesDataRange.Custom) {
			let minutes = oneDayInMinutes * 0.5
			if (range === TimeseriesDataRange.Last24h) {
				minutes = oneDayInMinutes * 1
			} else if (range === TimeseriesDataRange.LastWeek) {
				minutes = oneDayInMinutes * 7
			}
			setCustomFrom(new Date(new Date().getTime() - minutes * 60 * 1000))
			setCustomTo(new Date())
		}
		setGetTimeseriesArgs((prev) => {
			return {
				...prev,
				interval:
					range === TimeseriesDataRange.Custom
						? toIntervalV2(customFrom, customTo)
						: createIntervalOffsetV2(-minutes),
			}
		})
		setGetOpModeIntervalsArgs((prev) => {
			return {
				...prev,
				to: range === TimeseriesDataRange.Custom ? customTo : new Date(),
				from:
					range === TimeseriesDataRange.Custom
						? customFrom
						: new Date(new Date().getTime() - minutes * 60 * 1000),
			}
		})
	}, [range])

	useEffect(() => {
		if (fetchTo === undefined || fetchFrom === undefined) {
			return
		}
		setGetTimeseriesArgs((prev) => {
			return {
				...prev,
				interval:
					range === TimeseriesDataRange.Custom
						? toIntervalV2(fetchFrom, fetchTo)
						: createIntervalOffsetV2(-minutes),
			}
		})
		setGetOpModeIntervalsArgs((prev) => {
			return {
				...prev,
				to: range === TimeseriesDataRange.Custom ? fetchTo : new Date(),
				from:
					range === TimeseriesDataRange.Custom
						? fetchFrom
						: new Date(new Date().getTime() - minutes * 60 * 1000),
			}
		})
	}, [fetchFrom, fetchTo])

	if (selectedCustomFrom !== previousSelectedCustomFrom) {
		setPreviousSelectedCustomFrom(selectedCustomFrom)
		setGetTimeseriesArgs((prev) => {
			return { ...prev, interval: toIntervalV2(customFrom, customTo) }
		})
		setGetOpModeIntervalsArgs((prev) => {
			return { ...prev, to: customTo, from: customFrom }
		})
	}
	const [aggregationMethod, setAggregationMethod] =
		useState<AggregationConfig["method"]>("average")

	const conditionForAggregation =
		getTimeseriesArgs.interval.lt.getTime() - getTimeseriesArgs.interval.gte.getTime() >
		LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS
	const {
		isLoading: isResSensorDataLoading,
		isError: isResSensorDataError,
		data: resSensorData,
	} = useGetTimeseriesQuery({
		t1: getTimeseriesArgs.interval.gte,
		t2: getTimeseriesArgs.interval.lt,
		plantId: getTimeseriesArgs.plantId,
		sensorIds: getTimeseriesArgs.sensorIds,
		aggregationGroupedBy: conditionForAggregation === true ? "hour" : "minute",
		aggregationMethod: conditionForAggregation === true ? aggregationMethod : undefined,
	})

	const {
		isLoading: isOpModeDataLoading,
		isError: isOpModeDataError,
		data: opModeData,
	} = useGetOpModeIntervalsQuery({
		t1: getOpModeIntervalsArgs.from,
		t2: getOpModeIntervalsArgs.to,
		plantId: getOpModeIntervalsArgs.plantId,
	})

	useEffect(() => {
		const apiCall = async ({ updateRange = true }: { updateRange?: boolean }) => {
			if (resSensorData === null) {
				setDataForPlot({
					plotId: plotData.id,
					plotName: "",
					series: null,
				})
				return
			}
			if (resSensorData === undefined) {
				return
			}
			const signal0 = plotData.signals[0]
			if (signal0 === undefined) {
				setDataForPlot({
					plotId: plotData.id,
					plotName: "",
					series: [],
				})
				return
			}
			const dataForPlotTemp: PlotData = {
				plotId: plotData.id,
				plotName:
					plotData.name !== null
						? plotData.name
						: common.sensorDataFromIds.get(signal0.sensorId)?.display_name!,
				series: [],
			}
			const sensorsIdWithNoDataInIntervalTemp: {
				sensorLabel: string
				sensorId: number
				timestampLastDataPoint: Date
			}[] = []
			var data_db: { x: Date; y: number | null }[][][] = []

			const resOrdered: Timeseries[] = []
			const totalNumberDatapoints = resSensorData.sensors.reduce(
				(prev, current) => current.data.length + prev,
				0
			)
			if (showRawData === true && totalNumberDatapoints > 2 * MAX_TIMESERIES_SIZE) {
				setIsDataDecimated(true)
			} else {
				setIsDataDecimated(false)
			}
			for (const signal of plotData.signals) {
				if (signal.aggregationMethod === AggregationMethod.PREDICTION) {
					continue
				}
				if (resSensorData.sensors.length === 0) {
					const sensorId = signal.sensorId
					if (sensorId !== undefined) {
						resOrdered.push({
							sensorId: sensorId,
							aggregationMethod: signal.aggregationMethod,
							dataPoints: [],
						})
					}
				} else {
					for (const [r, sensorData] of resSensorData.sensors.entries()) {
						const sensorId = sensorData.id
						if (sensorId === signal.sensorId) {
							resOrdered.push({
								sensorId: sensorId,
								aggregationMethod: signal.aggregationMethod,
								dataPoints: (showDataDecimated === true &&
								showRawData === true &&
								totalNumberDatapoints > 2 * MAX_TIMESERIES_SIZE &&
								sensorData.data.length > MAX_TIMESERIES_SIZE
									? RDP(sensorData.data, 0.01)
									: sensorData.data
								).map((dataPoint) => ({
									timestamp: dataPoint[0].toString(),
									value: dataPoint[1],
								})),
							})
							break
						}
						if (r === resSensorData.sensors.length - 1) {
							const sensorId = signal.sensorId
							if (sensorId !== undefined) {
								resOrdered.push({
									sensorId: sensorId,
									aggregationMethod: signal.aggregationMethod,
									dataPoints: [],
								})
							}
						}
					}
				}
			}

			const initTs = getTimeseriesArgs.interval.gte.getTime()
			const endTs = getTimeseriesArgs.interval.lt.getTime()

			if (showRawData !== true) {
				for (const sensorData of resOrdered) {
					const tsData = sensorData.dataPoints
					const tsData_0 = tsData?.[0]
					if (tsData.length === 1 && tsData_0 !== undefined) {
						if (new Date(tsData_0.timestamp) !== getTimeseriesArgs.interval.gte) {
							tsData.push({
								value: tsData_0.value,
								timestamp: getTimeseriesArgs.interval.gte.toString(),
							})
						}
						tsData.push({
							timestamp: getTimeseriesArgs.interval.lt.toString(),
							value: tsData_0.value,
						})
						sensorsIdWithNoDataInIntervalTemp.push({
							sensorLabel:
								common.sensorDataFromIds.get(sensorData.sensorId)?.display_name ??
								"unknown sensor",
							sensorId: sensorData.sensorId,
							timestampLastDataPoint: new Date(tsData_0.timestamp),
						})
						continue
					}
					const sensorData_dataPoints_0 = sensorData.dataPoints[0]
					if (tsData_0 !== undefined && sensorData_dataPoints_0 !== undefined) {
						const ts0 = new Date(tsData_0.timestamp).getTime()
						if (ts0 < initTs) {
							sensorData_dataPoints_0.timestamp =
								getTimeseriesArgs.interval.gte.toString()
						}
					}
					const tsData_last = tsData?.[tsData.length - 1]
					if (tsData_last !== undefined) {
						const tsLast = new Date(tsData_last.timestamp).getTime()
						if (tsLast < endTs) {
							sensorData.dataPoints.push({
								timestamp: getTimeseriesArgs.interval.lt.toString(),
								value: tsData_last.value,
							})
						}
					}
				}
			}

			///make signals uniform
			const uniformTable: {
				timestamp: Date
				sensorValues: Map<number, number | null>
			}[] = []

			// Group data by timestamp
			const dataGroupedByTimestamp = new Map<number, { sensorId: number; value: number }[]>()
			const sensorValueBySensorId = new Map<number, number | null>()

			for (const sensorData of resOrdered) {
				const tsData = sensorData.dataPoints
				const tsData_0 = tsData?.[0]
				if (tsData.length === 1 && tsData_0 !== undefined) {
					sensorsIdWithNoDataInIntervalTemp.push({
						sensorLabel:
							common.sensorDataFromIds.get(sensorData.sensorId)?.display_name ??
							"unknown sensor",
						sensorId: sensorData.sensorId,
						timestampLastDataPoint: new Date(tsData_0.timestamp),
					})
				}
				const sensorId = sensorData.sensorId
				for (const d of tsData) {
					const key = new Date(d.timestamp).getTime()
					let timestampDatapoints = dataGroupedByTimestamp.get(key)
					if (timestampDatapoints == undefined) {
						timestampDatapoints = []
						dataGroupedByTimestamp.set(key, timestampDatapoints)
					}
					timestampDatapoints.push({ sensorId, value: d.value })
				}
			}

			// Run through the timestamps chronologically
			const timestampsSorted = Array.from(dataGroupedByTimestamp.keys()).sort((a, b) => a - b)
			for (const timestampMs of timestampsSorted) {
				const datapointsForTimestamp = dataGroupedByTimestamp.get(timestampMs) ?? []
				if (showRawData === true) {
					for (const sensorId of sensorValueBySensorId.keys()) {
						sensorValueBySensorId.set(sensorId, null)
					}
				}
				// Set the state of each sensor
				for (const datapoint of datapointsForTimestamp) {
					sensorValueBySensorId.set(datapoint.sensorId, datapoint.value)
				}
				// Create and save the row
				const row: {
					timestamp: Date
					sensorValues: Map<number, number | null>
				} = {
					sensorValues: new Map(),
					timestamp: new Date(timestampMs),
				}
				// For each sensor, there will be either a new value or a value that was previously set
				for (const [plantId, sensorValue] of sensorValueBySensorId) {
					row.sensorValues.set(plantId, sensorValue)
				}
				uniformTable.push(row)
			}
			const resOrderedUniform = resOrdered.map((signal) => {
				return {
					...signal,
					dataPoints: uniformTable
						.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
						.map((entry) => {
							return {
								timestamp: entry.timestamp.toString(),
								value: entry.sensorValues.get(signal.sensorId) ?? null,
							}
						}),
				}
			})
			///
			data_db.push(
				resOrderedUniform.map((signal) =>
					signal.dataPoints
						.map((row) => ({
							x: new Date(Date.parse(row.timestamp)),
							y: row.value,
						}))
						.sort((a, b) => a.x.getTime() - b.x.getTime())
				)
			)

			const data_db0 = data_db[0]
			if (data_db0 === undefined) {
				return
			}
			// Using this approach to ensure the reference to the state (and consequently the state) is updated.
			// Otherwise the component was not rerendering with new data.
			var seriesCopy: { x: Date; y: number | null }[][] = series.slice()
			seriesCopy = data_db0
			setSeries(seriesCopy)
			//
			for (const [k, seriesK] of seriesCopy.entries()) {
				const signalK = plotData.signals[k]
				if (signalK === undefined) {
					return
				}
				const dataTransformed = seriesK

				const dataTransformed_0 = dataTransformed[0]
				if (dataTransformed.length === 1 && dataTransformed_0 !== undefined) {
					const refTimestamp = dataTransformed_0.x
					if (firstTimestamp === undefined) {
						setFirstTimestamp(refTimestamp.getTime() - 60000)
					}
					if (firstTimestamp !== undefined) {
						dataTransformed.push({
							x: new Date(firstTimestamp),
							y: dataTransformed_0.y,
						})
						dataTransformed_0.x = refTimestamp
					} else {
						dataTransformed.push({
							x: new Date(refTimestamp.getTime() - 60000),
							y: dataTransformed_0.y,
						})
						dataTransformed_0.x = refTimestamp
					}
				}

				if (dataTransformed.length > -1) {
					const dataUnit = common.sensorDataFromIds.get(signalK.sensorId)?.unit
					const stateUnits =
						dataUnit !== undefined
							? stateAliases?.find((s) => s.unit === dataUnit)?.states
							: undefined

					dataForPlotTemp.series !== null &&
						dataForPlotTemp.series.push({
							...signalK,
							data: dataTransformed.reverse(),
							displayName: `${
								common.sensorDataFromIds.get(signalK.sensorId)?.display_name
							}${
								signalK.aggregationMethod !== AggregationMethod.PREDICTION
									? "" //plotData.signals[k].aggregationMethod
									: ` (prediction)`
							}`,
							unit: dataUnit ?? "",
							stateAliases: stateUnits,
						})
				}
			}

			setQuickAddChosenAlternatives(tmpChosenSensors)
			setDataForPlot(dataForPlotTemp)
			setSensorsIdWithNoDataInInterval(sensorsIdWithNoDataInIntervalTemp)
			setLoaded(true)
		}

		const interval = setInterval(() => {
			apiCall({ updateRange: false })
		}, fetch_interval)

		apiCall({})
		return () => {
			clearInterval(interval)
		}
	}, [resSensorData, opModeData, plotData, showRawData, showDataDecimated])

	const togglePlotConfig = () => {
		setIsPlotConfigOpen(!isPlotConfigOpen)
	}

	const toggleQuickAdd = async () => {
		getCardPosition()
		setIsQuickAddOpen(!isQuickAddOpen)
	}

	const getCardPosition = () => {
		setCardRect(cardRef !== null ? cardRef.getBoundingClientRect() : undefined)
	}

	const handleQuickAdd = async (newSignals: string[]) => {
		if (fetchPlantPlots === undefined) {
			return
		}
		const signals: PlantPlotSignal[] = []
		if (newSignals.length === 0 || plotData === undefined) {
			return
		}
		const availableColors = COLORS_PLOTS_COLORBLIND.slice()
		for (const signal of newSignals) {
			const existingSignal = plotData.signals.find((x) => x.sensorId === parseInt(signal))
			if (existingSignal !== undefined) {
				const index = availableColors.indexOf(existingSignal.color)
				if (index !== -1) {
					availableColors.splice(index, 1)
				}
				signals.push(existingSignal)
			} else {
				const color = availableColors.shift() ?? "#000000"
				signals.push({
					sensorId: parseInt(signal),
					aggregationMethod: AggregationMethod.SNAP_FIRST,
					color: color,
					id: -1,
					thresholds: [],
					baselines: [],
				})
			}
		}

		try {
			await requestApi2(endpoints.plantPlotUpdate, {
				id: plotData.id,
				signals: signals,
				name: plotData.name,
			})
			fetchPlantPlots()
		} catch (err) {
			// console.log(err)
		}
	}

	const deleteFunction = async () => {
		try {
			if (window.confirm("ARE YOU SURE YOU WANT TO DELETE THIS PLOT")) {
				try {
					await requestApi2(endpoints.plantPlotRemove, {
						id: plotData.id,
					})
					fetchPlantPlots()
				} catch (err) {
					throw err
				}
			}
		} catch (err) {
			throw err
		}
	}

	const exposePlotFetchRange = (range: TimeseriesDataRange, from?: Date, to?: Date) => {
		if (c?.exposeFetchRange === undefined) {
			return
		}
		c?.exposeFetchRange(plotData.id, range, from, to)
	}
	const [currentOpMode, setCurrentOpMode] = useState<{ modeName: string; modeColor: string }>()
	const [currentTimestamp, setCurrentTimestamp] = useState<number>()

	const [cardRect, setCardRect] = useState<DOMRect>()
	const [cardRef, setCardRef] = useState<HTMLDivElement | null>(null)

	const multiSelectRef = useRef<HTMLElement>(null)

	const refetchData = () => {
		if (range !== TimeseriesDataRange.Custom) {
			queryClient.removeQueries({
				queryKey: getTimeseriesQueryKeys(
					getTimeseriesArgs.interval.gte,
					getTimeseriesArgs.interval.lt,
					getTimeseriesArgs.plantId,
					getTimeseriesArgs.sensorIds,
					conditionForAggregation === true ? "hour" : "minute",
					conditionForAggregation === true ? aggregationMethod : "average"
				),
			})

			queryClient.removeQueries({
				queryKey: getOpModeIntervalsQueryKeys(
					getOpModeIntervalsArgs.from,
					getOpModeIntervalsArgs.to,
					getOpModeIntervalsArgs.plantId
				),
			})

			const newInterval = createIntervalOffsetV2(-minutes)
			setGetTimeseriesArgs({
				plantId: getTimeseriesArgs.plantId,
				sensorIds: sensorIdTemp,
				interval: newInterval,
			})
			setGetOpModeIntervalsArgs({
				plantId: id ?? -1,
				from: new Date(createIntervalOffset(-minutes).from),
				to: new Date(createIntervalOffset(-minutes).to),
			})

			setCustomTo(newInterval.lt)
			setCustomFrom(newInterval.gte)
		} else {
			queryClient.invalidateQueries({
				queryKey: getOpModeIntervalsQueryKeys(
					getOpModeIntervalsArgs.from,
					getOpModeIntervalsArgs.to,
					getOpModeIntervalsArgs.plantId
				),
			})
			queryClient.invalidateQueries({
				queryKey: getTimeseriesQueryKeys(
					getTimeseriesArgs.interval.gte,
					getTimeseriesArgs.interval.lt,
					getTimeseriesArgs.plantId,
					getTimeseriesArgs.sensorIds,
					conditionForAggregation === true ? "hour" : "minute",
					conditionForAggregation === true ? aggregationMethod : "average"
				),
			})
		}
	}

	const [showWarningPanel, setShowWaringPanel] = useState<boolean>(false)
	useEffect(() => {
		if (conditionForAggregation === true) {
			setShowWaringPanel(true)
			return
		}
		if (isDataDecimated === true && showDataDecimated === true && showRawData === true) {
			setShowWaringPanel(true)
			return
		}
		setShowWaringPanel(false)
	}, [
		getTimeseriesArgs.interval.gte.getTime(),
		getTimeseriesArgs.interval.lt.getTime(),
		isDataDecimated,
		showDataDecimated,
		showRawData,
	])

	return (
		<div
			ref={(newRef) => setCardRef(newRef)}
			id={`card_${dataForPlot.plotId}`}
			className="h-full py-4 m-auto"
			style={{ width: width, left: margin }}
		>
			{dataForPlot.series !== null ? (
				<>
					<div className={`flex flex-col gap-2`}>
						<PlotHeading
							isDataLoading={isResSensorDataLoading}
							cardRef={cardRef}
							buttonsSetup={["settings", "delete", "quickAdd"]}
							handleConfigOpen={togglePlotConfig}
							handleDelete={deleteFunction}
							handleQuickAdd={toggleQuickAdd}
							plotName={plotData.name !== null ? plotData.name : ""}
							currentOpMode={currentOpMode}
							currentTimestamp={currentTimestamp}
							showOpModeBg={showOpModeBg}
							setShowOpModeBg={setShowOpModeBg}
							showRawData={showRawData}
							setShowRawData={setShowRawData}
							isDataDecimated={isDataDecimated}
							showDataDecimated={showDataDecimated}
							setShowDataDecimeted={setShowDataDecimeted}
							aggregationMethod={aggregationMethod}
							setAggregationMethod={setAggregationMethod}
							showAggregationSelector={conditionForAggregation === true}
						/>
						{isResSensorDataLoading !== true ? (
							<>
								{showWarningPanel === true ? (
									<div className="flex flex-row items-center w-full h-6 gap-3 px-2 font-medium rounded-sm border-1 border-amber-300 bg-amber-50 text-tiny text-amber-700">
										<ImportantIcon
											className="w-4 h-4"
											bgColor="text-amber-400"
											iconColor="text-amber-700"
										/>
										{conditionForAggregation === true ? (
											<div
												className={`flex flex-row items-center gap-1 rounded-full border-0 border-gray-ccc`}
											>
												<p className={``}>{`${t("DATA AGGREGATED PER")} ${t(
													"HOUR"
												)}`}</p>
												<Tooltip
													className={"bg-dipai-secondary-800/95"}
													arrowClassName={"fill-dipai-secondary-800/95"}
													collisionBoundary={cardRef}
													customContent={
														<div
															className={`flex max-w-[550px] flex-col`}
														>
															{`${t(
																"DATA AGGREGATED PER HOUR EXPLANATION"
															)}`}
														</div>
													}
												>
													<div className={`flex w-fit flex-row gap-2`}>
														<MdInfoOutline
															className={
																"h-4 w-4 hover:cursor-pointer"
															}
														/>
													</div>
												</Tooltip>
											</div>
										) : null}
										{isDataDecimated === true && showDataDecimated === true ? (
											<div
												className={`flex flex-row items-center gap-1 rounded-full border-0 border-gray-ccc`}
											>
												<p className={``}>{`${t(
													"DATA IS DOWNSAMPLED"
												)}`}</p>
												<Tooltip
													className={"bg-dipai-secondary-800/95"}
													arrowClassName={"fill-dipai-secondary-800/95"}
													collisionBoundary={cardRef}
													customContent={
														<div className={`max-w-[550px]`}>
															{`${t(
																"DATA DOWNSAMPLING EXPLANATION"
															)} `}
															<span
																className={`inline-flex rounded-sm border-1 bg-white`}
															>
																<MdStackedLineChart
																	className={
																		"h-3 w-3 text-dipai-secondary-800"
																	}
																/>
															</span>{" "}
															{` ${t("BUTTON")}.`}
														</div>
													}
												>
													<div className={`flex w-fit flex-row gap-2`}>
														<MdInfoOutline
															className={
																"h-4 w-4 hover:cursor-pointer"
															}
														/>
													</div>
												</Tooltip>
											</div>
										) : null}
									</div>
								) : null}

								<div
									className={`${
										showWarningPanel === true ? "h-[368px]" : "h-[400px]"
									}  w-full`}
								>
									<LinePlot
										isWarningPanelVisible={showWarningPanel}
										opModeEvents={opModeData !== null ? opModeData : []}
										plotData={dataForPlot}
										newRangeLoaded={newRangeLoaded}
										setCurrentOpMode={setCurrentOpMode}
										setCurrentTimestamp={setCurrentTimestamp}
										showOpModeBg={showOpModeBg}
										listOfModeColors={listOfModeColors}
										showRawData={showRawData}
										from={getTimeseriesArgs.interval.gte}
										to={getTimeseriesArgs.interval.lt}
										sensorsIdWithNoDataInInterval={
											sensorsIdWithNoDataInInterval
										}
									/>
								</div>
							</>
						) : (
							<div className={`h-[400px] w-full border-1`}>
								<LoadingCircle containerHeightWithUnit={"h-[400px]"} />
							</div>
						)}
						{hideRangeSelector !== true ? (
							<PlotRangeSelection
								isDataLoading={isResSensorDataLoading}
								cardRef={cardRef}
								plotId={plotData.id}
								exposePlotFetchRange={exposePlotFetchRange}
								endDate={customTo}
								selectedStartDate={selectedCustomFrom}
								setRange={setRange}
								range={range}
								setSelectedStartDate={setSelectedCustomFrom}
								setStartDate={setCustomFrom}
								startDate={customFrom}
								setEndDate={setCustomTo}
								refetchData={refetchData}
							/>
						) : null}
					</div>
					<ModalNL
						initialFocus={multiSelectRef}
						isOpen={isQuickAddOpen}
						setIsOpen={setIsQuickAddOpen}
						opacity={0.2}
					>
						<div
							className="fixed flex max-w-[50%] flex-col gap-2 rounded-sm bg-white p-2 pb-3"
							style={{
								top:
									cardRect !== undefined
										? `calc(48px + ${cardRect.top}px)`
										: `50%`,
								right:
									cardRect !== undefined
										? `calc(100% - ${cardRect.right}px + 30px)`
										: "20px",
							}}
						>
							<div className={`flex flex-row justify-between gap-4`}>
								<div className={`flex flex-row items-baseline gap-2`}>
									<p className="text-button uppercase tracking-[1px] text-gray-666">
										{t("QUICK CHANGE SENSORS")}
									</p>
									{quickAddChosenAlternativesTemp !== undefined &&
									quickAddChosenAlternativesTemp?.length >= MAX_NUMBER_SIGNALS ? (
										<p className={`text-tiny text-red-700`}>
											{`(${"MAXIMUM NUMBER OF SENSORS SELECTED"})`}
										</p>
									) : null}
								</div>
								<IconButton
									// tabIndex={1}
									variant={"text-dark"}
									size={"sm"}
									onClick={() => setIsQuickAddOpen((prev) => !prev)}
								>
									<IconButton.Icon>{MdClose}</IconButton.Icon>
								</IconButton>
							</div>
							<div
								className={`grid w-full min-w-[500px] grid-cols-[auto_32px] gap-2`}
							>
								<MultiSelectWrapper
									alternatives={fetchListOfSensors(plantName, componentName)}
									initialValues={quickAddChosenAlternatives ?? []}
									onChange={(newValues) => {
										setQuickAddChosenAlternativesTemp(newValues)
									}}
									isClearable={false}
									maxAmountItems={MAX_NUMBER_SIGNALS}
									minAmountItems={1}
								/>
								<Tooltip align="end" customContent={t("CONFIRM SELECTION")}>
									<IconButton
										className={"min-w-[32px]"}
										variant={"filled-secondary"}
										onClick={() => {
											if (
												JSON.stringify(quickAddChosenAlternativesTemp) !==
													JSON.stringify(quickAddChosenAlternatives) &&
												quickAddChosenAlternativesTemp !== undefined
											) {
												handleQuickAdd(quickAddChosenAlternativesTemp)
											}
											setIsQuickAddOpen((prev) => !prev)
										}}
									>
										<IconButton.Icon>{MdCheck}</IconButton.Icon>
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</ModalNL>

					<ModalNL isOpen={isPlotConfigOpen} setIsOpen={setIsPlotConfigOpen}>
						<PlotConfig
							columnsLayout={`24px auto 250px 136px 48px`}
							companyName={companyName}
							componentName={componentName}
							configType={"Plot"}
							sensorsGroupId={sensorsGroupId}
							emptyLineTemplate={{
								sensorId: -1,
								aggregationMethod: AggregationMethod.SNAP_FIRST,
								baselines: [],
								id: Math.random(),
								thresholds: [],
								color: "",
							}}
							fetchPlantPlots={fetchPlantPlots}
							handleConfigOpen={togglePlotConfig}
							numberSignals={MAX_NUMBER_SIGNALS}
							plantName={plantName}
							plotData={plotData}
						/>
					</ModalNL>
				</>
			) : (
				<div className="flex flex-row justify-between gap-2">
					<p className="text-body">
						{`Couldn't fetch data for sensor ${dataForPlot.plotName}`}
					</p>
					<div className={`flex flex-row gap-2`}>
						<IconButton
							variant={"text-dark"}
							size={"sm"}
							onClick={() => deleteFunction()}
						>
							<IconButton.Icon>{MdDelete}</IconButton.Icon>
						</IconButton>
						<IconButton
							variant={"text-dark"}
							size={"sm"}
							onClick={() => togglePlotConfig()}
						>
							<IconButton.Icon>{MdSettings}</IconButton.Icon>
						</IconButton>
					</div>
				</div>
			)}
		</div>
	)
}
