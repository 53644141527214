import {
	isFeatureAvailable,
	useGetDevFeatures,
	useIsContentAvailable,
} from "@components/featureGuard/FeatureGuard"
import Logout from "@components/login/Logout"
import { ImportantIcon } from "@helpers/ImportantIcon"
import { UniSeaVesselIcon, useGetUniSeaVessels } from "@hooks/useGetUniSeaVessels"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getCurrentCompany,
	setCurrentCompany,
} from "@redux/features/currentCompany/currentCompanySlice"
import { getOfflineVessels } from "@redux/features/offlineVessels/offlineVesselsSlice"
import { getUniSeaVessels } from "@redux/features/uniSeaVessels/uniSeaVesselsSlice"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { ModuleNameSOP } from "@routes/clients.$clientName/_sopPageLayout"
import { Outlet } from "@tanstack/react-router"
import { ModuleMetadata, PageLayout } from "@ui-lib/src"
import {
	VesselConnectionStatusIcon,
	VesselConnectionStatusIconProps,
	useGetVesselsConnectionStatusData,
} from "@utils/hooks/useGetVesselsConnectionStatusData"
import { submitUserTrackingAction } from "@utils/websocket/userTrackingSocket"
import { AppStructure, PortalFeature } from "api"
import { ReactElement, lazy } from "react"
import { useTranslation } from "react-i18next"
import {
	MdAssignment,
	MdBolt,
	MdBuild,
	MdDirectionsBoatFilled,
	MdEco,
	MdFileDownload,
	MdOutlineMap,
	MdOutlineSpeed,
	MdTimeline,
} from "react-icons/md"
import { WARNING_DIALOG_HEIGHT, getWarningCondition } from "./DashboardPage"

// import OperationsTimelineContent from "../OperationsTimelinePage/OperationsTimelineContent"
// import DownloadPageContent from "../DownloadPage/DownloadPageContent"
// import EmissionContent from "../FuelPage/ESG/EmissionContent"
// import FuelConsumptionContent from "../FuelPage/FuelConsumptionContent"
// import FuelConsumptionVoyageContent from "../FuelPage/Voyage/FuelConsumptionVoyageContentV2"
// import HistoricalDataContentSensorGroupsBased from "../HistoricalDataPage/HistoricalDataContentSensorGroupsBased"
// import MapPageContent from "../MapPage/MapPageContent"
// import OperationModesContent from "../OperationModesPage/OperationModesContent"
// import PeriodicMaintenanceContent from "../PeriodicMaintenancePage/PeriodicMaintenanceContent"

const OperationsTimelineContent = lazy(
	() => import("../OperationsTimelinePage/OperationsTimelinePageContent")
)
const OperationsTimelineContentUniSea = lazy(
	() => import("../OperationsTimelinePageUniSea/OperationsTimelinePageContentUniSea")
)
const MapPageContent = lazy(() => import("../MapPage/MapPageContent"))
const OperationModesContent = lazy(() => import("../OperationModesPage/OperationModesContent"))
const FuelConsumptionContent = lazy(() => import("../FuelPage/FuelConsumptionContent"))
const FuelConsumptionContentUniSea = lazy(
	() => import("../FuelPageUniSea/FuelConsumptionContentUniSea")
)

const PeriodicMaintenanceContent = lazy(
	() => import("../PeriodicMaintenancePage/PeriodicMaintenanceContent")
)
const ConditionBasedMaintenanceContent = lazy(
	() => import("../ConditionBasedMaintenancePage/ConditionBasedMaintenanceContent")
)
const FuelConsumptionVoyageContent = lazy(() => import("../VoyagePage/VoyagePageContent"))
const EmissionContent = lazy(() => import("../EmissionPage/EmissionPageContent"))
const HistoricalDataContentSensorGroupsBased = lazy(
	() => import("../HistoricalDataPage/HistoricalDataContentSensorGroupsBased")
)
const DownloadPageContent = lazy(() => import("../DownloadPage/DownloadPageContent"))

export default function DashboardPageLayout({ props }: { props: AppStructure }) {
	const paramSubClient: string = "" //useSearch({ strict: false })?.subClient ?? ""

	const devFeatures = useGetDevFeatures()

	const { t, i18n } = useTranslation()
	const dispatch = useAppDispatch()
	const userData = useAppSelector(getUserData)
	const offlineVessels = useAppSelector(getOfflineVessels)
	const uniSeaVessels = useAppSelector(getUniSeaVessels)

	const companyName = Object.keys(props)[0]
	const currentCompany = useAppSelector(getCurrentCompany) ?? ""
	if (currentCompany === "") {
		dispatch(setCurrentCompany(companyName))
	}

	let customerPlants = props[currentCompany]?.plants
	useGetVesselsConnectionStatusData(customerPlants)
	useGetUniSeaVessels(customerPlants ?? {})

	if (
		isFeatureAvailable(
			undefined,
			PortalFeature.SUBCLIENTS,
			currentCompany,
			devFeatures,
			userData.portalMode
		) === true &&
		paramSubClient !== currentCompany
	) {
		const clients = props[currentCompany]?.clients
		if (clients !== undefined) {
			if (paramSubClient !== "All" && paramSubClient !== "") {
				customerPlants = {}
				for (const [clientName, clientData] of Object.entries(clients)) {
					if (clientName !== paramSubClient) {
						continue
					}
					const clientPlants = clientData.plants
					customerPlants = clientPlants
					break
				}
			} else {
				for (const clientData of Object.values(clients)) {
					const clientPlants = clientData.plants
					customerPlants = { ...customerPlants, ...clientPlants }
				}
			}
		}
	}

	const showDatalessPlants = isFeatureAvailable(
		undefined,
		PortalFeature.SHOW_DATALESS_PLANTS,
		currentCompany,
		devFeatures,
		userData.portalMode
	)

	const secondaryNavContent: {
		plantName: string
		icons?: (ReactElement<VesselConnectionStatusIconProps> | null)[]
	}[] = []

	if (customerPlants !== null && customerPlants !== undefined) {
		Object.keys(customerPlants).forEach((plantName) => {
			const vesselOfflineData = offlineVessels?.find(
				(vesselData) => vesselData.name === plantName
			)
			const vesselUniSeaData = uniSeaVessels?.find(
				(vesselData) => vesselData.name === plantName
			)

			if (
				showDatalessPlants === true ||
				vesselOfflineData === undefined ||
				(vesselOfflineData !== undefined && vesselOfflineData.lastUpdateTimestamp !== null)
			) {
				const icon =
					vesselUniSeaData !== undefined ? (
						<UniSeaVesselIcon
							key={`uniSeaDataIcon_${plantName}`}
							firstTimestamp={vesselUniSeaData.firstTimestamp}
						/>
					) : vesselOfflineData !== undefined ? (
						<VesselConnectionStatusIcon
							key={`vesselAvailabilityIcon_${plantName}`}
							isAvailable={vesselOfflineData === undefined}
							lastTimestamp={
								vesselOfflineData !== undefined
									? vesselOfflineData.lastUpdateTimestamp!
									: undefined
							}
						/>
					) : null
				secondaryNavContent.push({
					plantName,
					icons:
						offlineVessels !== undefined || uniSeaVessels !== undefined
							? [icon]
							: undefined,
				})
			}
		})
	}

	const contents: ModuleMetadata<PortalFeature, ModuleNameSOP>[] = [
		{
			feature: PortalFeature.MAP,
			name: "fleet-overview",
			content: <MapPageContent props={props} />,
			icon: <MdOutlineMap className="h-6 w-6" />,
			config: { hideVesselSelection: true, hideHeader: true },
		},
		{
			feature: PortalFeature.VESSEL_STATUS,
			name: "vessel-status",
			content: <OperationModesContent props={props} />,
			icon: <MdDirectionsBoatFilled className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.OPERATION_TIMELINE,
			name: "operations",
			content: <OperationsTimelineContent props={props} />,
			alternativeContent: [
				{
					constraintName: "uniSeaData",
					content: <OperationsTimelineContentUniSea props={props} />,
				},
			],
			icon: <MdOutlineSpeed className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.FUEL_CONSUMPTION,
			name: "consumption",
			content: <FuelConsumptionContent props={props} />,
			alternativeContent: [
				{
					constraintName: "uniSeaData",
					content: <FuelConsumptionContentUniSea props={props} />,
				},
			],
			icon: <MdBolt className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.MACHINERY,
			name: "machinery",
			content: <PeriodicMaintenanceContent props={props} />,
			icon: <MdBuild className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.CBM,
			name: "cbm",
			content: <ConditionBasedMaintenanceContent props={props} />,
			icon: <MdBuild className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.VOYAGE_PLANNING,
			name: "voyage",
			content: <FuelConsumptionVoyageContent />,
			icon: <MdAssignment className="h-6 w-6" />,
		},
		// {
		// 	feature: undefined,
		// 	name: "hybrid",
		// 	content: <HybridContent props={props} />,
		// 	icon: <MdBatteryChargingFull className="w-6 h-6" />,
		// },
		{
			feature: PortalFeature.EMISSIONS_ANALYSIS,
			name: "emission",
			content: <EmissionContent props={props} />,
			icon: <MdEco className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.SENSORDATA,
			name: "historical-data",
			content: <HistoricalDataContentSensorGroupsBased props={props} />,
			icon: <MdTimeline className="h-6 w-6" />,
		},
		{
			feature: PortalFeature.SENSOR_DATA_DOWNLOAD,
			name: "download-data",
			content: <DownloadPageContent />,
			icon: <MdFileDownload className="h-6 w-6" />,
		},
	]

	const filteredContents = useIsContentAvailable(contents)

	const uniSeaContraint: {
		constraintName: string
		vessels: string[]
		availableModules: ModuleNameSOP[]
	} = {
		constraintName: "uniSeaData",
		vessels: uniSeaVessels.map((vesselData) => vesselData.name),
		availableModules: ["fleet-overview", "operations", "consumption"],
	}
	const warningCondition = getWarningCondition()

	return currentCompany === undefined ? (
		<div>{"Internal error: No customer found"}</div>
	) : (
		<>
			{warningCondition === true ? (
				<div
					style={{ height: `${WARNING_DIALOG_HEIGHT}px` }}
					className="text-button grid w-full grid-cols-[24px_auto] items-center gap-2 border-2 border-amber-500 bg-amber-100 px-2 font-normal text-amber-700"
				>
					<ImportantIcon
						className="h-6 w-6"
						bgColor="text-amber-300"
						iconColor="text-amber-700"
					/>
					<p className={`text-button leading-5`}>{t("WARNING MESSAGE")}</p>
				</div>
			) : null}
			<PageLayout
				t={t}
				company={"dipai"}
				defaultModule={"fleet overview"}
				contents={filteredContents}
				submitAction={(text: string) => {
					submitUserTrackingAction({ action: text })
				}}
			>
				<PageLayout.MainNav>
					<PageLayout.MainNav.ExpandButton />
					<PageLayout.MainNav.LanguageSelector
						i18n={i18n}
						languages={[
							{ name: "English", code: "en" },
							{ name: "Norsk", code: "no" },
						]}
					/>
					<PageLayout.MainNav.UserDropdown
						userData={{
							name: userData.name,
							profilePicture: userData.profilePicture,
						}}
						logoutComponent={<Logout />}
					/>
				</PageLayout.MainNav>
				<PageLayout.SecondaryNav
					label={"vessels"}
					items={secondaryNavContent}
					moduleConstraints={[uniSeaContraint]}
				/>
				<PageLayout.BodyLayout
					moduleConstraints={[
						{
							...uniSeaContraint,
							placeHolderComponent: (
								<div
									style={{
										height:
											warningCondition === true
												? `calc(100vh - 52px - ${WARNING_DIALOG_HEIGHT}px)`
												: `calc(100vh - 52px)`,
									}}
									className={`flex w-full flex-col items-center pt-[calc((100vh-52px-460px)/3)]`}
								>
									<img
										className={`h-[400px] opacity-25`}
										alt="placeholder image vessel"
										src={"/assets/img/vessels/placeHolderImage.png"}
									/>
									<p className="text-title4 pb-2 font-semibold opacity-75">
										{t("THIS MODULE IS NOT AVAILABLE FOR THIS VESSEL")}
									</p>
									<p className="text-headline opacity-50">
										{t(
											"PLEASE SELECT A DIFFERENT VESSEL IN THE LIST TO THE LEFT"
										)}
									</p>
								</div>
							),
						},
					]}
					placeHolderComponent={
						<div
							style={{
								height:
									warningCondition === true
										? `calc(100vh - 52px - ${WARNING_DIALOG_HEIGHT}px)`
										: `calc(100vh - 52px)`,
							}}
							className={`flex w-full flex-col items-center pt-[calc((100vh-52px-460px)/3)]`}
						>
							<img
								className={`h-[400px] opacity-25`}
								alt="placeholder image vessel"
								src={"/assets/img/vessels/placeHolderImage.png"}
							/>
							<p className="text-title4 pb-2 font-semibold opacity-75">
								{t("NO VESSEL SELECTED YET")}
							</p>
							<p className="text-headline opacity-50">
								{t("PLEASE SELECT A VESSEL IN THE LIST TO THE LEFT")}
							</p>
						</div>
					}
					customerName={currentCompany}
				/>
				<Outlet />
			</PageLayout>
		</>
	)
}
