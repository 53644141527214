import { PlantIntervalMark } from "api"
import { ScatterDataPoint } from "chart.js"

export enum FuelDataUnit {
	TONNES = "tonnes",
	CUBIC_METER = "cubicMeter",
}

export type AccumulatedValues = {
	shipName: string
	values: { value: number; label: string }[]
}

export enum STACK_PLOT_RANGE {
	YEAR = "year",
	QUARTER = "quarter",
	MONTH = "month",
	WEEK = "week",
	DAY = "day",
}

export enum SHOW_TOTAL {
	SHOW = "show",
	HIDE = "hide",
}

export enum DisplayValue {
	FUEL = "Fuel",
	CO2 = "CO2",
	NOX = "NOx",
	SOX = "SOx",
}

export type ValueNumberLabelStringPair = {
	value: number | string
	label: string
}

export interface ResultItem {
	value: number
	label: string
}

export interface StatisticsType {
	from: string
	to: string
	lossAverageSpeed: number
	speedAverage: number
	lossInterpolated: Statistics
}

export type Statistics = {
	lossMedian: number
	lossAverage: number
}

export type Coefficients = {
	a: number
	b: number
	c: number
}

export type InterpolatedGroupedType = {
	label: string
	avgSpeed: number
	loss: number
	data: (number | ScatterDataPoint | null)[]
	coeff: Coefficients
	xyData: ScatterDataPoint[]
}

export interface HeatmapGroupedType {
	label: string
	avgSpeed: number
	loss: number
	data: (number | ScatterDataPoint | null)[]
	coeff: Coefficients
	xyData: ScatterDataPoint[]
}

export interface CardData {
	text: string
	value: string
	color?: boolean
}

export enum HEAT_MAP_TYPES {
	"kgPerNm" = "kgPerNm",
	"propulsionPower" = "propulsionPower",
	"tonnesPerDay" = "tonnesPerDay",
}

export interface LegendItem {
	label: string
	isHidden: boolean
}

export interface INTERVAL_MARKS {
	plants: { id: number; marks: PlantIntervalMark[] }[]
}
