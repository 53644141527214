import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

const dateSchema = z.preprocess((val) => {
	if (typeof val === "string") {
		const parsedDate = new Date(val)
		if (!isNaN(parsedDate.getTime())) {
			return parsedDate.getTime()
		}
	} else if (typeof val === "number" && !isNaN(val)) {
		return val
	}
	return undefined
}, z.number().int().min(0).optional())

const MIN_YEAR_DATA_DOWNLOAD = 2020
const minDateFrom = new Date(`${MIN_YEAR_DATA_DOWNLOAD}-01-01`).getTime()

export const downloadDataPageSearchSchema = z
	.object({
		vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
		opMode: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of opModes
		toDownloadData: dateSchema,
		fromDownloadData: dateSchema,
	})
	.superRefine((data, ctx) => {
		let hasErrors = false
		if (
			(data.fromDownloadData !== undefined && data.toDownloadData === undefined) ||
			(data.fromDownloadData === undefined && data.toDownloadData !== undefined)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Both 'fromDownloadData' and 'toDownloadData' must be provided together",
				path: ["fromDownloadData"],
			})
			hasErrors = true
		}

		if (
			data.fromDownloadData !== undefined &&
			data.toDownloadData !== undefined &&
			data.fromDownloadData >= data.toDownloadData
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromDownloadData' must be before 'toDownloadData'",
				path: ["fromDownloadData"],
			})
			hasErrors = true
		}

		if (data.fromDownloadData !== undefined && data.fromDownloadData < minDateFrom) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `'fromDownloadData' cannot be earlier than ${MIN_YEAR_DATA_DOWNLOAD}-01-01`,
				path: ["fromDownloadData"],
			})
			hasErrors = true
		}

		if (hasErrors === true) {
			data.fromDownloadData = undefined
			data.toDownloadData = undefined
		}
	})

export const validateDownloadDataSearchParams = (
	inputParams: Record<string, unknown>
): z.infer<typeof downloadDataPageSearchSchema> => {
	const result = downloadDataPageSearchSchema.safeParse(inputParams)

	if (result.success === false) {
		return {
			...inputParams,
			fromDownloadData: undefined,
			toDownloadData: undefined,
		}
	}

	return { ...inputParams, ...result.data }
}

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/download-data")({
	component: RouteComponent,
	validateSearch: validateDownloadDataSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(downloadDataPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
