import { monthsList, monthslistType } from "@helpers/getLabelsMonths"
import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

const MIN_YEAR_OPERATIONS = 2022

export const operationPageSearchSchema = z.object({
	vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
	opMode: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of opModes
	tab: z.optional(z.enum(["timeline"])).catch(undefined),
	month: z
		.optional(z.enum(monthsList))
		.catch(
			monthsList[new Date().getMonth()] !== undefined
				? (monthsList[new Date().getMonth()]!.toLowerCase() as monthslistType)
				: undefined
		),
	year: z.optional(z.number().int().min(MIN_YEAR_OPERATIONS)).catch(new Date().getFullYear()),
})

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/operations")({
	component: RouteComponent,
	validateSearch: operationPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(operationPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
