import { ReactElement } from "react"
import { SectionTitleSize, SectionTitleType } from "./types"

export type SectionTitleProps = {
	title: string
	width?: number
	padding?: number
	type?: SectionTitleType
	size?: SectionTitleSize | number
}

export default function SectionTitle({
	title = "",
	width,
	padding = 16,
	type = SectionTitleType.body,
	size = SectionTitleSize.medium,
}: SectionTitleProps): ReactElement {
	return (
		<div
			className={`flex items-center ${
				type === SectionTitleType.body
					? "rounded-r-lg"
					: type === SectionTitleType.first
					? "rounded-br-lg"
					: "rounded-none"
			} bg-dipai-secondary-901`}
			style={{
				width: width !== undefined ? width : "100%",
				height:
					size === SectionTitleSize.medium
						? 40
						: size === SectionTitleSize.large
						? 60
						: size,
				paddingLeft: padding,
			}}
		>
			<p
				className={`${
					size === SectionTitleSize.medium
						? "text-menu"
						: size === SectionTitleSize.large
						? "text-headline font-bold"
						: "text-headline font-bold"
				} text-white`}
			>
				{title}
			</p>
		</div>
	)
}
