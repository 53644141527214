import { diseaseZoneType } from "@redux/features/diseaseZones/diseaseZonesSlice"
import { GeoJSON } from "leaflet"
import { DataSetter, mapLayersColors } from "../utils/mapLayers"

export const addDiseaseZones: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined || (layerGroup as GeoJSON).addData === undefined) return

	const { deseaseZonesData } = layerData
	if (deseaseZonesData === undefined) return

	const { diseaseZonesData: data, selectedDiseaseZones } = deseaseZonesData

	layerGroup.clearLayers()

	for (const zoneType in data) {
		const zoneTypeName = diseaseZoneType[zoneType] as string | undefined
		const zoneData = data[zoneType]
		if (
			zoneTypeName === undefined ||
			selectedDiseaseZones[zoneTypeName] === false ||
			zoneData === undefined
		) {
			continue
		}

		for (const zoneItem of zoneData) {
			const geoJson: GeoJSON.Feature = {
				type: "Feature",
				geometry: zoneItem.geometry,
				properties: {
					color: mapLayersColors.diseaseZones[zoneType] as string,
					zoneType: diseaseZoneType[zoneType] as string,
					regulationNumber: zoneItem.forskNr,
				},
			}
			;(layerGroup as GeoJSON).addData(geoJson)
		}
	}
}
