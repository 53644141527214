import { ValueLabelPair } from "@utilityTypes/ValueLabelPair"
import { PageInfo } from "edge-admin-api"
import { ReactElement, useState } from "react"
import { MultiValue } from "react-select"
import Button from "../Button"
import MultiSelect from "../MultiSelect"
import EzTable from "./EzTable"

export default function LoggerController(args: {
	pageInfo: PageInfo
	onOpen: (deviceId: number, key: string, severities: string[]) => void
	selectAbleDevices: {
		id: number
		name: string
	}[]
}): ReactElement {
	const [deviceId, setDeviceId] = useState<string>(
		args.selectAbleDevices[0]?.id?.toString() ?? ""
	)
	const [loggerKey, setLoggerKey] = useState<string>(args.pageInfo.loggerKeys[0] ?? "")
	const [severities, setSeverities] = useState<MultiValue<ValueLabelPair>>([])

	const fetchClicked = () => {
		if (deviceId == undefined || loggerKey == undefined || severities.length == 0) {
			return
		}
		args.onOpen(
			parseFloat(deviceId),
			loggerKey,
			severities.map((s) => s.value)
		)
	}

	return (
		<>
			<EzTable
				data={[
					[
						<select
							className={`h-8 py-0`}
							onChange={(event) => setDeviceId(event.target.value)}
						>
							{args.selectAbleDevices.map((sad, index) => (
								<option key={index} value={sad.id}>
									{sad.name}
								</option>
							))}
						</select>,
						<select
							className={`h-8 py-0`}
							onChange={(event) => setLoggerKey(event.target.value)}
						>
							{args.pageInfo.loggerKeys.map((lk, index) => (
								<option key={index} value={lk}>
									{lk}
								</option>
							))}
						</select>,
						<MultiSelect
							options={args.pageInfo.logEntrySeverities.map((les) => {
								return {
									label: les,
									value: les,
								}
							})}
							onChange={setSeverities}
							isClearable={true}
							value={severities}
						/>,
						<>
							<Button label="fetch" onClick={fetchClicked} />
						</>,
					],
				]}
				header={["device", "logger", "severities", ""]}
			/>
		</>
	)
}
