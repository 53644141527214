import { AggregationMethod, SourceKey } from "api"

export type KpiToFetchProps = {
	clientId: string
	sensor: string[]
	kpi_name: string
	plant_name: string
	owner_name: string
	component_name: string
	aggregationMethod: AggregationMethod[]
	unit: string
	pre_label?: string
	header?: string
	combine_method: string
	display_mode: string
	source: SourceKey
	multiplier?: number
}

export type KpisToFetch = { [kpiName: string]: KpiToFetchProps[] }

export const HUES = [
	360, 235, 25, 120, 50, 340, 90, 40, 265, 65, 320, 150, 15, 280, 80, 300, 180, 5, 230, 70,
]

export const formatNumber2String = (n: number): string => {
	let nStringFormatted: string
	const nStringRounded = Math.floor(n).toString()
	const length = nStringRounded.length
	nStringFormatted = nStringRounded
	for (let i = length - 3; i > 0; i = i - 3) {
		nStringFormatted = [nStringFormatted.slice(0, i), " ", nStringFormatted.slice(i)].join("")
	}

	return nStringFormatted
}

export const ICONS_MAP: { [iconName: string]: string } = {
	"Running Hours": "/assets/img/icons/engineIcon.svg",
	"Fuel Consumption": "/assets/img/icons/fuelIcon.svg",
	CO2: "/assets/img/icons/co2Icon.svg",
	"FUEL CONSUMPTION PER RUNNING HOURS": "/assets/img/icons/fuelIcon.svg",
	"Energy Production": "/assets/img/icons/energyIcon.svg",
	"ENERGY PRODUCTION PER RUNNING HOURS": "/assets/img/icons/energyIcon.svg",
}
