import { getFileName } from "@helpers/getFileName"
import { LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS } from "@queries/useGetTimeseriesQuery"
import { requestApi2 } from "@utils/http"
import { endpoints } from "api"
import { useQuery } from "react-query"

type GetTimeseries4QueryArgs = {
	gte?: Date
	lt?: Date
	sensorIds?: (number | null)[]
}

export function useGetTimeseries4Query(args: GetTimeseries4QueryArgs) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetTimeseries4Query`,
			`endpoints=[${endpoints.getTimeseriesData4.name}]`,
			`sensorIds=[${args.sensorIds}]`,
			`gte=${
				args.gte?.toISOString !== undefined
					? `${args.gte.toISOString().split(":")[0]}:${
							args.gte.toISOString().split(":")[1]
					  }`
					: args.gte
			}`,
			`lt=${
				args.lt?.toISOString !== undefined
					? `${args.lt.toISOString().split(":")[0]}:${
							args.lt.toISOString().split(":")[1]
					  }`
					: args.lt
			}`,
		],
		async (): Promise<
			| {
					sensors: {
						data: [number, number][]
						id: number
					}[]
			  }
			| undefined
			| null
		> => {
			if (
				args.gte === undefined ||
				args.lt === undefined ||
				args.sensorIds === undefined ||
				args.sensorIds.length === 0
			) {
				return undefined
			}
			const response = await requestApi2(endpoints.getTimeseriesData4, {
				interval: { gte: args.gte, lt: args.lt },
				sensorIds: args.sensorIds.filter((s): s is number => s !== null),
				aggregation:
					new Date(args.lt).getTime() - new Date(args.gte).getTime() >
					LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS
						? {
								groupBy: "hour",
								method: "average",
						  }
						: undefined,
			})

			return response !== null
				? {
						sensors: response?.sensors.map((s) => {
							return {
								data: s.data.map((d) => {
									return [d[0].getTime(), d[1]]
								}),
								id: s.id,
							}
						}),
				  }
				: null
		},
		{
			staleTime: 10 * 60 * 1000,
			refetchInterval: 10 * 60 * 1000,
		}
	)
	return res
}
