import { LABELS_MONTHS } from "@helpers/getLabelsMonths"
import { getMonthT1UTC2 } from "@helpers/getMonthT1UTC2"
import { getRangeQuarter, makeLabelQuarter } from "@sop/FuelPage/Total/utils/makeLabels"
import { getFirstDayOfQuarter } from "@sop/FuelPage/Total/utils/utilityFunctions"
import { useTranslation } from "react-i18next"

export const MIN_YEAR_EMISSION = 2024

export const getListOfRangesEmission = () => {
	const yearRef = new Date().getFullYear()
	const monthRef = new Date().getMonth()
	const quarterRef = monthRef - 1
	const quarters = makeLabelQuarter({
		gte: new Date(yearRef - 1, quarterRef),
		lt: new Date(yearRef, quarterRef),
	}).reverse()

	const rangesDates = ["this year", "this month", "last 4 quarters", "custom"]
	let monthCount = monthRef - 1
	while (monthCount >= 0) {
		const currentMonthLabel = LABELS_MONTHS[monthCount]?.[1]
		if (currentMonthLabel === undefined) {
			continue
		}
		rangesDates.push(`${currentMonthLabel}`)
		monthCount--
	}
	for (const q of quarters) {
		rangesDates.push(q)
	}
	for (let i = 1; i <= yearRef - MIN_YEAR_EMISSION; i++) {
		rangesDates.push(`previous year ${yearRef - i}`)
	}
	return [...rangesDates] as const
}

export function useGetRangesDatesEmission() {
	const { t } = useTranslation()
	const monthRef = new Date().getMonth()
	const yearRef = new Date().getFullYear()
	const quarterRef = monthRef - 1

	const quarters = makeLabelQuarter({
		gte: new Date(yearRef - 1, quarterRef),
		lt: new Date(yearRef, quarterRef),
	}).reverse()

	const rangesDates: {
		[rangeRef: string]: { labelShort: string; labelLong: string; t1: Date; t2: Date }
	} = {}

	rangesDates["this year"] = {
		labelShort: `${t("THIS YEAR")}`,
		labelLong: `${t("THIS YEAR")} (${yearRef})`,
		t1: getMonthT1UTC2(0, yearRef),
		t2: new Date(new Date().setMinutes(60, 0, 0)),
	}

	const currentMonthLabel = LABELS_MONTHS[monthRef]?.[0]
	rangesDates["this month"] = {
		labelShort: `${t("THIS MONTH")}`,
		labelLong: `${t("THIS MONTH")} ${
			currentMonthLabel !== undefined ? `(${t(currentMonthLabel)} ${yearRef})` : ""
		}`,
		t1: getMonthT1UTC2(monthRef, yearRef),
		t2: getMonthT1UTC2(monthRef + 1, yearRef),
	}

	let monthCount = monthRef - 1
	while (monthCount >= 0) {
		const currentMonthLabel = LABELS_MONTHS[monthCount]?.[1]
		if (currentMonthLabel === undefined) {
			continue
		}
		rangesDates[currentMonthLabel] = {
			labelShort: `${t(currentMonthLabel)} (${yearRef})`,
			labelLong: `(${t(currentMonthLabel)} ${yearRef})`,
			t1: getMonthT1UTC2(monthCount, yearRef),
			t2: getMonthT1UTC2(monthCount + 1, yearRef),
		}
		monthCount--
	}
	// const lastMonthT1 = getMonthT1UTC2(monthRef - 1, yearRef)
	// const lastMonthLabel = LABELS_MONTHS[lastMonthT1.getMonth()]?.[0]
	// rangesDates["last month"] = {
	// 	labelShort: `${t("LAST MONTH")}`,
	// 	labelLong: `${t("LAST MONTH")} ${
	// 		lastMonthLabel !== undefined
	// 			? `(${t(lastMonthLabel)} ${lastMonthT1.getFullYear()})`
	// 			: ""
	// 	}`,
	// 	t1: lastMonthT1,
	// 	t2: getMonthT1UTC2(monthRef, yearRef),
	// }
	// const lastThirdMonthT1 = getMonthT1UTC2(monthRef - 3, yearRef)
	// const lastThirdMonthLabel = LABELS_MONTHS[lastThirdMonthT1.getMonth()]?.[0]

	// const todayRef = new Date(new Date().setMinutes(60, 0, 0))
	// const lastThirtyDaysT1 = new Date(todayRef)
	// lastThirtyDaysT1.setUTCDate(todayRef.getDate() - 30)
	// lastThirtyDaysT1.setUTCHours(0, 0, 0, 0)

	// rangesDates["last 30 days"] = {
	// 	labelShort: `${t("LAST")} 30 ${t("DAYS")}`,
	// 	labelLong: `${t("LAST")} 30 ${t("DAYS")} ${
	// 		lastMonthLabel !== undefined && lastThirdMonthLabel !== undefined
	// 			? `(${t(lastThirdMonthLabel)} ${
	// 					lastThirdMonthT1.getFullYear() !== lastMonthT1.getFullYear()
	// 						? lastThirdMonthT1.getFullYear()
	// 						: ""
	// 				} - ${t(lastMonthLabel)} ${lastMonthT1.getFullYear()})`
	// 			: ""
	// 	}`,
	// 	t1: lastThirtyDaysT1,
	// 	t2: new Date(new Date().setMinutes(60, 0, 0)),
	// }

	rangesDates["last 4 quarters"] = {
		labelShort: `${t("LAST 4 QUARTERS")}`,
		labelLong: `${t("LAST 4 QUARTERS")} (${yearRef})`,
		t1: getFirstDayOfQuarter(getMonthT1UTC2(monthRef, yearRef - 1)),
		t2: new Date(new Date().setMinutes(60, 0, 0)),
	}

	for (const q of quarters) {
		rangesDates[q] = {
			labelShort: q,
			labelLong: q,
			t1: getRangeQuarter(q).t1,
			t2: getRangeQuarter(q).t2,
		}
	}

	for (let i = 1; i <= yearRef - MIN_YEAR_EMISSION; i++) {
		rangesDates[`previous year ${yearRef - i}`] = {
			labelShort: `${t("PREVIOUS YEAR")} (${yearRef - i})`,
			labelLong: `${t("PREVIOUS YEAR")} (${yearRef - i})`,
			t1: getMonthT1UTC2(0, yearRef - i),
			t2: getMonthT1UTC2(0, yearRef - i + 1),
		}
	}

	return rangesDates
}
