import { LayerData } from "@components/map//utils/mapLayers"
import { useSetMapBoundsV3 } from "@components/map//utils/useSetMapBounds"
import ErrorMessageMap from "@components/map/mapComponents/ErrorMessageMap"
import { MapButtons } from "@components/map/mapComponents/MapButtons"
import useAddLayersDataToMap from "@components/map/utils/useAddLayersDataToMap"
import { FiltersPanelMemo } from "@components/map/windyMap/MapComponent"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getMapFiltersPanelState } from "@redux/features/mapFiltersPanelState/mapFiltersPanelStateSlice"
import { getPanelState } from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { common } from "@utils/common"
import ErrorBoundary from "@utils/errorBoundary/ErrorBoundary"
import { PlantInfo } from "api"
import { Dispatch, ReactElement, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClear, MdSearch } from "react-icons/md"
import { useQueryClient } from "react-query"
import { RegularMapType } from "./regularMap"
import "./regularMap.css"
import useInitRegularMap from "./useInitRegularMap"

type MapData<T> = {
	layersData: LayerData[]
	onSearchChange?: (searchString: string) => void
	searchString?: string | null
	setSearchString?: Dispatch<SetStateAction<string | null>>
	inputsForBoundCalc?: T
	customerName: string
	mapDivId?: string
}

export default function RegularMapComponent3(
	props: MapData<
		| {
				shipData: PlantInfo[]
				trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
		  }
		| undefined
	>
) {
	return (
		<ErrorBoundary fallback={ErrorMessageMap}>
			<RegularMapComponentBodyBounds3 {...props} />
		</ErrorBoundary>
	)
}
function RegularMapComponentBodyBounds3({
	layersData,
	onSearchChange,
	searchString,
	setSearchString,
	inputsForBoundCalc,
	customerName,
	mapDivId = "regularMap",
}: MapData<
	| {
			shipData: PlantInfo[]
			trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
	  }
	| undefined
>): ReactElement {
	const mapFiltersPanelState = useAppSelector(getMapFiltersPanelState)

	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const { isOpen, selectedTab } = useAppSelector(getPanelState)
	const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen)
	const [regularMap, setRegularMap] = useState<RegularMapType>()

	useInitRegularMap(dispatch, queryClient, setRegularMap, regularMap, mapDivId)
	if (regularMap !== undefined) {
		if (isPanelOpen !== isOpen) {
			setTimeout(() => regularMap.resizemap(), 10) // important to ensure map container is resized correctly
			// Create a new resize event
			var event = new Event("resize")
			setTimeout(() => {
				// Dispatch the event on the window object
				window.dispatchEvent(event)
			}, 500) // important to ensure map container is resized correctly
			setIsPanelOpen(isOpen)
		}
	}
	// useAssignRegularMapDiv()
	const selectedVessel = useAppSelector(getSelectedVessel)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)
	useAddLayersDataToMap(layersData, undefined, { map: regularMap }, customerName, plantData)
	useSetMapBoundsV3(regularMap, inputsForBoundCalc)
	const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null)

	return (
		<>
			{/* REGULAR MAP CONTAINER */}
			<div
				ref={(newRef) => setMapRef(newRef)}
				id={mapDivId}
				className={`h-full w-full`}
			/>

			{/* GLOBAL FILTER */}
			{onSearchChange !== undefined && setSearchString !== undefined ? (
				<div
					id={"globalFilter"}
					className="absolute top-[14px] left-16 z-900 inline-block rounded-md border-2 border-[rgba(0,0,0,0.2)]"
				>
					<div className="absolute inset-x-1 inset-y-1 h-6 w-6">
						<MdSearch className={`h-6 w-6`} />
					</div>
					<input
						className="text-menu shadow-card hover:border-alert-focus focus:border-alert-focus h-8 rounded-sm bg-white px-8 focus:border-1 focus:outline-hidden"
						value={searchString ?? ""}
						onChange={(e) => {
							setSearchString(e.target.value)
							onSearchChange(e.target.value)
						}}
						placeholder={t("SEARCH VESSEL BY NAME")}
					/>
					{searchString !== "" && searchString !== undefined && (
						<div
							className="text-gray-666 absolute inset-y-1 right-1 h-6 w-6 cursor-pointer"
							onClick={() => {
								setSearchString("")
								onSearchChange("")
							}}
						>
							<MdClear className={`h-6 w-6`} />
						</div>
					)}
				</div>
			) : null}
			{/* MAP BUTTONS */}
			<MapButtons map={regularMap} />

			{/* FILTERS PANEL */}
			<FiltersPanelMemo
				dispatch={dispatch}
				isOpen={mapFiltersPanelState.isOpen}
				mapRef={mapRef}
				plantData={plantData}
			/>

			{/* MAPTILE ATTRIBUTION */}
			<a
				id="maptilerLink"
				href="https://www.maptiler.com"
				className="absolute bottom-4 left-4 z-999"
			>
				<img
					src="https://api.maptiler.com/resources/logo.svg"
					alt="MapTiler logo"
				/>
			</a>
		</>
	)
}
