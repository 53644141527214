import { requestApi2 } from "@utils/http"
import { endpoints } from "api"
import Button from "./Button"

const DEFAULT_MSG = `Dear Dipai user,

The portal will automatically re-start, due to a software update.

Best regards,
The Dipai developers`

export default function NotificationSubmitPage() {
	const ID = "notificationSubmitTextArea"

	async function submit() {
		const elem = document.getElementById(ID) as HTMLTextAreaElement | undefined
		if (elem == undefined) {
			throw new Error(`Element not found: ${ID}`)
		}
		const text = elem.value
		const confirmation = confirm(`Are you sure you want to submit this message?:\n\n${text}`)
		if (confirmation === true) {
			requestApi2(endpoints.submitNotification, { message: text })
		}
	}

	async function reloadAll() {
		const c = confirm(`Are you sure you want to submit a reload signal? This might annoy users`)
		if (c === true) {
			await requestApi2(endpoints.submitReload)
		}
	}

	return (
		<>
			<textarea id={ID} rows={30} cols={100} defaultValue={DEFAULT_MSG} />
			<p>
				Warning! Submitted messages will be sent to all users that are connected to this
				server: {window.location.hostname}
			</p>
			<Button label="Submit" onClick={submit} />
			<Button label="Reload all clients" onClick={reloadAll} />
		</>
	)
}
