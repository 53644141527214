import Logout from "@components/login/Logout"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { useAppSelector } from "@redux/app/hooks"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { SessionValidityStatus } from "api"
import { ReactElement } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"

export type UserDropdownProps = {
	userData?: Omit<SessionValidityStatus, "isAuthenticated"> &
		Partial<Pick<SessionValidityStatus, "isAuthenticated">>
}

export default function UserDropdown({ userData }: UserDropdownProps): ReactElement {
	userData = useAppSelector(getUserData)
	const userNameSplit = userData.name.split(" ")
	const userNameSplit_0 = userNameSplit[0]
	const userNameSplot_last =
		userNameSplit[userNameSplit.length > 0 ? userNameSplit.length - 1 : 0]

	return (
		<div className="flex h-10 rounded-[20px] p-1 pr-2 hover:bg-gray-eee focus:shadow-none focus:outline-hidden">
			<DropdownMenu.Root>
				<DropdownMenu.Trigger>
					<div className="cursor-pointer ">
						<div
							className={`float-right ml-2 flex h-8 items-center text-dipai-secondary-901`}
						>
							<MdKeyboardArrowDown />
						</div>
						<p
							className={`float-right mt-1 inline-block text-menu font-bold text-dipai-secondary-901`}
						>
							{userNameSplit_0 !== undefined
								? `${userNameSplit_0} ${
										userNameSplit.length > 1 ? userNameSplot_last : ""
								  }`
								: "Unknonwn User"}
						</p>
						{userData.profilePicture !== undefined && userData.profilePicture !== "" ? (
							<img
								className={`float-right mr-3 h-8 w-8 rounded-full border-2 border-dipai-secondary-901`}
								src={userData.profilePicture}
								alt=""
							/>
						) : (
							<div
								className={`float-right mr-3 inline-block h-8 w-8 rounded-full border-2 border-dipai-secondary-901 bg-white text-center align-middle`}
							>
								<p
									className={`pt-1 text-small font-bold uppercase text-dipai-secondary-901`}
								>
									{userNameSplit_0 !== undefined
										? `${userNameSplit_0?.[0]}${
												userNameSplit.length > 1
													? userNameSplot_last?.[0]
													: ""
										  }`
										: `UU`}
								</p>
							</div>
						)}
					</div>
				</DropdownMenu.Trigger>
				<DropdownMenu.Content className="mt-2 w-[200px] rounded-lg bg-white p-[6px] shadow-popup">
					<DropdownMenu.Item className="cursor-pointer rounded-md px-[14px] py-1 text-menu hover:bg-gray-ddd hover:outline-hidden">
						<Logout />
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Root>
		</div>
	)
}
