import { FacilityRadiusPopup } from "@components/map/mapComponents/FacilityRadiusPopup"
import { MapElementMetadata } from "@components/map/utils/utils"
import { AppDispatch } from "@redux/app/store"
import { useNavigate } from "@tanstack/react-router"
import { Facility } from "api"
import { Circle, GeoJSON, LayerGroup, Map as LeafletMap, Marker, Polyline } from "leaflet"
import ReactDOM from "react-dom/client"
import { QueryClient } from "react-query"
import { LayerData, mapLayersColors } from "../utils/mapLayers"
import { LayerOptionsWithMetadata } from "../windyMap/windyMap"

function generateFacilityRadiusPopupContent(args: {
	facility: Facility
	dispatch: AppDispatch
	queryClient: QueryClient
	circle: Circle
	facilities: Facility[]
	isDipaiUser: boolean
}) {
	const popupNode = document.createElement("div")
	popupNode.id = "popupDiv"
	ReactDOM.createRoot(popupNode).render(
		<FacilityRadiusPopup
			facilities={args.facilities}
			facility={args.facility}
			dispatch={args.isDipaiUser === true ? args.dispatch : undefined}
			queryClient={args.queryClient}
			circle={args.circle}
		/>
	)
	return popupNode
}

export interface CustomCircleOptions extends L.CircleMarkerOptions {
	metadata?: MapElementMetadata
}

export const addFacilitiesWithRadius = (
	dispatch: AppDispatch,
	queryClient: QueryClient,
	layerGroup: LayerGroup,
	layerData: LayerData,
	navigate?: ReturnType<typeof useNavigate>,
	map?: LeafletMap
) => {
	if (layerGroup === undefined) return

	const { facilitiesRadius } = layerData
	if (facilitiesRadius === undefined) return

	layerGroup.clearLayers()
	const canvasRenderer = L.canvas({ padding: 0.5, pane: "facilitiesRadiusPane" })
	const Circle: Circle[] = []
	for (const facility of facilitiesRadius.facilities) {
		const circle = L.circle([facility.latitude, facility.longitude], {
			renderer: canvasRenderer,
			color: mapLayersColors.facilities[facility.type],
			fillColor: mapLayersColors.facilities[facility.type],
			fillOpacity: 0.5,
			weight: 2,
			dashArray: [6],
			radius: facility.radiusM,
			metadata: {
				facility: { name: facility.name, type: facility.type, radius: facility.radiusM },
			},
		} as CustomCircleOptions)
		// .bindTooltip(
		// 	`${facility.name}</br>${facility.type !== "FISH_FARM" ? facility.type : "FISH FARM"}`
		// )
		// const radius = circle.getRadius().circle.on("mouseover", function () {
		// 	if (circle.isPopupOpen() === true) {
		// 		circle.closeTooltip()
		// 	}
		// })
		circle.on("click", function () {
			circle.toggleTooltip()
			const popupContent = generateFacilityRadiusPopupContent({
				facility,
				facilities: facilitiesRadius.facilities,
				dispatch,
				queryClient,
				circle,
				isDipaiUser: facilitiesRadius.company.toLowerCase() === "dipai" ? true : false,
			})

			const popup = L.popup({
				autoPan: true, // Ensure it pans if necessary
				offset: L.point(0, 0),
				className: "custom-popup", // Add a custom class to target the popup
			})
				.setContent(popupContent)
				.setLatLng(circle.getLatLng())

			popup.on("add", function () {
				requestAnimationFrame(() => {
					const popupWrapper = document.querySelector(
						".custom-popup .leaflet-popup-content-wrapper"
					)

					if (popupWrapper !== null) {
						popupWrapper.classList.remove("leaflet-popup-content-wrapper")
						popupWrapper.classList.add(
							"bg-white",
							"rounded-sm",
							"py-1",
							"px-0",
							"shadow-card"
						)
					}
					popup.options.offset = L.point(0, 0)
					popup.update()

					map?.eachLayer((layer) => {
						layer.unbindTooltip()
						if ((layer.options as LayerOptionsWithMetadata).metadata !== undefined) {
							layer.fireEvent("mouseout")
						}

						const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
						if (
							layerOptionsTyped?.oldStyle !== undefined &&
							layerOptionsTyped?.fillOpacity !== undefined &&
							layerOptionsTyped.fillOpacity === 0.85
						) {
							if ((layer as unknown as GeoJSON).setStyle !== undefined) {
								;(layer as unknown as GeoJSON).setStyle({
									fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
									weight: layerOptionsTyped?.oldStyle.weight,
									color: layerOptionsTyped?.oldStyle.color,
									dashArray: layerOptionsTyped?.oldStyle.dashArray,
									stroke: layerOptionsTyped?.oldStyle.stroke,
								})
							} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
								;(layer as unknown as Marker).setOpacity(0.5)
							} else if ((layer as unknown as Polyline).setStyle !== undefined) {
								;(layer as unknown as Polyline).setStyle({
									fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
									weight: layerOptionsTyped?.oldStyle.weight,
									color: layerOptionsTyped?.oldStyle.color,
									dashArray: layerOptionsTyped?.oldStyle.dashArray,
									stroke: layerOptionsTyped?.oldStyle.stroke,
								})
							}
						}
					})
				})
			})
			circle.bindPopup(popup).openPopup()
		})

		Circle.push(circle)
		layerGroup.addLayer(circle)
	}
	layerGroup.addLayer(L.layerGroup(Circle))
}
