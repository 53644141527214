import { createOilAndGasFacilityPopup } from "@components/map/mapComponents/oilAndGasFacilityPopup"
import { DataSetter, mapLayersColors } from "../utils/mapLayers"

export const addOilAndGasFacilities: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { oilAndGasData } = layerData
	if (oilAndGasData === undefined) return

	layerGroup.clearLayers()

	for (const facility of oilAndGasData) {
		if (facility.fclNsDeg === "" || facility.fclEwDeg === "") {
			continue
		}
		layerGroup.addLayer(
			L.circleMarker(
				[
					parseFloat(facility.fclNsDeg) +
						parseFloat(facility.fclNsMin) / 60 +
						parseFloat(facility.fclNsSec) / 3600,
					parseFloat(facility.fclEwDeg) +
						parseFloat(facility.fclEwMin) / 60 +
						parseFloat(facility.fclEwSec) / 3600,
				],
				{
					color: "#ffffff",
					fillColor:
						facility.fclSurface === "Y"
							? mapLayersColors.oilAndGasFacilities.SURFACE
							: mapLayersColors.oilAndGasFacilities.UNDERWATER,
					fillOpacity: 0.5,
					weight: 1,
					radius: 5,
					pane: "oilAndGasPane",
				}
			).bindTooltip(createOilAndGasFacilityPopup(facility))
		)
	}
}
