// import "./multiSelect.css"
import * as Tabs from "@radix-ui/react-tabs"
import { requestApi2 } from "@utils/http"
import { DbDevice, DbDeviceConfig, DbDeviceConfigs, endpoints } from "api"
import { ReactElement, useEffect, useState } from "react"
import DeviceConfigEditor from "./DeviceConfigEditor"
import DeviceConfigParser from "./DeviceConfigParser"
import DeviceConfigPusher from "./DeviceConfigPusher"
import DeviceConfigRaw from "./DeviceConfigRaw"

export default function DeviceConfigPage() {
	const [activeTab, setActiveTab] = useState<string>("0")
	const [tabs, setTabs] = useState<Tab[]>([])

	const [devices, setDevices] = useState<DbDevice[] | undefined>()
	const [deviceId, setDeviceId] = useState<number | undefined>()
	const [configsForSelectedDevice, setConfigsForSelectedDevice] = useState<
		DbDeviceConfigs | undefined
	>()
	const [configId, setConfigId] = useState<number | undefined>()
	const [selectedConfig, setSelectedConfig] = useState<DbDeviceConfig>()
	const [currentDeviceId, setCurrentDeviceId] = useState<number>(0)

	async function loadListOfDevices() {
		const res = await requestApi2(endpoints.getAllDevices)
		if (res === null) {
			return
		}
		setDevices(res)
	}

	useEffect(() => {
		if (devices == undefined) {
			loadListOfDevices()
		}
	}, [])

	async function loadDeviceConfig() {
		if (deviceId == undefined) return
		const tmp = await requestApi2(endpoints.deviceConfigGet, {
			id: deviceId,
		})
		if (tmp === null) {
			return
		}
		setConfigsForSelectedDevice(tmp)

		// setCurrentDeviceId(tmp.id)
	}

	useEffect(() => {
		loadDeviceConfig()
	}, [deviceId])

	useEffect(() => {
		setSelectedConfig(undefined)
		if (configsForSelectedDevice !== undefined) {
			for (const configgg of configsForSelectedDevice) {
				if (configgg.id == configId) {
					setSelectedConfig(configgg)
					break
				}
			}
		}
	}, [configId])
	function tab(number: string, label: string): ReactElement {
		return (
			<Tabs.Trigger key={number.toString()} value={number}>
				<p
					className={`${
						activeTab === number
							? "inline-block h-[40px] border-b-2 border-dipai-primary-501 px-4 text-menu font-bold hover:text-gray-333"
							: "inline-block h-[40px] border-b-2 border-transparent px-4 text-menu text-gray-333 hover:cursor-pointer hover:font-bold"
					} tab-button`}
				>
					{label}
				</p>
			</Tabs.Trigger>
		)
	}

	type Tab = {
		label: string
		content: ReactElement
	}

	useEffect(() => {
		if (selectedConfig !== undefined) {
			const local_tabs: Tab[] = [
				{
					label: "Config Parser",
					content: <DeviceConfigParser props={selectedConfig.config} />,
				},
				{
					label: "Raw",
					content: <DeviceConfigRaw props={selectedConfig} />,
				},
				{
					label: "Editor",
					content: <DeviceConfigEditor props={selectedConfig} />,
				},
				{
					label: "Config Pusher",
					content: <DeviceConfigPusher props={selectedConfig} />,
				},
			]
			setTabs(local_tabs)
		} else {
			setTabs([])
		}
	}, [selectedConfig])

	return (
		<>
			{devices == undefined ? (
				<p>Loading...</p>
			) : (
				<>
					<label htmlFor="device-selector">Plant</label>
					<br></br>
					<select
						id="device-selector"
						onChange={(evt) => (
							setDeviceId(parseFloat(evt.target.value)),
							setConfigsForSelectedDevice(undefined),
							setConfigId(-1)
						)}
						defaultValue={""}
					>
						<option disabled value="">
							{" "}
							-- select an option --{" "}
						</option>
						{devices.map((device, index) => {
							return (
								<option key={index} value={device.id}>
									{device.name + " @ " + device.plantName}
								</option>
							)
						})}
					</select>
				</>
			)}

			{configsForSelectedDevice === undefined ? (
				<p></p>
			) : (
				<>
					<br></br>
					<label htmlFor="config-selector-label">Config</label>
					<br></br>

					<select
						id="config-selector"
						onChange={(evt) => setConfigId(parseFloat(evt.target.value))}
						defaultValue={""}
					>
						<option disabled value="">
							{" "}
							-- select an option --{" "}
						</option>
						{configsForSelectedDevice.map((config, index) => {
							return (
								<option key={index} value={config.id}>
									{config.timestamp}
								</option>
							)
						})}
					</select>
				</>
			)}

			<Tabs.Root
				defaultValue="0"
				orientation="vertical"
				className=""
				onValueChange={setActiveTab}
			>
				<Tabs.List>{tabs.map((t, index) => tab(index.toString(), t.label))}</Tabs.List>
				{tabs.map((t, index) => (
					<Tabs.Content key={index} value={index.toString()}>
						{t.content}
					</Tabs.Content>
				))}
			</Tabs.Root>
		</>
	)
}
