import { flexRender, HeaderGroup } from "@tanstack/react-table"
import { ReactElement } from "react"
import { twMerge } from "tailwind-merge"

export function SortIcon({ type }: { type: "up" | "down" }): ReactElement {
	return (
		<svg
			className={`h-3 w-4 ${type === "up" ? "rotate-90" : "-rotate-90"} my-auto ml-[9px]`}
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M15 19l-7-7 7-7"
			/>
		</svg>
	)
}

export type HeaderCell = {
	Header: string
	accessor: string
	disableSortBy?: boolean
	disableGlobalFilter?: boolean
}

export type TableHeaderProps<T> = {
	headerGroups: HeaderGroup<T>[]
	columnsLayout?: string
	columnsLayoutStyle?: string
	className?: string
}

export function TableHeader<T>(props: TableHeaderProps<T>): ReactElement {
	return (
		<>
			{props.headerGroups.map((headerGroup) => (
				<div
					key={headerGroup.id}
					style={{ gridTemplateColumns: props.columnsLayoutStyle }}
					className={twMerge(
						`grid min-h-[50px] select-none py-1 ${
							props.columnsLayout ?? ""
						} gap-4 border-t-1 border-gray-300 bg-white px-4 text-tiny font-bold leading-[12px] tracking-[0.5px]`,
						props.className
					)}
				>
					{headerGroup.headers.map((header, idx) => (
						<div
							key={header.id}
							className={`${
								props.headerGroups.length > 1 &&
								idx !== headerGroup.headers.length - 1 &&
								(header.isPlaceholder === false ||
									headerGroup.headers[idx + 1]?.isPlaceholder === false) &&
								headerGroup.depth === 0
									? "border-r-1 pr-[3px]"
									: "pr-1"
							}`}
							style={{ gridColumn: `span ${header.subHeaders.length}` }}
						>
							<div
								className={`flex h-full flex-row items-center ${
									header.column.getCanSort() === true
										? `cursor-pointer select-none`
										: ``
								}`}
								onClick={header.column.getToggleSortingHandler()}
							>
								<span className={`col-span-3 h-full w-full py-1`}>
									{header.isPlaceholder === true
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext()
										  )}
								</span>
								<div className={`ml-[-5px]`}>
									{header.column.getIsSorted() === "desc" ? (
										<SortIcon type={"down"} />
									) : header.column.getIsSorted() === "asc" ? (
										<SortIcon type={"up"} />
									) : null}
								</div>
							</div>
						</div>
					))}
				</div>
			))}
		</>
	)
}
