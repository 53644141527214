import useGetShipDataForMap from "@components/map/dataGettterHooks/useGetShipDataForMap"
import SidePanelMapNL from "@components/sidePanelMap/SidePanelMapNL"
import HomePageListOfOperationPortalPlants from "@pages/HomePage/operationPortalTable/HomePageListOfOperationPortalPlants"
import { NotFoundPageNL } from "@pages/NotFoundPage/NotFoundPage"
import { RequestStatus } from "@redux/app/RequestStatus"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import {
	getCurrentCompany,
	setCurrentCompany,
} from "@redux/features/currentCompany/currentCompanySlice"
import { getPanelState } from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import {
	clearSelectedVessel,
	getSelectedVessel,
} from "@redux/features/selectedVessel/selectedVesselSlice"
import { fetchUserData } from "@redux/features/userData/userDataSlice"
import { getWarningCondition, WARNING_DIALOG_HEIGHT } from "@sop/DashboardPage/DashboardPage"
import { LoadingCircle } from "@ui-lib/src"
import { common } from "@utils/common"
import { AppStructure } from "api"
import { ReactElement, useEffect, useRef } from "react"
import MapContainer from "../../components/map/windyMap/MapContainer"
import EventLogContent from "./EventLogContent"
import KpisContent from "./KpisContent"
import VesselsContentNL from "./VesselsContentNL"

function ExpandedTable(): ReactElement {
	const appStruct = useAppSelector(getAppStructure)
	return appStruct !== null ? <HomePageListOfOperationPortalPlants props={appStruct} /> : <></>
}

function ComponentBody({ props, currentCompany }: { props: AppStructure; currentCompany: string }) {
	const dispatch = useAppDispatch()
	const [vessels, isPositionDataLoading, isLiveOpModeDataLoading] = useGetShipDataForMap(
		props,
		currentCompany,
		undefined,
		false
	)

	const selectedVessel = useAppSelector(getSelectedVessel)

	if (selectedVessel.name !== "") {
		if (
			currentCompany.toLowerCase() !== "dipai" &&
			common.plantDataFromOwnerAndName.get(
				`${currentCompany?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
			) === undefined
		) {
			dispatch(clearSelectedVessel())
		} else {
			if (common.plantDataFromName.get(selectedVessel.name) === undefined) {
				dispatch(clearSelectedVessel())
			}
		}
	}

	const mapRef = useRef<HTMLDivElement>(null)

	const contents: ReactElement[] = [
		<VesselsContentNL showHeader={currentCompany.toLowerCase() === "dipai" ? false : true} />,
		<KpisContent />,
		<EventLogContent />,
	]

	const postStatus = useAppSelector((state) => state.userData.status)

	useEffect(() => {
		if (postStatus === RequestStatus.idle) {
			dispatch(fetchUserData())
		}
	}, [postStatus, dispatch])

	const { isOpen } = useAppSelector(getPanelState)

	const warningCondition = getWarningCondition()

	return currentCompany === undefined ? (
		<NotFoundPageNL
			linkTo={"./../"}
			labelTo={"Go back"}
		/>
	) : isPositionDataLoading === true || isLiveOpModeDataLoading === true ? (
		<LoadingCircle containerHeightWithUnit="100vh" />
	) : (
		// : paramSelectedVessel !== "" &&
		//   Array.isArray(filteredVesselList) &&
		//   filteredVesselList.length === 0 ? (
		// 	<NotFoundPageNL linkTo={"./../"} labelTo={"Go to Map"} />
		// )
		<div
			className={`relative w-full overflow-hidden`}
			style={{
				height:
					warningCondition === true
						? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
						: "100vh",
			}}
		>
			<div
				id="map window"
				ref={mapRef}
				className={`absolute min-h-[600px] ${
					isOpen === false
						? "right-0 w-full duration-500"
						: "right-0 w-[calc(100%-590px)] duration-500"
				} transition-[width]`}
				style={{
					height:
						currentCompany.toLowerCase() === "dipai"
							? warningCondition === true
								? `calc(100vh - 52 - ${WARNING_DIALOG_HEIGHT}px)`
								: `calc(100vh - 52)`
							: warningCondition === true
								? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
								: "100vh",
				}}
			>
				<MapContainer
					clientId={`${currentCompany}:All`}
					specificPlant={undefined}
					props={props}
				/>
			</div>
			<SidePanelMapNL
				contents={contents}
				contentsLabels={["Operation", "KPIs", "Events Log"]}
				ExpandedTable={ExpandedTable}
			/>
		</div>
	)
}

export default function MapPageContent({ props }: { props: AppStructure }): ReactElement {
	const dispatch = useAppDispatch()
	const companyName = Object.keys(props)[0]
	const currentCompany = useAppSelector(getCurrentCompany) ?? ""
	if (currentCompany === "" || currentCompany !== companyName) {
		dispatch(setCurrentCompany(companyName))
	}
	if (currentCompany === undefined || companyName === undefined) {
		return (
			<NotFoundPageNL
				linkTo={"./../"}
				labelTo={"Go back"}
			/>
		)
	}
	return (
		<ComponentBody
			props={props}
			currentCompany={companyName}
		/>
	)
}
