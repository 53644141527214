import { DbDataReport } from "api"
import { ReactElement } from "react"

export default function DbDataTable(props: { data: DbDataReport }): ReactElement {
	const data = props.data
	return (
		<div className="mt-6 mb-6">
			<h1 className="text-title3"></h1>
			<table>
				<thead>
					<tr>
						{data.columns.map((col, i) => (
							<th key={i}>{col}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.data.map((dRow, i) => (
						<tr key={i}>
							{dRow.map((value, i) => {
								return <td key={i}>{`${value}`}</td>
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
