import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import { getFileName } from "@helpers/getFileName"
import { useAppSelector } from "@redux/app/hooks"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { requestApi2 } from "@utils/http"
import { PortalFeature, endpoints } from "api"
import { WeekNumbers } from "luxon"
import { useQuery } from "react-query"

export type BwFishFarmWeekData = {
	year: number
	week: number
	localities: BwFishFarmData[]
}

export type BwFishFarmData = {
	localityNo: number
	localityWeekId: number
	name: string
	hasReportedLice: boolean
	isFallow: boolean
	avgAdultFemaleLice: number
	hasCleanerfishDeployed: boolean
	hasMechanicalRemoval: boolean
	hasSubstanceTreatments: boolean
	hasPd: boolean
	hasIla: boolean
	municipalityNo: string
	municipality: string
	lat: number
	lon: number
	isOnLand: boolean
	inFilteredSelection: boolean
	hasSalmonoids: boolean
	isSlaughterHoldingCage: boolean
}

type FishFarmDataQuery = {
	postData: {
		year: number
		week: WeekNumbers
		orgNo: number | null
	}
	companyName: string | undefined
}
export default function useFishFarmDataQuery(args: FishFarmDataQuery) {
	const queryKeys = [
		`source=${getFileName()}`,
		`queryId=FishFarmData`,
		`endpoints=[${endpoints.getFishInfo.name}]`,
		`companyName=${args.companyName}`,
	]
	if (args.postData.orgNo !== null) {
		queryKeys.push(`orgNo=${args.postData.orgNo}`)
	}
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	const response = useQuery(
		queryKeys,
		async () => {
			if (args.companyName === undefined) {
				return undefined //should this be really undefined??
			}
			if (
				isFeatureAvailable(
					undefined,
					PortalFeature.FISH_FARM,
					args.companyName,
					devFeatures,
					userData.portalMode
				) === false
			) {
				return undefined //should this be really undefined??
			}
			const fishInfo = await requestApi2(endpoints.getFishInfo, args.postData)
			return fishInfo !== null ? (JSON.parse(fishInfo) as BwFishFarmWeekData) : undefined
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return response
}
