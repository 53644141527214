import { ReactElement, ReactNode } from "react"

type CardProps = {
	children: ReactNode
	width: number | string
	height: number | string
	padding?: number | string
	isButton?: boolean
	id?: string
	overflowX?: "hidden" | "visible" | "clip" | "scroll" | "auto"
}

export default function Card({
	children,
	width,
	height,
	padding = 10,
	isButton = false,
	id,
	overflowX = "hidden",
}: CardProps): ReactElement {
	return isButton ? (
		<div
			className="cursor-pointer overflow-hidden rounded-lg border-1 border-gray-ddd bg-white shadow-card transition ease-in-out hover:scale-105 hover:shadow-card"
			style={{ width: width, height: height, padding: padding }}
		>
			{children}
		</div>
	) : (
		<div
			id={id}
			className="overflow-y-hidden rounded-lg bg-white shadow-card"
			style={{ width: width, height: height, padding: padding, overflowX: overflowX }}
		>
			{children}
		</div>
	)
}
