import BarLoader from "react-spinners/BarLoader"
import { TableLoadingBarProps } from "../TableLoadingBar"

export function TableMapPageLoadingBar(props: TableLoadingBarProps) {
	return (
		<div className={`mb-2 border-t-1 border-t-gray-300`}>
			{props.loading ? (
				<BarLoader
					color={"#a2c0d7"}
					loading={props.loading}
					cssOverride={{ display: "block", margin: "0 auto" }}
					width={"100%"}
				/>
			) : (
				<div className={`h-[4px] w-full`} />
			)}
		</div>
	)
}
