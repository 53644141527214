import { Dispatch, SetStateAction, useCallback, useState } from "react"

export default function useLocalStorage(
	key: string,
	defaultValue: string
): [string | null, Dispatch<SetStateAction<string | null>>] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === "undefined") {
			return defaultValue
		}
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key)
			// Parse stored json or if none return defaultValue
			return item !== null ? JSON.parse(item) : defaultValue
		} catch (error) {
			// If error also return defaultValue
			console.log(error)
			return defaultValue
		}
	})
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = useCallback((value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			if (typeof window !== "undefined") {
				window.localStorage.setItem(key, JSON.stringify(valueToStore))
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}, [])
	return [storedValue, setValue]
}
