import { requestApi2 } from "@utils/http"
import { ConfigurationModule, endpoints } from "api"
import { ReactElement, useEffect, useState } from "react"
import Button from "./Button"

export default function DbConfigModuleKeySelector(props: {
	label: string
	module: ConfigurationModule
	onSetKey: (newKey: string) => void
}): ReactElement {
	const [keys, setKeys] = useState<string[]>()

	const fetchKeys = async () => {
		const keys = await requestApi2(endpoints.dbConfigGetModuleKeys, { module: props.module })
		if (keys == null) {
			return
		}
		keys.sort()
		setKeys(keys)
	}

	useEffect(() => {
		fetchKeys()
	}, [])

	return (
		<>
			{keys == undefined ? (
				<div>Loading ...</div>
			) : (
				<>
					<Button label="Refresh" onClick={fetchKeys} />
					<br />
					<label htmlFor="keySelect">{props.label}</label>
					<br />
					<select id="keySelect" onChange={(evt) => props.onSetKey(evt.target.value)}>
						{[""].concat(keys).map((s) => (
							<option key={s} value={s}>
								{s}
							</option>
						))}
					</select>
				</>
			)}
		</>
	)
}
