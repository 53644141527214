export const weatherSensorsUniSeaDict = [
	{ id: "windDirection", label: "Wind Direction", unit: "°" },
	{ id: "windSpeed", label: "Wind Speed (10m)", unit: "kn" },
	{ id: "trueWindDirection", label: "True Wind Direction", unit: "°" },
	{ id: "trueWindSpeed", label: "True Wind Speed", unit: "kn" },
	{ id: "airTemperature", label: "Air Temperature", unit: "°C" },
	{ id: "waterTempC", label: "Water Temperature", unit: "°C" },
	{ id: "waveHeightM", label: "Wave Height", unit: "m" },
	{ id: "waveDirectionDeg", label: "Wave Direction", unit: "°" },
	{ id: "windWavePeriodSec", label: "Wind Wave Period", unit: "s" },
	{ id: "windWaveHeight", label: "Wind Wave Height", unit: "m" },
	{ id: "currentSpeedMps", label: "Ocean Current Speed", unit: "m/s" },
	{ id: "currentDirectionDeg", label: "Ocean Current Direction", unit: "°" },
	{ id: "wavePeriodSec", label: "Wave Period", unit: "s" },
	{ id: "windWaveDirectionDeg", label: "Wind Wave Direction", unit: "°" },
	{ id: "humidityPercent", label: "Air Humidity", unit: "%" },
	{ id: "relativeWindDirection", label: "Relative Wind Direction", unit: "°" },
	{ id: "relativeWindSpeed", label: "Relative Wind Speed", unit: "kn" },
]
