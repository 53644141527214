import { LABELS_MONTHS } from "@helpers/getLabelsMonths"
import { IconButton, Tooltip } from "@ui-lib/src"
import { AggregationConfig } from "api"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import {
	MdAddchart,
	MdDelete,
	MdRawOff,
	MdRawOn,
	MdSettings,
	MdSsidChart,
	MdStackedLineChart,
	MdVisibility,
	MdVisibilityOff,
	MdZoomInMap,
	MdZoomOutMap,
} from "react-icons/md"

type PlotHeadingProps = {
	cardRef?: HTMLDivElement | null
	buttonsSetup: ("close" | "delete" | "expand" | "settings" | "quickAdd")[]
	handleConfigOpen?: () => void
	handleDelete?: () => void
	handleExpandOrClose?: () => void
	handleQuickAdd?: () => void
	plotName: string
	currentOpMode?: { modeName: string; modeColor: string }
	currentTimestamp?: number
	showOpModeBg?: boolean
	setShowOpModeBg?: Dispatch<SetStateAction<boolean | undefined>>
	showRawData?: boolean
	setShowRawData?: Dispatch<SetStateAction<boolean | undefined>>
	isDataDecimated?: boolean
	showDataDecimated?: boolean
	setShowDataDecimeted?: Dispatch<SetStateAction<boolean | undefined>>
	isDataLoading?: boolean
	aggregationMethod?: AggregationConfig["method"]
	setAggregationMethod?: Dispatch<SetStateAction<AggregationConfig["method"]>>
	showAggregationSelector?: boolean
}

export default function PlotHeading(props: PlotHeadingProps) {
	const { t } = useTranslation()
	return (
		<div className={`flex flex-col `}>
			<div className={`flex flex-row justify-between`}>
				<div className={`flex flex-row items-center gap-2`}>
					{props.plotName !== "" ? (
						<p className={`text-body font-bold leading-6 text-[#101231]`}>
							{props.plotName}
						</p>
					) : null}
					<p className={props.plotName !== "" ? `pl-3` : ``}> {`${t("TIMESTAMP")}: `}</p>
					{(() => {
						if (props.currentTimestamp !== undefined && props.isDataLoading !== true) {
							const date = new Date(props.currentTimestamp)
							return (
								<p className={`rounded-sm pr-2`}>{`${
									date.getDate() < 10 ? "0" : ""
								}${date.getDate()} ${t(
									LABELS_MONTHS?.[date.getMonth()]?.[0] ?? ""
								)} - ${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
									date.getMinutes() < 10 ? "0" : ""
								}${date.getMinutes()}:${
									date.getSeconds() < 10 ? "0" : ""
								}${date.getSeconds()}`}</p>
							)
						} else {
							return <p className={`rounded-sm px-2`}>{`--`}</p>
						}
					})()}
					<p className={props.plotName !== "" ? `pl-3` : ``}> {`${t("MODE")}: `}</p>
					<p
						className={`rounded-sm px-2`}
						style={{
							backgroundColor:
								props.isDataLoading === true
									? undefined
									: props.currentOpMode?.modeColor,
						}}
					>
						{props.isDataLoading === true
							? `--`
							: props.currentOpMode?.modeName ?? `--`}
					</p>
				</div>
				<div className={`flex items-center gap-2`}>
					{props.aggregationMethod !== undefined &&
					props.setAggregationMethod !== undefined &&
					props.showAggregationSelector === true &&
					props.isDataLoading === false ? (
						<div className={`flex flex-row gap-2 border-r-1 pr-2`}>
							<label
								className={`flex items-center text-small`}
								htmlFor={`selectorAggregation`}
							>
								{t("AGGREGATION")}
							</label>
							<select
								id={`selectorAggregation`}
								name={`selectorAggregation`}
								disabled={false}
								className={`h-8 w-[110px] rounded-sm border-1 border-gray-ccc py-[2px] text-small hover:border-dipai-secondary-500 focus:border-2 focus:border-dipai-secondary-500 focus:outline-hidden focus:ring-0`}
								value={props.aggregationMethod}
								onChange={(e) => {
									if (props.setAggregationMethod === undefined) {
										return
									}
									props.setAggregationMethod(
										e.target.value as AggregationConfig["method"]
									)
								}}
							>
								<option value="average">{"average"}</option>
								<option value="latest">{"last"}</option>
								<option value="greatest">{"max"}</option>
							</select>
						</div>
					) : null}
					{props.showOpModeBg !== undefined && props.setShowOpModeBg !== undefined ? (
						<Tooltip
							collisionBoundary={props.cardRef}
							customContent={t(
								props.showOpModeBg === true
									? "HIDE OPERATION MODES COLORS"
									: "SHOW OPERATION MODES COLORS"
							)}
						>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.setShowOpModeBg !== undefined &&
									props.setShowOpModeBg((prev) =>
										prev !== undefined ? !prev : undefined
									)
								}
							>
								<IconButton.Icon>
									{props.showOpModeBg === true ? MdVisibility : MdVisibilityOff}
								</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.showRawData !== undefined && props.setShowRawData !== undefined ? (
						<Tooltip
							collisionBoundary={props.cardRef}
							customContent={t(
								props.showRawData === true
									? "SHOW INTERPOLATED DATA"
									: "SHOW RAW DATA"
							)}
						>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.setShowRawData !== undefined &&
									props.setShowRawData((prev) =>
										prev !== undefined ? !prev : undefined
									)
								}
							>
								<IconButton.Icon>
									{props.showRawData === true ? MdRawOn : MdRawOff}
								</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.isDataDecimated === true ? (
						<Tooltip
							collisionBoundary={props.cardRef}
							customContent={t(
								props.showDataDecimated === true
									? "REMOVE DATA DOWNSAMPLING"
									: "DOWNSAMPLE DATA"
							)}
						>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.setShowDataDecimeted !== undefined &&
									props.setShowDataDecimeted((prev) =>
										prev !== undefined ? !prev : undefined
									)
								}
							>
								<IconButton.Icon>
									{props.showDataDecimated === true
										? MdStackedLineChart
										: MdSsidChart}
								</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.buttonsSetup.includes("delete") && props.handleDelete !== undefined ? (
						<Tooltip collisionBoundary={props.cardRef} customContent={t("DELETE PLOT")}>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.handleDelete !== undefined && props.handleDelete()
								}
							>
								<IconButton.Icon>{MdDelete}</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.buttonsSetup.includes("quickAdd") &&
					props.handleQuickAdd !== undefined ? (
						<Tooltip
							collisionBoundary={props.cardRef}
							customContent={t("QUICK ADD/REMOVE SENSOR")}
						>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.handleQuickAdd !== undefined && props.handleQuickAdd()
								}
							>
								<IconButton.Icon>{MdAddchart}</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.buttonsSetup.includes("settings") &&
					props.handleConfigOpen !== undefined ? (
						<Tooltip
							collisionBoundary={props.cardRef}
							customContent={t("PLOT SETTINGS")}
						>
							<IconButton
								disabled={props.isDataLoading === true ? true : false}
								variant={"text-dark"}
								className={"h-7 w-7"}
								onClick={() =>
									props.handleConfigOpen !== undefined && props.handleConfigOpen()
								}
							>
								<IconButton.Icon>{MdSettings}</IconButton.Icon>
							</IconButton>
						</Tooltip>
					) : null}
					{props.buttonsSetup.includes("expand") ||
					props.buttonsSetup.includes("close") ? (
						<IconButton
							disabled={true}
							variant={"text-dark"}
							className={"h-7 w-7"}
							onClick={() =>
								props.handleExpandOrClose !== undefined &&
								props.handleExpandOrClose()
							}
						>
							<IconButton.Icon>
								{props.buttonsSetup.includes("expand") ? MdZoomOutMap : MdZoomInMap}
							</IconButton.Icon>
						</IconButton>
					) : null}
				</div>
			</div>
		</div>
	)
}
