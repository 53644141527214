import { createShipIcon } from "@components/map/mapComponents/shipIcon"
import { updateSidePanelState } from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { setSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { common } from "@utils/common"
import { LatLng } from "leaflet"
import { DataSetter } from "../utils/mapLayers"

export const addVessels: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { vesselsData } = layerData
	if (vesselsData === undefined) return

	const { company, vesselInfo } = vesselsData
	if (vesselInfo.length === 0) return

	layerGroup.clearLayers()

	for (const ship of vesselInfo) {
		if (ship?.position?.lat === undefined || ship?.position?.lon === undefined) {
			continue
		}
		if (isNaN(ship.position.lat) === true || isNaN(ship.position.lon) == true) {
			continue
		}
		const shipCoord: LatLng = L.latLng(ship.position.lat, ship.position.lon)

		const plantData = common.plantDataFromOwnerAndId.get(
			`${company.toLowerCase()}/${ship.plantId}`
		)

		layerGroup.addLayer(
			L.marker(shipCoord, {
				icon: createShipIcon({
					heading: ship.position.heading,
					latestOperationMode: ship.latestOperationMode,
				}),
				pane: "vesselsPane",
			})
				.bindTooltip(plantData?.display_name ?? "unknown vessel", { permanent: true })
				.on("click", () => {
					if (ship !== null) {
						dispatch(setSelectedVessel(ship))
						dispatch(
							updateSidePanelState({
								isTableExpanded: false,
								isOpen: true,
								selectedTab: "1",
							})
						)
						if (navigate !== undefined) {
							navigate({
								to: `.`,
								search: (prev) => ({ ...prev, vessel: ship.name }),
							})
						}
					}
				})
		)
	}
}
