export const COLORS_PLOTS_LIGHT_V2 = [
	"#4d82aa",
	"#4d53aa",
	"#754daa",
	"#a44daa",
	"#aa4d82",
	"#aa4d53",
	"#aa754d",
	"#aaa44d",
	"#82aa4d",
	"#53aa4d",
	"#4daa75",
	"#4daaa4",
]
export const COLORS_PLOTS_LIGHT = [
	"#4d82aa",
	"#a44daa",
	"#aa754d",
	"#53aa4d",
	"#4d53aa",
	"#aa4d82",
	"#aaa44d",
	"#4daa75",
	"#754daa",
	"#aa4d53",
	"#82aa4d",
	"#4daaa4",
]
// we can check the different palettes and how they change for each type of colorblindness
// at https://projects.susielu.com/viz-palette
export const COLORS_PLOTS_COLORBLIND = [
	"#332288",
	"#CC6677",
	"#117733",
	"#AA4499",
	"#44AA99",
	"#88CCEE",
	"#882255",
	"#DDCC77",
]
export const COLORS_PLOTS_COLORBLIND_2 = [
	"#193CBC",
	"#1473AF",
	"#589ACF",
	"#89C3EF",
	"#EA594E",
	"#FA8775",
	"#FFB14E",
	"#FFD700",
	"#FFE900",
]

export const COLORS_PLOTS_COLORBLIND_3 = [
	"#0072b2",
	"#cc79a7",
	"#d55e00",
	"#009e73",
	"#e69f00",
	"#56b4e9",
	"#f0e442",
]
export const COLORS_PLOTS_COLORBLIND_4 = [
	"#0000ff",
	"#9d02d7",
	"#cd34b5",
	"#ea5f94",
	"#fa8775",
	"#ffb14e",
	"#ffd700",
	"#FFE900",
]

export const COLORS_VESSEL_COMPARISON = ["#193CBC", "#589ACF", "#EA594E", "#009e73"]

export function isColorBright(hex: string): boolean {
	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, "")

	// Parse the r, g, b values
	const r = parseInt(hex.substring(0, 2), 16)
	const g = parseInt(hex.substring(2, 4), 16)
	const b = parseInt(hex.substring(4, 6), 16)

	// Calculate luminance
	const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

	// A luminance above 128 is considered light, otherwise dark
	return luminance > 180
}

export function adjustHexColor(hex: string, factor: number, lighten: boolean = false): string {
	// Ensure the factor is within the range 0 to 1
	if (factor < 0 || factor > 1) {
		throw new Error("Factor must be between 0 and 1")
	}

	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, "")

	// Parse the r, g, b values
	let r = parseInt(hex.substring(0, 2), 16)
	let g = parseInt(hex.substring(2, 4), 16)
	let b = parseInt(hex.substring(4, 6), 16)

	// If lighten is true, lighten the color, else darken it
	if (lighten) {
		// Lighten: increase RGB values towards 255
		r = Math.max(0, Math.min(255, Math.floor(r + (255 - r) * factor)))
		g = Math.max(0, Math.min(255, Math.floor(g + (255 - g) * factor)))
		b = Math.max(0, Math.min(255, Math.floor(b + (255 - b) * factor)))
	} else {
		// Darken: decrease RGB values towards 0
		r = Math.max(0, Math.min(255, Math.floor(r * (1 - factor))))
		g = Math.max(0, Math.min(255, Math.floor(g * (1 - factor))))
		b = Math.max(0, Math.min(255, Math.floor(b * (1 - factor))))
	}

	// Convert back to hex and return the result
	const adjustedHex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
		.toString(16)
		.padStart(2, "0")}`
	return adjustedHex
}
