import * as Ttip from "@radix-ui/react-tooltip"
import { cn } from "@ui-lib/src/lib"
import { VariantProps, cva } from "class-variance-authority"
import { ReactNode, RefAttributes, useState } from "react"
import "./style.css"

const tooltipVariants = cva(
	"z-1001 h-auto w-auto rounded-sm bg-dipai-secondary-800/100 px-2 py-1 text-small text-white"
)

interface ITooltipProps
	extends Omit<Ttip.TooltipContentProps, "children">,
		VariantProps<typeof tooltipVariants>,
		RefAttributes<HTMLDivElement> {
	children: ReactNode
	customContent: ReactNode
	arrowClassName?: string
	delayDuration?: number
	open?: boolean
	defaultOpen?: boolean
	onOpenChange?: (open: boolean) => void
} //RefAttributes<HTMLDivElement>

export function Tooltip({
	children,
	customContent,
	arrowClassName,
	delayDuration = 0,
	open,
	defaultOpen,
	onOpenChange,
	...props
}: Omit<ITooltipProps, "$$typeof">) {
	const { className, onClick, side, sideOffset, ...rest } = props
	const [openTooltip, setOpenTooltip] = useState<boolean>(false)
	// let hovering = false
	const [clickOutsidePending, setClickOutsidePending] = useState(false)

	return (
		<Ttip.Provider>
			<Ttip.Root
				open={openTooltip}
				defaultOpen={defaultOpen}
				onOpenChange={onOpenChange}
				delayDuration={delayDuration}
			>
				<Ttip.Trigger
					onClick={(e) => {
						if (!clickOutsidePending) {
							setOpenTooltip(!openTooltip)
						}
						setClickOutsidePending(false)
					}}
					asChild
					onMouseEnter={() => {
						// hovering = true
						setTimeout(() => {
							// if (hovering) {
							setOpenTooltip(true)
							// }
						}, 200)
					}}
					onMouseLeave={() => {
						// hovering = false
						setTimeout(() => {
							setOpenTooltip(false)
						}, 200)
					}}
				>
					{children}
				</Ttip.Trigger>
				<Ttip.Content
					className={cn(
						tooltipVariants({
							className,
						})
					)}
					onPointerDownOutside={(e) => {
						setOpenTooltip(false)
						setClickOutsidePending(true)
					}}
					onEscapeKeyDown={() => setOpenTooltip(false)}
					collisionPadding={4}
					side={side ?? "bottom"}
					sideOffset={sideOffset ?? 4}
					{...rest}
				>
					{customContent}
					<Ttip.Arrow
						className={`${
							arrowClassName !== undefined
								? arrowClassName
								: "fill-dipai-secondary-800/100"
						}`}
					/>
				</Ttip.Content>
			</Ttip.Root>
		</Ttip.Provider>
	)
}
