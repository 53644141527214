import { VariantProps, cva } from "class-variance-authority"
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { cn } from "../../lib/utils"
import { ButtonIcon } from "./ButtonIcon"

const iconButtonVariants = cva(
	"inline-flex items-center justify-center text-button font-medium transition-colors focus:outline-hidden focus:ring-0 focus:ring-slate-400 focus:ring-offset-1 disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-slate-100",
	{
		variants: {
			company: {
				dipai: "",
				soelvtrans: "",
			},
			variant: {
				"filled-primary": "",
				"filled-secondary": "",
				"outline-light": "",
				"outline-dark": "",
				"text-light": "",
				"text-dark": "",
				destructive: "bg-red-500 text-white hover:bg-red-600",
			},
			size: {
				sm: "h-6 w-6",
				medium: "h-8 w-8",
				lg: "h-10 w-10",
			},
			shape: {
				squared: "rounded-sm",
				rounded: "rounded-full",
			},
		},
		compoundVariants: [
			{
				variant: "filled-primary",
				company: "dipai",
				className:
					"bg-dipai-primary-501 text-dipai-secondary-700 hover:text-dipai-secondary-901",
			},
			{
				variant: "filled-secondary",
				company: "dipai",
				className: "bg-dipai-secondary-800 text-white hover:bg-dipai-secondary-901",
			},
			{
				variant: "outline-light",
				company: "dipai",
				className:
					"bg-transparent text-white hover:text-dipai-primary-501 border-1 border-white hover:border-dipai-primary-501 hover:bg-white/20",
			},
			{
				variant: "outline-dark",
				company: "dipai",
				className:
					"bg-transparent text-dipai-secondary-800 hover:text-dipai-secondary-901 hover:bg-dipai-secondary-100 border-1 border-dipai-secondary-800 hover:border-dipai-secondary-901",
			},
			{
				variant: "text-light",
				company: "dipai",
				className:
					"bg-transparent text-white hover:text-dipai-primary-501 hover:bg-white/20",
			},
			{
				variant: "text-dark",
				company: "dipai",
				className:
					"bg-transparent text-dipai-secondary-800 hover:text-dipai-secondary-901 hover:bg-dipai-secondary-100",
			},
		],
		defaultVariants: {
			company: "dipai",
			shape: "squared",
			variant: "filled-primary",
			size: "medium",
		},
	}
)

interface IIconButtonProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		VariantProps<typeof iconButtonVariants> {
	blockSubmit?: boolean
}

const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>((props, ref) => {
	const { className, children, variant, size, shape, company, blockSubmit, ...rest } = props
	return (
		<button
			type={blockSubmit === false ? "submit" : "button"}
			className={cn(
				iconButtonVariants({
					variant,
					size,
					shape,
					company,
					className,
				})
			)}
			ref={ref}
			{...rest}
		>
			{children}
		</button>
	)
})

const IconButtonNamespace = Object.assign(IconButton, {
	Icon: ButtonIcon,
})
export { IconButtonNamespace as IconButton, iconButtonVariants }
