import { ReactNode, useMemo } from "react"
import BodyLayout from "./BodyLayout"
import HeaderLayout from "./HeaderLayout"
import TopBar from "./TopBar"

type PageProps = {
	children: ReactNode
	bodyWidth?: number | string
	headerHeight?: number
	headerWidth?: number
}

type databaseUrls = "database" | "devices" | "map" | "clients" | "maintenance"
export type LinkData = {
	linkLabel: string
	linkUrl: databaseUrls
}
export default function Page({
	children,
	bodyWidth = 940,
	headerHeight = 52,
	headerWidth,
}: PageProps) {
	const linksVar: LinkData[] = useMemo(() => {
		return [
			{ linkLabel: "MAP", linkUrl: "map" },
			{ linkLabel: "CLIENTS", linkUrl: "clients" },
			{ linkLabel: "DATABASE", linkUrl: "database" },
			{ linkLabel: "DEVICES", linkUrl: "devices" },
			{ linkLabel: "MAINTENANCE", linkUrl: "maintenance" },
		]
	}, [])

	return (
		<>
			<HeaderLayout height={headerHeight} bodyWidth={headerWidth ?? bodyWidth}>
				<TopBar links={linksVar} />
			</HeaderLayout>
			<div className={`h-[calc(100vh-52px)] overflow-auto`}>
				<BodyLayout headerHeight={headerHeight} width={"calc(100% - 3rem)"}>
					{children}
				</BodyLayout>
			</div>
		</>
	)
}
