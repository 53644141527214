import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import useGetFishFarmDataForMap from "@components/map/dataGettterHooks/useGetFishFarmDataForMap"
import useGetOilGasDataForMap from "@components/map/dataGettterHooks/useGetOilGasDataForMap"
import useGetSelectedShipTrack from "@components/map/dataGettterHooks/useGetSelectedShipTrack"
import useGetShipDataForMap from "@components/map/dataGettterHooks/useGetShipDataForMap"
import { LayerData } from "@components/map/utils/mapLayers"
import { PointFeature } from "@components/map/utils/utils"
import { RequestStatus } from "@redux/app/RequestStatus"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import {
	fetchDiseaseZones,
	getDiseaseZones,
	getDiseaseZonesStatus,
	getSelectedDiseaseZones,
} from "@redux/features/diseaseZones/diseaseZonesSlice"
import { getFacilities, setFacilities } from "@redux/features/facilities/facilitiesSlice"
import {
	fetchFishFarmOwners,
	getFishFarmOwnersStatus,
} from "@redux/features/fishFarmOwners/fishFarmOwnersSlice"
import { getMapFiltersFacilities } from "@redux/features/mapFiltersFacilities/mapFiltersFacilitiesSlice"
import { getMapParamsState } from "@redux/features/mapParams/mapParamsSlice"
import { getTimelineEventMarker } from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { useSearch } from "@tanstack/react-router"
import {
	AppStructure,
	DipaiModule,
	FacilityType,
	GetAllFacilitiesArgs,
	PlantInfo,
	PortalFeature,
} from "api"
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import BarLoader from "react-spinners/BarLoader"
import { useAsyncDebounce } from "react-table"
import { useGetFacilitiesRadiusQuery } from "../dataGettterHooks/useGetFacilitiesRadiusQuery"
import { MapLayers } from "../utils/mapLayers"
import { MapComponentBoundsV2 } from "./MapComponent"

const overrideLoaderCss = {
	display: "block",
	margin: "0 auto",
}

type MapContainerArgs = {
	props: AppStructure
	clientId: string
	specificPlant?: string | undefined
	showGlobalFilter?: boolean
	disablePopup?: boolean
}

export default function MapContainer({
	props,
	clientId,
	specificPlant,
	showGlobalFilter = false,
	disablePopup = false,
}: MapContainerArgs): ReactElement {
	const customerName = Object.keys(props)[0]
	if (customerName === undefined) {
		return <div className={``}>{"Unknown customer"}</div>
	}
	return (
		<ComponentBody
			props={props}
			clientId={clientId}
			customerName={customerName}
			specificPlant={specificPlant}
			showGlobalFilter={showGlobalFilter}
			disablePopup={disablePopup}
		/>
	)
}

function ComponentBody({
	props,
	customerName,
	clientId,
	specificPlant,
	showGlobalFilter = true,
	disablePopup = false,
}: MapContainerArgs & { customerName: string }): ReactElement {
	const devFeatures = useGetDevFeatures()

	const appStructure = useAppSelector(getAppStructure)

	const customerData = props?.[customerName]
	const dispatch = useAppDispatch()
	const userData = useAppSelector(getUserData)

	const mapFilterFacilities = useAppSelector(getMapFiltersFacilities).facilities
	const fishFarmOwnersStatus = useAppSelector(getFishFarmOwnersStatus)
	const diseaseZonesStatus = useAppSelector(getDiseaseZonesStatus)
	const facilitiesData = useAppSelector(getFacilities)

	useEffect(() => {
		if (
			isFeatureAvailable(
				undefined,
				PortalFeature.FISH_FARM,
				customerName,
				devFeatures,
				userData.portalMode
			) === false
		) {
			return
		}
		if (fishFarmOwnersStatus !== RequestStatus.idle) {
			return
		}
		dispatch(fetchFishFarmOwners())
	}, [fishFarmOwnersStatus, dispatch])

	useEffect(() => {
		if (
			isFeatureAvailable(
				undefined,
				PortalFeature.FISH_FARM,
				customerName,
				devFeatures,
				userData.portalMode
			) === false
		) {
			return
		}
		if (diseaseZonesStatus !== RequestStatus.idle) {
			return
		}
		dispatch(fetchDiseaseZones())
	}, [diseaseZonesStatus, dispatch])

	const [searchString, setSearchString] = useState<string | null>("")
	const [shipData, isPositionDataLoading, isLiveOpModeDataLoading] = useGetShipDataForMap(
		props,
		clientId,
		specificPlant,
		false
	)

	const {
		isLoading: isFacilitiesWithRadiusDataLoading,
		isError: isFacilitiesWithRadiusDataError,
		data: facilitiesWithRadiusData,
	} = useGetFacilitiesRadiusQuery({
		isFacilitiesFeatureAvailable: isFeatureAvailable(
			undefined,
			PortalFeature.FACILITIES,
			customerName,
			devFeatures,
			userData.portalMode
		),
		types: (
			[
				"FISH_FARM",
				"PORT",
				"PLATFORM",
				"DISCHARGE_TERMINAL",
				"DISCHARGE_STS",
				"ANCHORAGE",
			] as GetAllFacilitiesArgs["types"]
		)
			.filter((f) =>
				isFeatureAvailable(
					undefined,
					PortalFeature.FISH_FARM,
					customerName,
					devFeatures,
					userData.portalMode
				) === false
					? f !== "FISH_FARM"
					: true
			)
			.filter((f) =>
				isFeatureAvailable(
					undefined,
					PortalFeature.OIL_AND_GAS,
					customerName,
					devFeatures,
					userData.portalMode
				) === false
					? f !== "PLATFORM" &&
						f !== "DISCHARGE_TERMINAL" &&
						f !== "DISCHARGE_STS" &&
						f !== "ANCHORAGE"
					: true
			),
	})

	useEffect(() => {
		if (facilitiesWithRadiusData === null || facilitiesWithRadiusData === undefined) {
			return
		}
		const facilitiesTypeToInclude: FacilityType[] = []
		Object.entries(mapFilterFacilities).forEach(
			([key, value]) =>
				value === true &&
				(key.toLowerCase().includes("port") === true
					? facilitiesTypeToInclude.push("PORT")
					: key.toLowerCase().includes("fish") === true
						? facilitiesTypeToInclude.push("FISH_FARM")
						: key.toLowerCase().includes("platform") === true
							? facilitiesTypeToInclude.push("PLATFORM")
							: key.toLowerCase().includes("dischargeterminals") === true
								? facilitiesTypeToInclude.push("DISCHARGE_TERMINAL")
								: key.toLowerCase().includes("dischargests") === true
									? facilitiesTypeToInclude.push("DISCHARGE_STS")
									: key.toLowerCase().includes("anchorage") === true
										? facilitiesTypeToInclude.push("ANCHORAGE")
										: null)
		)
		const filteredFacilitiesWithRadiusData = facilitiesWithRadiusData.filter((f) =>
			facilitiesTypeToInclude.includes(f.type)
		)
		dispatch(setFacilities(filteredFacilitiesWithRadiusData))
	}, [facilitiesWithRadiusData, mapFilterFacilities])

	const [filteredShipData, setFilteredShipData] = useState<PlantInfo[]>(shipData)
	useEffect(() => {
		setFilteredShipData(
			shipData.filter((ship) =>
				ship.name.toLowerCase().includes(searchString?.toLowerCase() ?? "")
			)
		)
	}, [shipData])
	const { shipTrackData } = useGetSelectedShipTrack()

	const [
		textFilteredFishGeoJson,
		mapParametersFilteredFishGeoJson,
		isFishFarmDataLoading,
		handleSetTextFilteredFishGeoJson,
	] = useGetFishFarmDataForMap(searchString)

	const onSearchChange: (searchString: string) => void = useAsyncDebounce(
		useCallback((searchString) => {
			setFilteredShipData(
				shipData.filter((ship) =>
					ship.name.toLowerCase().includes(searchString.toLowerCase())
				)
			)
			handleSetTextFilteredFishGeoJson({
				type: mapParametersFilteredFishGeoJson.type,
				features: mapParametersFilteredFishGeoJson.features.filter(
					(feature: PointFeature) => {
						const properties = feature.properties
						if (properties === undefined) {
							return false
						}

						return (
							(properties?.siteName as string)
								?.toLowerCase()
								.includes(searchString.toLowerCase()) ||
							(properties?.municipalityName as string)
								?.toLowerCase()
								.includes(searchString.toLowerCase())
						)
					}
				),
			})
		}, []),
		500
	)
	const { zoom } = useAppSelector(getMapParamsState)

	const diseaseZonesData = useAppSelector(getDiseaseZones)
	const selectedDiseaseZones = useAppSelector(getSelectedDiseaseZones)
	const timelineEventMarker = useAppSelector(getTimelineEventMarker)

	const [oilAndGasfacilitiesData, fieldsData] = useGetOilGasDataForMap()
	const paramSelectedVessel = useSearch({ strict: false })?.vessel ?? ""

	const layersData: LayerData[] = useMemo(() => {
		return [
			{
				mapLayerName: MapLayers.FACILITIES_RADIUS,
				facilitiesRadius: {
					company: appStructure !== null ? (Object.keys(appStructure)[0] ?? "") : "",
					facilities:
						facilitiesData !== null && facilitiesData !== undefined
							? facilitiesData
							: [],
				},
				portalFeature: PortalFeature.FACILITIES,
			},
			{
				mapLayerName:
					customerData?.module === DipaiModule.OPERATION2 ||
					customerData?.module === DipaiModule.DIPAI
						? MapLayers.VESSELS_NL
						: MapLayers.VESSELS,
				vesselsNlData:
					customerData?.module === DipaiModule.OPERATION2 ||
					customerData?.module === DipaiModule.DIPAI
						? {
								company: customerName,
								vesselInfo: filteredShipData,
								selectedVessel: paramSelectedVessel,
								zoom: zoom?.zoomLevel,
							}
						: undefined,
				vesselsData:
					customerData?.module === DipaiModule.OPERATION2 ||
					customerData?.module === DipaiModule.DIPAI
						? undefined
						: { company: customerName, vesselInfo: filteredShipData },
			},
			{
				mapLayerName: MapLayers.VESSELS_TRACK,
				vesselsTrackData: { ...shipTrackData, zoom: zoom?.zoomLevel },
				portalFeature: PortalFeature.VESSELS_TRACK,
			},
			{
				mapLayerName: MapLayers.TIMELINE_EVENT_MARKER,
				timelineEventMarkerData: timelineEventMarker,
			},
			// {
			// 	mapLayerName: MapLayers.OIL_AND_GAS,
			// 	oilAndGasData: oilAndGasfacilitiesData,
			// 	portalFeature: PortalFeature.OIL_AND_GAS,
			// },
			{
				mapLayerName: MapLayers.OIL_AND_GAS_FIELDS,
				oilAndGasFieldsData: fieldsData,
				portalFeature: PortalFeature.OIL_AND_GAS,
			},
			{
				mapLayerName: MapLayers.FISH_FARMS,
				fishFarmsData: textFilteredFishGeoJson,
				portalFeature: PortalFeature.FISH_FARM,
			},
			{
				mapLayerName: MapLayers.DESEASE_ZONES,
				deseaseZonesData: { diseaseZonesData, selectedDiseaseZones },
				portalFeature: PortalFeature.FISH_FARM,
			},
		]
	}, [
		appStructure,
		customerData,
		customerName,
		diseaseZonesData,
		facilitiesData,
		fieldsData,
		filteredShipData,
		paramSelectedVessel,
		selectedDiseaseZones,
		shipTrackData,
		textFilteredFishGeoJson,
		timelineEventMarker,
		zoom,
	])

	const [inputForBoundCalc, setInputForBoundCalc] = useState<{
		shipData: PlantInfo[]
		trackData: PointFeature[] | undefined
	}>({
		shipData: filteredShipData,
		trackData:
			shipTrackData?.intervalPoints?.map((p) => {
				return {
					type: "Feature" as const,
					properties: {},
					geometry: {
						type: "Point" as const,
						coordinates: [p.longitude, p.latitude],
					},
				}
			}) ?? [],
	})
	useEffect(() => {
		setInputForBoundCalc({
			shipData: filteredShipData,
			trackData: shipTrackData?.intervalPoints?.map((p) => {
				return {
					type: "Feature" as const,
					properties: {},
					geometry: {
						type: "Point" as const,
						coordinates: [p.longitude, p.latitude],
					},
				}
			}),
		})
	}, [filteredShipData, shipTrackData])

	return (
		<>
			<div className="absolute top-0 left-0 z-2000 h-4 w-full">
				<BarLoader
					color={"#ffd600"}
					loading={isPositionDataLoading || isLiveOpModeDataLoading}
					cssOverride={overrideLoaderCss}
					width={"100%"}
				/>
			</div>
			<MapComponentBoundsV2
				customerName={customerName}
				layersData={layersData}
				setSearchString={setSearchString}
				searchString={searchString}
				onSearchChange={onSearchChange}
				inputsForBoundCalc={inputForBoundCalc}
				showGlobalFilter={showGlobalFilter}
				isLoading={isPositionDataLoading || isLiveOpModeDataLoading}
			/>
		</>
	)
}
