import { AllSensorsForAnAsset } from "@utilityTypes/timeseriesDefs"
import { AppStructure } from "api"

export const getSensorsByAsset = (
	appStructure: AppStructure,
	companyName: string,
	plantName: string,
	assetName?: string,
	sensorsGroupId?: number
): AllSensorsForAnAsset => {
	let sensors: AllSensorsForAnAsset = { sensors: [] }

	if (appStructure === null) {
		return sensors
	}
	if (Object.keys(appStructure).length === 0) {
		return sensors
	}
	const companyStructure = appStructure[companyName]
	if (companyStructure !== undefined) {
		const plants = companyStructure.plants
		for (const [plantNameRef, plantData] of Object.entries(plants)) {
			if (plantNameRef.toLowerCase() !== plantName.toLowerCase()) {
				continue
			}
			if (assetName !== undefined) {
				const assetData = plantData.assets.filter((c) => c.display_name === assetName)?.[0]
				if (assetData === undefined) {
					continue
				}
				if (sensorsGroupId !== undefined) {
					const sensorsGroup = assetData.sensorGroups.filter(
						(sg) => sg.id === sensorsGroupId
					)?.[0]
					if (sensorsGroup === undefined) {
						continue
					}
					sensorsGroup.sensorIds.map((sId) => {
						const sensorDataAdd = assetData.sensors.filter(
							(sensorData) => sensorData.id === sId
						)?.[0]
						if (sensorDataAdd !== undefined) {
							sensors.sensors = sensors.sensors.concat(sensorDataAdd)
						}
					})
				} else {
					sensors.sensors = assetData.sensors
				}
			} else {
				if (sensorsGroupId === undefined) {
					plantData.assets.map(
						(c) => (sensors.sensors = sensors.sensors.concat(c.sensors))
					)
				} else {
					const listOfSensorsForSensorGroup: number[] = []
					for (const asset of plantData.assets) {
						const sgs = asset.sensorGroups
						const sensorIds = sgs.find(
							(sensorGroup) => sensorGroup.id === sensorsGroupId
						)?.sensorIds
						if (sensorIds !== undefined) {
							listOfSensorsForSensorGroup.push(...sensorIds)
						}
					}
					plantData.assets.map(
						(c) =>
							(sensors.sensors = sensors.sensors.concat(
								c.sensors.filter((sensor) =>
									listOfSensorsForSensorGroup.includes(sensor.id)
								)
							))
					)
				}
			}
			return sensors
		}
	} else {
		for (const client of Object.values(appStructure)) {
			sensors = getSensorsByAsset(
				client.clients,
				companyName,
				plantName,
				assetName,
				sensorsGroupId
			)
			if (sensors.sensors.length > 0) {
				break
			}
		}
	}
	return sensors
}
