import { Dispatch, useReducer } from "react"
import { SensorThresholdFE } from "./SensorListLine"

export type ThresholdReducerState = SensorThresholdFE[]

export type ThresholdReducerAction = {
	type: ThresholdReducerActionTypes
	payload: SensorThresholdFE
}

export enum ThresholdReducerActionTypes {
	ADD_THRESHOLD = "add_threshold",
	REMOVE_THRESHOLD = "remove_threshold",
	UPDATE_THRESHOLD = "update_threshold",
}

const thresholdReducer = (
	state: ThresholdReducerState,
	action: ThresholdReducerAction
): ThresholdReducerState => {
	switch (action.type) {
		case ThresholdReducerActionTypes.REMOVE_THRESHOLD:
			return state.filter((threshold) => threshold.id !== action.payload.id)
		case ThresholdReducerActionTypes.UPDATE_THRESHOLD:
			return state.map((threshold) => {
				if (threshold.id === action.payload.id) {
					return action.payload
				}
				return threshold
			})
		case ThresholdReducerActionTypes.ADD_THRESHOLD:
			return [...state, action.payload]
		default:
			throw new Error("Invalid Action type!")
	}
}

export function useThresholdReducer(
	thresholdsList: SensorThresholdFE[]
): [ThresholdReducerState, Dispatch<ThresholdReducerAction>] {
	const [thresholdState, thresholdDispatch] = useReducer(thresholdReducer, thresholdsList)

	return [thresholdState, thresholdDispatch]
}
