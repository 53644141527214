import { ReactElement } from "react"
import { ZoneRangeId } from "./ZoneRangeUtils"

export const ValveStateSvgCollection = ({
	valveState,
	height = 38,
	width = 38,
}: {
	valveState: ZoneRangeId
	height?: number
	width?: number
}): ReactElement => {
	let path: ReactElement
	const noFluidColor = "#ffffff"
	const fluidColor = "#39e4ed"
	const valveClosedColor = "#fc270d"
	const valveOpenedColor = "#10e622"
	const pipeColor = "#444444"

	const fullPipe = `M${width / 2} 0 l0 ${height}`
	const R = width / 4
	const r = R * 0.66
	switch (valveState) {
		case ZoneRangeId.enter:
			path = (
				<g>
					<path
						d={`M${width / 2} 0 l0 ${height}`}
						stroke={pipeColor}
						strokeWidth={width / 4}
					/>
					<circle
						cx={`${width / 2}`}
						cy={`${height / 2}`}
						r={R}
						stroke={pipeColor}
						fill={pipeColor}
					/>
					<path d={`M${width / 2} 0 l0 ${height / 2}`} stroke={noFluidColor} />
					<path d={`M${width / 2} ${height / 2} l0  ${height / 2}`} stroke={fluidColor} />
					<circle
						cx={`${width / 2}`}
						cy={`${height / 2}`}
						r={r}
						stroke={valveOpenedColor}
						fill={valveOpenedColor}
					/>
					<path
						d={`M${width / 2} ${height / 2 - 1.5 * R} l0 ${3 * R}`}
						stroke={valveOpenedColor}
					/>
				</g>
			)
			break
		case ZoneRangeId.inside:
			path = (
				<g>
					<path d={fullPipe} stroke={pipeColor} strokeWidth={width / 4} />
					<path d={fullPipe} stroke={fluidColor} />
				</g>
			)
			break
		case ZoneRangeId.leave:
			path = (
				<g>
					<path
						d={`M${width / 2} 0 l0 ${height}`}
						stroke={pipeColor}
						strokeWidth={width / 4}
					/>
					<circle
						cx={`${width / 2}`}
						cy={`${height / 2}`}
						r={R}
						stroke={pipeColor}
						fill={pipeColor}
					/>
					<path d={`M${width / 2} 0 l0 ${height / 2}`} stroke={fluidColor} />
					<path
						d={`M${width / 2} ${height / 2} l0  ${height / 2}`}
						stroke={noFluidColor}
					/>
					<circle
						cx={`${width / 2}`}
						cy={`${height / 2}`}
						r={r}
						stroke={valveClosedColor}
						fill={valveClosedColor}
					/>
					<path
						d={`M${width / 2 - (3 * R) / 2} ${height / 2} l${3 * R} 0`}
						stroke={valveClosedColor}
					/>
				</g>
			)
			break
		case ZoneRangeId.none:
			path = (
				<g>
					<path d={fullPipe} stroke={pipeColor} strokeWidth={width / 4} />
					<path d={fullPipe} stroke={noFluidColor} />
				</g>
			)
			break
		default:
			path = (
				<g>
					<path d={fullPipe} stroke={pipeColor} strokeWidth="10" />
					<path d={fullPipe} stroke={noFluidColor} />
				</g>
			)
	}
	return (
		<svg
			style={{
				width: width,
				height: height,
			}}
			viewBox={`0 0 ${width} ${height}`}
			strokeWidth={width / 10}
		>
			{path}
		</svg>
	)
}
