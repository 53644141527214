import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import {
	downloadDataPageSearchSchema,
	validateDownloadDataSearchParams,
} from "@routes/clients.$clientName/_sopPageLayout/download-data"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/download-data")({
	component: RouteComponent,
	validateSearch: validateDownloadDataSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(downloadDataPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
