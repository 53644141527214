import DatabasePage, {
	DEFAULT_DATABASE_TAB_LABEL,
	normaliseTabLabel,
} from "@pages/DatabasePage/DatabasePage"
import { createFileRoute } from "@tanstack/react-router"

type DatabasePageSearch = {
	page?: string
}

export const Route = createFileRoute("/_adminPageLayout/database")({
	component: RouteComponent,
	validateSearch: (search: Record<string, unknown>): DatabasePageSearch => {
		//TODO: validate search params here
		return {
			page: (search.page as string) ?? normaliseTabLabel(DEFAULT_DATABASE_TAB_LABEL),
		}
	},
})

function RouteComponent() {
	return <DatabasePage />
}
