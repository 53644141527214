import { normaliseTabLabel } from "@pages/DatabasePage/DatabasePage"
import DevicesPage, { DEFAULT_DEVICE_TAB_LABEL } from "@pages/DevicesPage/DevicesPage"
import { createFileRoute } from "@tanstack/react-router"

type DevicesPageSearch = {
	page?: string
}

export const Route = createFileRoute("/_adminPageLayout/devices")({
	component: RouteComponent,
	validateSearch: (search: Record<string, unknown>): DevicesPageSearch => {
		//TODO: validate search params here
		return {
			page: (search.page as string) ?? normaliseTabLabel(DEFAULT_DEVICE_TAB_LABEL),
		}
	},
})

function RouteComponent() {
	return <DevicesPage />
}
