import FeatureGuard from "@components/featureGuard/FeatureGuard"
import { mapLayersColors } from "@components/map/utils/mapLayers"
import { prepOpModeName } from "@helpers/prepOpModeName"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { diseaseZoneType } from "@redux/features/diseaseZones/diseaseZonesSlice"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import {
	clearTimelineEventMarker,
	setTimelineEventMarker,
} from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"
import { OpModeState } from "@utilityTypes/opmodeDefs"
import { common } from "@utils/common"
import { BwVisitedLocalityResponse, OpModeEventData, PortalFeature, Track } from "api"
import { DateTime } from "luxon"
import { Fragment, ReactElement } from "react"
import { LocationType, TimeLineSvgCollection } from "./LocationUtils"
import { ValveStateSvgCollection } from "./ValveStateUtils"
import { ZoneRangeId, ZoneRangeSvgCollection } from "./ZoneRangeUtils"

enum TimelineEventType {
	location = "location",
	zone = "zone",
	mainOpMode = "main op mode event",
	opMode = "op mode event",
	valve = "valve state event",
}

type VisitedLocality = {
	name: string
	type: TimelineEventType
	state?: OpModeState
	isArriving: boolean
	isJuvenile: boolean
	isOnLand: boolean
	isSlaughterHoldingCage: boolean
	zones: {
		[zoneKey: string]: ZoneRangeId[]
	}
	coords?: { lat: number; lon: number }
	heading?: number | null
}

type VisitedLocalityOutput = {
	day: string
	hour: string
	locality: VisitedLocality[]
}

class VisitedLocalityCollection {
	readonly listOfFullIntervalZones: string[] = []

	addFullIntervalZone(zoneId: string) {
		this.listOfFullIntervalZones.push(zoneId)
	}

	readonly VisitedLocalitiesByTimestamp: Map<number, VisitedLocality[]> = new Map()

	addVisitedLocality(timestamp: number, v: VisitedLocality) {
		if (this.VisitedLocalitiesByTimestamp.has(timestamp) === false) {
			this.VisitedLocalitiesByTimestamp.set(timestamp, [v])
		} else {
			const tempArray = this.VisitedLocalitiesByTimestamp.get(timestamp)!
			tempArray.push(v)
			this.VisitedLocalitiesByTimestamp.set(timestamp, tempArray)
		}
	}

	getVisitedLocalityByTimestamp(timestamp: number) {
		return this.VisitedLocalitiesByTimestamp.get(timestamp)
	}

	getVisitedLocalitiesKeysChrono(): number[] {
		const response: number[] = []
		const sorted = [...this.VisitedLocalitiesByTimestamp.keys()].sort()
		for (const s of sorted) {
			response.push(s)
		}
		return response
	}

	getVisitedLocalitiesChrono() {
		const visitedLocalitiesChrono: VisitedLocalityOutput[] = []
		const sortedKeys: number[] = this.getVisitedLocalitiesKeysChrono()
		const zonesKeys: string[] = []
		for (const key of sortedKeys) {
			const visitedLocality = this.getVisitedLocalityByTimestamp(key)
			if (visitedLocality === undefined) {
				continue
			}
			const dateObject = DateTime.fromSeconds(key)
			visitedLocalitiesChrono.push({
				day: `${dateObject.day}/${dateObject.month}`,
				hour: `${dateObject.hour}:${dateObject.minute < 10 ? 0 : ""}${dateObject.minute}`,
				locality: visitedLocality,
			})
			for (const l of visitedLocality) {
				for (const zoneKey of Object.keys(l.zones)) {
					if (!zonesKeys.includes(zoneKey)) {
						zonesKeys.push(zoneKey)
					}
				}
			}
		}

		for (const zone of this.listOfFullIntervalZones) {
			if (!zonesKeys.includes(zone)) {
				zonesKeys.push(zone)
			}
		}

		for (const zoneKey of zonesKeys) {
			const array = Array(sortedKeys.length)
				.fill(undefined)
				.map(() =>
					Array(
						sortedKeys.length +
							(this.listOfFullIntervalZones.includes(zoneKey)
								? ZoneRangeId.inside
								: ZoneRangeId.none)
					).fill(ZoneRangeId.none)
				) as ZoneRangeId[][]

			for (let i = 0; i < sortedKeys.length; i++) {
				const localitiesRef = visitedLocalitiesChrono?.[i]?.locality
				if (localitiesRef === undefined) {
					continue
				}
				for (const l of localitiesRef) {
					const arrayI = array[i]
					if (arrayI === undefined) {
						continue
					}
					if (l.zones[zoneKey] !== undefined) {
						const zoneKey0 = l.zones[zoneKey]?.[0]
						const zoneKey1 = l.zones[zoneKey]?.[1]

						if (zoneKey0 !== undefined && zoneKey1 !== undefined) {
							if (zoneKey0 === ZoneRangeId.inside) {
								arrayI[i] = zoneKey1
								for (let f = 0; f < i; f++) {
									const arrayF = array[f]
									if (arrayF === undefined) {
										continue
									}
									arrayF[i] = zoneKey0
								}
							} else {
								arrayI[i] = zoneKey0
								for (let f = i + 1; f < sortedKeys.length; f++) {
									const arrayF = array[f]
									if (arrayF === undefined) {
										continue
									}
									arrayF[i] = zoneKey1
								}
							}
						} else {
							arrayI[i] = zoneKey0 ?? ZoneRangeId.none
						}
					} else {
						if (arrayI.length === 1) {
							arrayI[i] = ZoneRangeId.none
						}
					}
				}
			}

			if (this.listOfFullIntervalZones.includes(zoneKey)) {
				for (let f = 0; f < sortedKeys.length; f++) {
					const arrayF = array[f]
					if (arrayF === undefined) {
						continue
					}
					arrayF[sortedKeys.length] = ZoneRangeId.inside
				}
			}

			const reduceFunc = (previousValue: string, currentValue: string): ZoneRangeId => {
				const values = [previousValue, currentValue]
				if (values.includes(ZoneRangeId.leave)) {
					return ZoneRangeId.leave
				}

				if (values.includes(ZoneRangeId.enter)) {
					return ZoneRangeId.enter
				}

				if (values.includes(ZoneRangeId.inside)) {
					return ZoneRangeId.inside
				}

				return ZoneRangeId.none
			}

			const reducedArray = array.map((subArray) =>
				subArray.reduce(
					(previousValue, currentValue) => reduceFunc(previousValue, currentValue),
					ZoneRangeId.none
				)
			)

			for (let i = 0; i < reducedArray.length - 1; i++) {
				if (
					reducedArray[i] === ZoneRangeId.enter ||
					reducedArray[i] === ZoneRangeId.inside
				) {
					if (reducedArray[i + 1] === ZoneRangeId.none) {
						reducedArray[i + 1] = ZoneRangeId.inside
					}
				}
			}
			for (let i = reducedArray.length - 1; i > 0; i--) {
				if (
					reducedArray[i] === ZoneRangeId.leave ||
					reducedArray[i] === ZoneRangeId.inside
				) {
					if (reducedArray[i - 1] === ZoneRangeId.none) {
						reducedArray[i - 1] = ZoneRangeId.inside
					}
				}
			}

			for (let i = 0; i < sortedKeys.length; i++) {
				const visitedLocalityChronoRef = visitedLocalitiesChrono[i]
				if (visitedLocalityChronoRef === undefined) {
					continue
				}
				for (const l of visitedLocalityChronoRef.locality) {
					l.zones[zoneKey] = []
					const reducedArrayI = reducedArray[i]
					if (reducedArrayI !== undefined) {
						l.zones[zoneKey]?.push(reducedArrayI)
					}
				}
			}
		}

		return visitedLocalitiesChrono
	}
}

const prepareRes = (
	localitiesObj: BwVisitedLocalityResponse,
	opModeEvents: OpModeEventData,
	tracks: Track[]
) => {
	const visitedLocalities = new VisitedLocalityCollection()

	const localityWaypoints = localitiesObj.trackAnalysis.localityWaypoints
	const diseaseZoneVisits = localitiesObj.trackAnalysis.diseaseZoneVisits
	const openStateEvents = opModeEvents.openStateEvents
	const deviationEvents = opModeEvents.deviationEvents
	const mainEvents = opModeEvents.opModeEvents

	if (openStateEvents !== undefined) {
		for (const event of openStateEvents) {
			const timestampFrom = DateTime.fromISO(event.timestamp).toUnixInteger()
			if (timestampFrom === null) {
				continue
			}
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const intervalPoint of trackData.intervalPoints) {
					if (timestampFrom < DateTime.fromISO(intervalPoint.msgt).toUnixInteger()) {
						break loop1
					}
					refCoords = {
						lat: intervalPoint.lat,
						lon: intervalPoint.lon,
					}
					heading = intervalPoint.heading
				}
			}

			visitedLocalities.addVisitedLocality(timestampFrom, {
				type: TimelineEventType.valve,
				name: event.state,
				isArriving: true,
				isJuvenile: false,
				isOnLand: false,
				isSlaughterHoldingCage: false,
				zones: {
					[LocationType.valve]: [
						event.state === "open" ? ZoneRangeId.enter : ZoneRangeId.leave,
					],
				},
				coords: refCoords,
				heading: heading,
			})
		}
	}

	if (mainEvents !== undefined) {
		for (const event of mainEvents) {
			const timestampFrom = Math.floor(new Date(event.timestamp).getTime() / 1000)
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampFrom < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}

			visitedLocalities.addVisitedLocality(timestampFrom, {
				type: TimelineEventType.opMode,
				// state: event.mode.state,
				name: `${prepOpModeName(event)}`,
				isArriving: true,
				isJuvenile: false,
				isOnLand: false,
				isSlaughterHoldingCage: false,
				zones: {},
				coords: refCoords,
				heading: heading,
			})
		}
	}

	if (deviationEvents !== undefined) {
		for (const event of deviationEvents) {
			const timestampFrom = DateTime.fromISO(event.timestamp).toUnixInteger()
			if (timestampFrom === null) {
				continue
			}
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampFrom < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}

			visitedLocalities.addVisitedLocality(timestampFrom, {
				type: TimelineEventType.mainOpMode,
				state: (event.severity as unknown as OpModeState) ?? OpModeState.GREEN,
				name: `${event.subsystemName}: ${event.categoryName}`,
				isArriving: true,
				isJuvenile: false,
				isOnLand: false,
				isSlaughterHoldingCage: false,
				zones: {},
				coords: refCoords,
				heading: heading,
			})
		}
	}

	for (const localityWaypoint of localityWaypoints) {
		const timestampFrom =
			localityWaypoint.fromTime === null
				? null
				: DateTime.fromISO(localityWaypoint.fromTime).toUnixInteger()

		if (timestampFrom !== null) {
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampFrom < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}

			visitedLocalities.addVisitedLocality(timestampFrom, {
				type: TimelineEventType.location,
				name: localityWaypoint.name,
				isArriving: true,
				isJuvenile: localityWaypoint.isJuvenile,
				isOnLand: localityWaypoint.isOnLand,
				isSlaughterHoldingCage: localityWaypoint.isSlaughterHoldingCage,
				zones: {},
				coords: refCoords,
				heading: heading,
			})
		}

		const timestampTo =
			localityWaypoint.toTime === null
				? null
				: DateTime.fromISO(localityWaypoint.toTime).toUnixInteger()

		if (timestampTo !== null) {
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampTo < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}

			visitedLocalities.addVisitedLocality(timestampTo, {
				type: TimelineEventType.location,
				name: localityWaypoint.name,
				isArriving: false,
				isJuvenile: localityWaypoint.isJuvenile,
				isOnLand: localityWaypoint.isOnLand,
				isSlaughterHoldingCage: localityWaypoint.isSlaughterHoldingCage,
				zones: {},
				coords: refCoords,
				heading: heading,
			})
		}
	}

	for (const deseaseZoneVisit of diseaseZoneVisits) {
		const fromTimeStr = deseaseZoneVisit.fromTime
		const timestampFrom =
			fromTimeStr === null ? null : DateTime.fromISO(fromTimeStr).toUnixInteger()
		const toTimeStr = deseaseZoneVisit.toTime
		const timestampTo = toTimeStr === null ? null : DateTime.fromISO(toTimeStr).toUnixInteger()

		if (timestampFrom !== null) {
			const zoneTransit: ZoneRangeId[] = []
			zoneTransit.push(ZoneRangeId.enter)
			if (timestampTo === null) {
				zoneTransit.push(ZoneRangeId.inside)
			}
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampFrom < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}
			visitedLocalities.addVisitedLocality(timestampFrom, {
				type: TimelineEventType.zone,
				name: diseaseZoneType[deseaseZoneVisit.diseaseZoneType],
				isArriving: true,
				isJuvenile: false,
				isOnLand: false,
				isSlaughterHoldingCage: false,
				zones: {
					[deseaseZoneVisit.diseaseZoneType]: zoneTransit,
				},
				coords: refCoords,
				heading: heading,
			})
		}

		if (timestampTo !== null) {
			const zoneTransit: ZoneRangeId[] = []
			if (timestampFrom === null) {
				zoneTransit.push(ZoneRangeId.inside)
			}
			zoneTransit.push(ZoneRangeId.leave)
			let refCoords: { lat: number; lon: number } | undefined
			let heading: number | undefined | null
			loop1: for (const trackData of tracks) {
				loop2: for (const point of trackData.intervalPoints) {
					if (timestampTo < DateTime.fromISO(point.msgt).toUnixInteger()) {
						break loop1
					} else {
						refCoords = { lat: point.lat, lon: point.lon }
						heading = point.heading
					}
				}
			}
			visitedLocalities.addVisitedLocality(timestampTo, {
				type: TimelineEventType.zone,
				name: diseaseZoneType[deseaseZoneVisit.diseaseZoneType],
				isArriving: false,
				isJuvenile: false,
				isOnLand: false,
				isSlaughterHoldingCage: false,
				zones: {
					[deseaseZoneVisit.diseaseZoneType]: zoneTransit,
				},
				coords: refCoords,
				heading: heading,
			})
		}

		if (timestampFrom === null && timestampTo === null) {
			visitedLocalities.addFullIntervalZone(deseaseZoneVisit.diseaseZoneType)
		}
	}

	return visitedLocalities.getVisitedLocalitiesChrono()
}

const getTimeLinePerDay = (
	timeline: VisitedLocalityOutput[]
): { [dayKey: string]: VisitedLocalityOutput[] } => {
	const timeLinePerDay: { [dayRef: string]: VisitedLocalityOutput[] } = {}
	timeline.forEach((timelineItem) => {
		const timeLinePerDayRef = timeLinePerDay[timelineItem.day]
		if (timeLinePerDayRef !== undefined) {
			timeLinePerDayRef.push(timelineItem)
		} else {
			timeLinePerDay[timelineItem.day] = [timelineItem]
		}
	})
	return timeLinePerDay
}

export default function LocalityTimeline(): ReactElement {
	const selectedVessel = useAppSelector(getSelectedVessel)

	let timeLinePerDay: { [dayKey: string]: VisitedLocalityOutput[] } = {}
	if (
		selectedVessel.visitedLocations !== undefined &&
		selectedVessel.operationModeEvents !== undefined &&
		selectedVessel.track !== undefined
	) {
		const res = prepareRes(
			selectedVessel.visitedLocations,
			selectedVessel.operationModeEvents,
			selectedVessel.track.tracks
		)
		timeLinePerDay = getTimeLinePerDay(res)
	}
	const dispatch = useAppDispatch()

	const plantData = common.plantDataFromName.get(`${selectedVessel.name?.toLowerCase()}`)

	return (
		<>
			{/* VALVE STATE LABELS */}
			<div className="mb-3 flex w-full flex-wrap">
				<p className="text-gray-666 w-full"> {"Valve state labels"} </p>
				<div className="mb-1 ml-[-4px] flex h-5 w-[265px] flex-row items-center">
					<ValveStateSvgCollection
						height={20}
						width={20}
						valveState={ZoneRangeId.enter}
					/>

					<p className="text-small text-gray-666 pl-3">{"Open valves"}</p>
				</div>
				<div className="flex h-5 w-[265px] flex-row items-center">
					<ValveStateSvgCollection
						height={20}
						width={20}
						valveState={ZoneRangeId.inside}
					/>
					<p className="text-small text-gray-666 pl-3">{"Valves opned"}</p>
				</div>
				<div className="ml-[-4px] flex h-5 w-[265px] flex-row items-center">
					<ValveStateSvgCollection
						height={20}
						width={20}
						valveState={ZoneRangeId.leave}
					/>
					<p className="text-small text-gray-666 pl-3">{"Close valves"}</p>
				</div>
				<div className="flex h-5 w-[265px] flex-row items-center">
					<ValveStateSvgCollection
						height={20}
						width={20}
						valveState={ZoneRangeId.none}
					/>
					<p className="text-small text-gray-666 pl-3">{"Valves closed"}</p>
				</div>
			</div>
			<FeatureGuard
				featureName={PortalFeature.FISH_FARM}
				plant={plantData}
			>
				{/* DISEASE ZONE LABELS */}
				<div className="mb-6 flex w-full flex-wrap">
					<p className="text-gray-666 w-full"> {"Disease zone labels"} </p>
					{Object.keys(mapLayersColors.diseaseZones).map((key, index) => (
						<div
							key={index}
							className="flex h-5 w-[265px] flex-row items-center"
						>
							<div
								className="h-3 w-3"
								style={{
									backgroundColor:
										mapLayersColors.diseaseZones[
											key as keyof typeof mapLayersColors.diseaseZones
										],
								}}
							/>
							<p className="text-small text-gray-666 pl-4">
								{diseaseZoneType[key as keyof typeof mapLayersColors.diseaseZones]}
							</p>
						</div>
					))}
				</div>
			</FeatureGuard>

			{/* TIMELINE */}
			{Object.keys(timeLinePerDay).length > 0
				? Object.keys(timeLinePerDay).map((dayKey, index) => (
						<div
							key={index}
							className="border-gray-ccc grid w-full grid-cols-[50px_auto] border-b-1"
						>
							<div className="border-gray-ccc flex items-center justify-center border-r-1">
								<p className="text-button text-gray-666 font-semibold">{dayKey}</p>
							</div>
							<div className="flex flex-col">
								{timeLinePerDay[dayKey]?.map((timeLineEvent, index2) => {
									const timeLineEventLocality0 = timeLineEvent.locality[0]
									if (timeLineEventLocality0 === undefined) {
										return null
									}
									return (
										<div
											key={index2}
											className={`grid grid-cols-[45px_20px_calc(553px-223px-50px-24px)_38px_20px_20px_20px_20px_20px_20px] ${
												index2 < (timeLinePerDay[dayKey]?.length ?? 1) - 1
													? "border-b"
													: "border-b-0"
											} border-gray-ccc`}
											onMouseEnter={() =>
												timeLineEventLocality0.coords !== undefined &&
												dispatch(
													setTimelineEventMarker({
														coords: timeLineEventLocality0.coords,
														heading: timeLineEventLocality0.heading,
													})
												)
											}
											onMouseLeave={() =>
												dispatch(clearTimelineEventMarker())
											}
										>
											<div className="text-small text-gray-666 flex items-center justify-center">
												{timeLineEvent.hour}
											</div>
											{/* LOCATION ICONS */}
											<TimeLineSvgCollection
												locationType={
													timeLineEventLocality0.type ===
													TimelineEventType.zone
														? LocationType.zone
														: timeLineEventLocality0.type ===
															  TimelineEventType.location
															? timeLineEventLocality0.isArriving !==
																false
																? timeLineEventLocality0.isJuvenile ===
																	true
																	? LocationType.juvenile
																	: timeLineEventLocality0.isOnLand ===
																		  true
																		? LocationType.onLand
																		: timeLineEventLocality0.isSlaughterHoldingCage ===
																			  true
																			? LocationType.slaughterHoldingCage
																			: LocationType.location
																: LocationType.zone
															: LocationType.zone
												}
												height={
													40 + (timeLineEvent.locality.length - 1) * 24
												}
											/>
											<div className="flex flex-col space-y-1 py-2">
												{timeLineEvent.locality.map((l, index2) => (
													<Fragment key={index2}>
														{/* EVENT TEXT */}
														<div className="text-small text-gray-666 flex items-center justify-start overflow-hidden pl-2 whitespace-nowrap hover:z-1000 hover:w-full hover:overflow-visible">
															{l.type ===
																TimelineEventType.mainOpMode && (
																<div
																	className={`mr-2 h-2 w-2 rounded-full ${
																		l.state ===
																		OpModeState.GREEN
																			? "bg-alert-sucess"
																			: l.state ===
																				  OpModeState.YELLOW
																				? "bg-alert-warning"
																				: l.state ===
																					  OpModeState.RED
																					? "bg-alert-error"
																					: "bg-gray-666"
																	}`}
																/>
															)}
															<p className="hover:bg-white">
																{`${
																	l.type ===
																	TimelineEventType.zone
																		? l.isArriving === true
																			? "Enter"
																			: "Leave"
																		: l.type ===
																			  TimelineEventType.location
																			? l.isArriving === true
																				? "Arrival"
																				: "Departure"
																			: l.type ===
																				  TimelineEventType.opMode
																				? "Op. Mode: "
																				: l.type ===
																					  TimelineEventType.valve
																					? "Valves: "
																					: ""
																} ${l.name} ${
																	l.type ===
																	TimelineEventType.location
																		? l.isJuvenile === true
																			? "- Juvenile"
																			: l.isOnLand === true
																				? "- On land"
																				: l.isSlaughterHoldingCage ===
																					  true
																					? "- Slaughter holding cage"
																					: ""
																		: ""
																}`}
															</p>
														</div>
													</Fragment>
												))}
											</div>
											{/* VALVE ICONS */}
											<div className="">
												{timeLineEventLocality0.zones[
													LocationType.valve
												]?.[0] !== undefined && (
													<ValveStateSvgCollection
														valveState={
															timeLineEventLocality0.zones[
																LocationType.valve
															]?.[0]!
														}
														height={
															40 +
															(timeLineEvent.locality.length - 1) *
																24 -
															2
														}
													/>
												)}
											</div>
											{/* DISEASE ZONES ICONS */}
											{Object.keys(mapLayersColors.diseaseZones).map(
												(disease, index) => {
													const zoneData =
														timeLineEventLocality0.zones[disease]?.[0]
													if (zoneData === undefined) {
														return null
													}
													return (
														<div
															key={index}
															className="pt-[0.5px]"
															style={{
																color: mapLayersColors.diseaseZones[
																	disease as keyof typeof mapLayersColors.diseaseZones
																],
															}}
														>
															<ZoneRangeSvgCollection
																rangeId={zoneData}
																height={
																	40 +
																	(timeLineEvent.locality.length -
																		1) *
																		24
																}
															/>
														</div>
													)
												}
											)}
										</div>
									)
								})}
							</div>
						</div>
					))
				: "No timeline data to display"}
		</>
	)
}
