import { BodyContentLayout } from "./BodyContentLayout";
import { TableHeader } from "./TableHeader";
import { Table, TableProps } from "./Table";
import { ReactElement } from "react";
import { PaginationControls } from "./PaginationControls";
import { TableLoadingBar } from "./TableLoadingBar";
import { FilterSectionWithExpandButton } from "./FilterSectionWithExpandButton";

export function TableGeneral<T>(props: TableProps<T>): ReactElement {
    return (
        <Table
            {...props}
            BodyContentLayout={BodyContentLayout<T>}
            FilterSection={FilterSectionWithExpandButton}
            PaginationControls={PaginationControls}
            TableHeader={TableHeader}
            TableLoadingBar={TableLoadingBar}
        />
    );
}
