import { requestApi2 } from "@utils/http"
import { TableDbEditingWidget, TableObject, endpoints } from "api"
import { useEffect, useState } from "react"
import { DbConfigEditingWidget } from "./DbConfigEditingWidget"

export function PlantRawDataPage() {
	const [widget, setWidget] = useState<TableDbEditingWidget>()

	const loadWidget = async () => {
		const requestedWidget = await requestApi2(endpoints.plantRawDataGetWidgets)
		if (requestedWidget == null) {
			return
		}
		setWidget(requestedWidget)
	}

	useEffect(() => {
		loadWidget()
	}, [])

	const submit = async (objects: TableObject[]) => {
		const object0 = objects[0]
		if (object0 == undefined) {
			throw new Error(`Expected 1 table object`)
		}
		const rows = await requestApi2(endpoints.plantRawDataGetData, object0)
		if (rows == null) {
			throw new Error(`Expected data from server`)
		}
		let csvData = ""
		for (const row of rows) {
			for (const col of row) {
				csvData += (col as {}).toString() + ","
			}
			csvData += "\n"
		}
		const blob = new Blob([csvData], { type: "text/csv" })
		const link = document.createElement("a")
		link.download = "data.csv"
		link.href = window.URL.createObjectURL(blob)
		link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":")
		const evt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		})
		link.dispatchEvent(evt)
		link.remove()
	}

	return (
		<>
			{widget == undefined ? (
				"Loading..."
			) : (
				<DbConfigEditingWidget doSubmit={submit} widget={widget} />
			)}
		</>
	)
}
