import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { getListOfRangesEmission } from "@sop/EmissionPage/useGetRangesDatesEmission"
import { createFileRoute } from "@tanstack/react-router"
import { GroupedByType } from "api"
import { z } from "zod"

const EmissionModules = ["mrv", "bunker report", "cii"] as const
export type EmissionModulesList = (typeof EmissionModules)[number]

export type EmissionRange =
	| "custom"
	| "january"
	| "february"
	| "march"
	| "april"
	| "may"
	| "june"
	| "july"
	| "august"
	| "september"
	| "october"
	| "november"
	| "december"
	| "year"
	| undefined

const dateSchema = z.preprocess((val) => {
	if (typeof val === "string") {
		const parsedDate = new Date(val)
		if (!isNaN(parsedDate.getTime())) {
			return parsedDate.getTime()
		}
	} else if (typeof val === "number" && !isNaN(val)) {
		return val
	}
	return undefined
}, z.number().int().min(0).optional())

const MIN_YEAR_EMISSION = 2020
const minDateFrom = new Date(`${MIN_YEAR_EMISSION}-01-01`).getTime()

export const emissionPageSearchSchema = z
	.object({
		vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
		opMode: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of opModes
		tab: z.optional(z.enum(EmissionModules)).catch("cii"),
		groupBy: z.optional(z.nativeEnum(GroupedByType)).catch(undefined),
		toEmission: dateSchema,
		fromEmission: dateSchema,
		rangeEmission: z
			.optional(
				z.union(
					getListOfRangesEmission().map((role) => z.literal(role)) as [
						z.ZodLiteral<string>,
						Zod.ZodLiteral<string>,
						...Zod.ZodLiteral<string>[],
					]
				)
			)
			.catch("custom"), //z.optional(z.enum([...monthsList, "custom", "year"])).catch("custom"),
	})
	.superRefine((data, ctx) => {
		let hasErrors = false
		if (
			(data.fromEmission !== undefined && data.toEmission === undefined) ||
			(data.fromEmission === undefined && data.toEmission !== undefined)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Both 'fromEmission' and 'toEmission' must be provided together",
				path: ["fromEmission"],
			})
			hasErrors = true
		}

		if (
			data.fromEmission !== undefined &&
			data.toEmission !== undefined &&
			data.fromEmission >= data.toEmission
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromEmission' must be before 'toEmission'",
				path: ["fromEmission"],
			})
			hasErrors = true
		}

		if (data.fromEmission !== undefined && data.fromEmission < minDateFrom) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `'fromEmission' cannot be earlier than ${MIN_YEAR_EMISSION}-01-01`,
				path: ["fromEmission"],
			})
			hasErrors = true
		}

		if (hasErrors === true) {
			data.fromEmission = undefined
			data.toEmission = undefined
			data.rangeEmission = "year"
		} else if (data.fromEmission !== undefined && data.toEmission !== undefined) {
			data.rangeEmission = "custom"
		}
	})

export const validateEmissionSearchParams = (
	inputParams: Record<string, unknown>
): z.infer<typeof emissionPageSearchSchema> => {
	const result = emissionPageSearchSchema.safeParse(inputParams)

	if (result.success === false) {
		return {
			...inputParams,
			fromEmission: undefined,
			toEmission: undefined,
		}
	}

	return { ...inputParams, ...result.data }
}

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/emission")({
	component: RouteComponent,
	validateSearch: validateEmissionSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(emissionPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
