export const main_dimensions_vessel_data: { [vessel: string]: { LOA: number; Breadth: number } } = {
	// ALTERA
	"Altera Thule": {
		LOA: 278.996,
		Breadth: 49,
	},
	"Lambada Spirit": {
		LOA: 282,
		Breadth: 49,
	},
	"Norse Spirit": {
		LOA: 280,
		Breadth: 49,
	},
	"Scott Spirit": {
		LOA: 250.0,
		Breadth: 43.8,
	},
	"Sertanejo Spirit": {
		LOA: 282,
		Breadth: 49,
	},
	// DOF
	Geoholm: {
		LOA: 85.65,
		Breadth: 19.7,
	},
	"Skandi Acergy": {
		LOA: 156.9,
		Breadth: 27.0,
	},
	"Skandi Achiever": {
		LOA: 105.9,
		Breadth: 21.0,
	},
	"Skandi Acu": {
		LOA: 145.9,
		Breadth: 30.0,
	},
	"Skandi Africa": {
		LOA: 160.9,
		Breadth: 32.0,
	},
	"Skandi Amazonas": {
		LOA: 95.0,
		Breadth: 24.1,
	},
	"Skandi Angra": {
		LOA: 93.5,
		Breadth: 22.0,
	},
	"Skandi Atlantic": {
		LOA: 75.0,
		Breadth: 17.4,
	},
	"Skandi Aukra": {
		LOA: 87.9,
		Breadth: 19.0,
	},
	"Skandi Barra": {
		LOA: 86.65,
		Breadth: 19.7,
	},
	"Skandi Buzios": {
		LOA: 146.0,
		Breadth: 30.0,
	},
	"Skandi Caledonia": {
		LOA: 83.9,
		Breadth: 19.7,
	},
	"Skandi Carla": {
		LOA: 83.85,
		Breadth: 19.7,
	},
	"Skandi Chieftain": {
		LOA: 74.3,
		Breadth: 16.4,
	},
	"Skandi Commander": {
		LOA: 74.3,
		Breadth: 16.4,
	},
	"Skandi Darwin": {
		LOA: 93.8,
		Breadth: 20.0,
	},
	"Skandi Emerald": {
		LOA: 75.0,
		Breadth: 17.4,
	},
	"Skandi Feistein": {
		LOA: 87.9,
		Breadth: 19.0,
	},
	"Skandi Flora": {
		LOA: 94.9,
		Breadth: 20.0,
	},
	"Skandi Fluminense": {
		LOA: 80.5,
		Breadth: 18.0,
	},
	"Skandi Gamma": {
		LOA: 94.9,
		Breadth: 20.0,
	},
	"Skandi Hawk": {
		LOA: 88.1,
		Breadth: 19.0,
	},
	"Skandi Hera": {
		LOA: 93.8,
		Breadth: 23.0,
	},
	"Skandi Hercules": {
		LOA: 109.6,
		Breadth: 24.0,
	},
	"Skandi Hugen": {
		LOA: 82.2,
		Breadth: 17.0,
	},
	"Skandi Iceman": {
		LOA: 93.5,
		Breadth: 24.0,
	},
	"Skandi Iguacu": {
		LOA: 95.0,
		Breadth: 24.0,
	},
	"Skandi Ipanema": {
		LOA: 74.3,
		Breadth: 17.0,
	},
	"Skandi Jupiter": {
		LOA: 92,
		Breadth: 22,
	},
	"Skandi Kvitsøy": {
		LOA: 87.9,
		Breadth: 19.0,
	},
	"Skandi Marøy": {
		LOA: 82.2,
		Breadth: 17.0,
	},
	"Skandi Mercury": {
		LOA: 92,
		Breadth: 22,
	},
	"Skandi Mongstad": {
		LOA: 96.9,
		Breadth: 21.0,
	},
	"Skandi Niterói": {
		LOA: 142.2,
		Breadth: 27.0,
	},
	"Skandi Nova": {
		LOA: 82.2,
		Breadth: 17.0,
	},
	"Skandi Olinda": {
		LOA: 139.9,
		Breadth: 28.0,
	},
	"Skandi Olympia": {
		LOA: 79.6,
		Breadth: 16.4,
	},
	"Skandi Paraty": {
		LOA: 93.5,
		Breadth: 22.0,
	},
	"Skandi Patagonia": {
		LOA: 93.3,
		Breadth: 19.7,
	},
	"Skandi Recife": {
		LOA: 139.9,
		Breadth: 28.0,
	},
	"Skandi Salvador": {
		LOA: 105.9,
		Breadth: 21.0,
	},
	"Skandi Seven": {
		LOA: 120.7,
		Breadth: 23.0,
	},
	"Skandi Singapore": {
		LOA: 107.1,
		Breadth: 21.0,
	},
	"Skandi Skansen": {
		LOA: 107.2,
		Breadth: 24.0,
	},
	"Skandi Urca": {
		LOA: 93.5,
		Breadth: 22.0,
	},
	"Skandi Vega": {
		LOA: 109.5,
		Breadth: 24.0,
	},
	"Skandi Vinland": {
		LOA: 93.2,
		Breadth: 20.0,
	},
	// NCT OFFSHORE
	Freja: {
		LOA: 95,
		Breadth: 15,
	},
	// OCEAN INFINITY
	"Armada 78 01": {
		LOA: 78,
		Breadth: 15,
	},
	"Armada 78 02": {
		LOA: 78,
		Breadth: 15,
	},
	"Armada 78 03": {
		LOA: 78,
		Breadth: 15,
	},
	// REMØY
	"Rem Eir": {
		LOA: 92.5,
		Breadth: 20.0,
	},
	// SOLSTAD
	"Normand Drott": {
		LOA: 95,
		Breadth: 24,
	},
	"Normand Frontier": {
		LOA: 121,
		Breadth: 23,
	},
	"Normand Ocean": {
		LOA: 107.6,
		Breadth: 22,
	},
	"Normand Vision": {
		LOA: 157,
		Breadth: 27,
	},
	// SØLVTRANS
	"Ronja Aurora": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	"Ronja Azul": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	"Ronja Borøy": {
		LOA: 92.66,

		Breadth: 22.4,
	},
	"Ronja Challenger": {
		LOA: 69.86,

		Breadth: 12,
	},
	"Ronja Christopher": {
		LOA: 69.96,
		Breadth: 17.8,
	},
	"Ronja Evolution": {
		LOA: 76.96,
		Breadth: 5.9,
	},
	"Ronja Explorer": {
		LOA: 69.96,
		Breadth: 17.8,
	},
	"Ronja Harvester": {
		LOA: 68.0,

		Breadth: 14.0,
	},
	"Ronja Havsøy": {
		LOA: 84.46,
		Breadth: 16.0,
	},
	"Ronja Herøy": {
		LOA: 92.66,
		Breadth: 22.4,
	},
	"Ronja Mistral": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	"Ronja Princess": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	"Ronja Queen": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	"Ronja Silver": {
		LOA: 92.5,
		Breadth: 20.0,
	},
	"Ronja Star": {
		LOA: 87.1,
		Breadth: 18.0,
	},
	"Ronja Storm": {
		LOA: 116.0,
		Breadth: 3.0,
	},
	"Ronja Supporter": {
		LOA: 69.86,
		Breadth: 12.0,
	},
	"Ronja Vita": {
		LOA: 76.96,
		Breadth: 17.8,
	},
	Ronjafisk: {
		LOA: 69.96,
		Breadth: 17.8,
	},
	// VARD
	"REV Ocean": {
		LOA: 183,
		Breadth: 22,
	},
}

export const hard_coded_vessel_data = [
	// ALTERA
	{
		vessel: "Altera Thule",
		type: "148200 tons",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Lambada Spirit",
		type: "154233 tons",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Norse Spirit",
		type: "148200 tons",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Scott Spirit",
		type: "109300 tons",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Sertanejo Spirit",
		type: "154233 tons",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	// DOF
	{ vessel: "Geoholm", type: "MRV", operation: "--", customer: "", filterGroup: "UniSea" },
	{
		vessel: "Skandi Acergy",
		type: "CSV",
		operation: "--",
		customer: "DOF Subsea UK",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Achiever",
		type: "CSV",
		operation: "--",
		customer: "DOF Subsea Brazil",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Acu",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Africa",
		type: "CSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Amazonas",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Angra",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Atlantic",
		type: "AHTS",
		operation: "--",
		customer: "--",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Aukra",
		type: "PSV",
		operation: "--",
		customer: "Chrysaor",
		filterGroup: undefined,
	},
	{ vessel: "Skandi Barra", type: "PSV", operation: "--", customer: "", filterGroup: "UniSea" },
	{
		vessel: "Skandi Buzios",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Caledonia",
		type: "PSV",
		operation: "--",
		customer: "",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Carla",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Chieftain",
		type: "CSV",
		operation: "--",
		customer: "",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Commander",
		type: "MRV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Darwin",
		type: "CSV",
		operation: "--",
		customer: "Esso Australia",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Emerald",
		type: "AHTS",
		operation: "--",
		customer: "",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Feistein",
		type: "PSV",
		operation: "--",
		customer: "Esso Australia",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Flora",
		type: "PSV",
		operation: "--",
		customer: "Equinor",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Fluminense",
		type: "AHTS",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Gamma",
		type: "PSV",
		operation: "--",
		customer: "Ithaca Energy",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Hawk",
		type: "CSV",
		operation: "--",
		customer: "Prime Energy Resources Development B.V.",
		filterGroup: undefined,
	},
	{ vessel: "Skandi Hera", type: "AHTS", operation: "--", customer: "", filterGroup: "UniSea" },
	{
		vessel: "Skandi Hercules",
		type: "CSV",
		operation: "--",
		customer: "DOF Subsea APAC",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Hugen",
		type: "MRV",
		operation: "--",
		customer: "ConocoPhillips",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Iceman",
		type: "AHTS",
		operation: "--",
		customer: "Equinor",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Iguacu",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Ipanema",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Jupiter",
		type: "AHTS",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Kvitsøy",
		type: "PSV",
		operation: "--",
		customer: "Esso Australia",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Marøy",
		type: "MRV",
		operation: "--",
		customer: "ConocoPhillips",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Mercury",
		type: "AHTS",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Mongstad",
		type: "PSV",
		operation: "--",
		customer: "",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Niterói",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Nova",
		type: "MRV",
		operation: "--",
		customer: "ConocoPhillips",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Olinda",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{ vessel: "Skandi Olympia", type: "CSV", operation: "--", customer: "", filterGroup: "UniSea" },
	{
		vessel: "Skandi Paraty",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Patagonia",
		type: "CSV",
		operation: "--",
		customer: "",
		filterGroup: "UniSea",
	},
	{
		vessel: "Skandi Recife",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Salvador",
		type: "CSV",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Seven",
		type: "CSV",
		operation: "--",
		customer: "DOF Subsea Norway",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Singapore",
		type: "DSV",
		operation: "--",
		customer: "Chevron",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Skansen",
		type: "MRV",
		operation: "--",
		customer: "DOF Subsea Norway",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Urca",
		type: "AHTS",
		operation: "--",
		customer: "Petrobras",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Vega",
		type: "AHTS",
		operation: "--",
		customer: "Equinor",
		filterGroup: undefined,
	},
	{
		vessel: "Skandi Vinland",
		type: "MRV",
		operation: "--",
		customer: "Cenovus",
		filterGroup: undefined,
	},
	// NCT OFFSHORE
	{
		vessel: "Freja",
		type: "CLV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	// OCEAN INFINITY
	{
		vessel: "Armada 78 01",
		type: "MPSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Armada 78 02",
		type: "MPSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Armada 78 03",
		type: "MPSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	// REMØY
	{ vessel: "Rem Eir", type: "PSV", operation: "--", customer: "--", filterGroup: undefined },
	// SOLSTAD
	{
		vessel: "Normand Drott",
		type: "AHTS",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Normand Frontier",
		type: "CSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Normand Ocean",
		type: "CSV",
		operation: "--",
		customer: "DeepOcean AS",
		filterGroup: undefined,
	},
	{
		vessel: "Normand Vision",
		type: "CSV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	// SØLVTRANS
	{
		vessel: "Ronja Aurora",
		type: "3000 m3",
		operation: "--",
		customer: "Salmar",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Azul",
		type: "3000 m3",
		operation: "--",
		customer: "Mowi",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Borøy",
		type: "6000 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Challenger",
		type: "1800 m3",
		operation: "--",
		customer: "Mowi UK",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Christopher",
		type: "2500 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Evolution",
		type: "3000 m3",
		operation: "--",
		customer: "Mowi",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Explorer",
		type: "2500 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Harvester",
		type: "1900 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Havsøy",
		type: "3900 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Herøy",
		type: "6000 m3",
		operation: "--",
		customer: "Mowi",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Mistral",
		type: "3000 m3",
		operation: "--",
		customer: "Spot og Bremnes",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Princess",
		type: "3000 m3",
		operation: "--",
		customer: "Salmar",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Queen",
		type: "3000 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Silver",
		type: "5000 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Star",
		type: "4175 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Storm",
		type: "7450 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Supporter",
		type: "1800 m3",
		operation: "--",
		customer: "Scottish Seafarms",
		filterGroup: undefined,
	},
	{
		vessel: "Ronja Vita",
		type: "3000 m3",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
	{
		vessel: "Ronjafisk",
		type: "2500 m3",
		operation: "--",
		customer: "Bakkafrost",
		filterGroup: undefined,
	},
	// VARD
	{
		vessel: "REV Ocean",
		type: "REV",
		operation: "--",
		customer: "--",
		filterGroup: undefined,
	},
]
