import { flexRender, HeaderGroup } from "@tanstack/react-table";
import { ReactElement } from "react";
import { SortIcon, TableHeaderProps } from "../TableHeader";
import { twMerge } from "tailwind-merge";

export function TableHeaderMapPage<T>(
    props: TableHeaderProps<T>
): ReactElement {
    return (
        <>
            {props.headerGroups.map((headerGroup) => (
                <div
                    key={headerGroup.id}
                    className={twMerge(
                        `mb-3 grid select-none text-[10px] font-bold uppercase leading-[12px] tracking-[0.5px] ${props.columnsLayout}`,
                        props.className
                    )}
                >
                    {headerGroup.headers.map((header) => (
                        <div key={header.id}>
                            <div
                                className={`flex h-full flex-row items-center ${
                                    header.column.getCanSort() === true
                                        ? `cursor-pointer select-none`
                                        : ``
                                }`}
                                onClick={header.column.getToggleSortingHandler()}
                            >
                                <span className={`h-full w-auto py-1`}>
                                    {header.isPlaceholder === true
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </span>
                                <div className={`ml-[-5px]`}>
                                    {header.column.getIsSorted() === "desc" ? (
                                        <SortIcon type={"down"} />
                                    ) : header.column.getIsSorted() ===
                                      "asc" ? (
                                        <SortIcon type={"up"} />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}
