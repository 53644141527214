import { forwardRef, InputHTMLAttributes } from "react"
import { MdEvent } from "react-icons/md"
import "./datepicker.css"

type CustomCalendarInputProps = InputHTMLAttributes<HTMLInputElement> & {
	error?: boolean
	isCalendarOpen?: boolean
}

export const CustomDatePickerInput = forwardRef<HTMLInputElement, CustomCalendarInputProps>(
	({ width = 170, value, isCalendarOpen, ...props }, ref) => {
		return (
			<>
				<MdEvent className={`text-dipai-secondary-800 absolute top-2 ml-2`} />
				<input
					{...props}
					className={`${isCalendarOpen === false ? "" : "react-datepicker-ignore-onclickoutside"} border-gray-ccc/90 text-small h-8 rounded-sm border-1 pl-[33px] text-end ${
						props.disabled !== true
							? "hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 focus:border-2 focus:pr-[11px] focus:pl-8 focus:ring-0 focus:outline-hidden"
							: "bg-slate-100"
					}`}
					style={{ width: width }}
					ref={ref}
					type="text"
					value={value}
				/>
			</>
		)
	}
)
