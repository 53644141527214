import { useTranslation } from "react-i18next"
import { MdError } from "react-icons/md"

export default function ErrorMessagePage({ error }: { error: Error | null }) {
	const { t } = useTranslation()
	return (
		<div className={`h-screen bg-dipai-secondary-901 text-white`}>
			<div className="flex h-[60vh] w-full flex-row items-end justify-center">
				<div className={`flex flex-col items-center`}>
					<div className={`mb-5 rounded-full bg-dipai-primary-501`}>
						<MdError className={`h-24 w-24 fill-dipai-secondary-901`} />
					</div>
					<p className="mb-2 h-[49px] text-center text-title3 font-bold">
						{t !== undefined
							? t("AN ERROR HAPPENED IN THE APPLICATION")
							: "An error happened in the application"}
					</p>
					<p className="mb-8 text-center text-body">
						{t !== undefined
							? t("IF THE ISSUE PERSISTS, PLEASE CONTACT DIPAI SUPPORT")
							: "If the issue persists, please contact Dipai support."}
					</p>
				</div>
			</div>
		</div>
	)
}
