import { NotFoundPageNL } from "@pages/NotFoundPage/NotFoundPage"
import { createRootRoute, useLocation } from "@tanstack/react-router"
import App from "../App"

export const Route = createRootRoute({
	component: RouteComponent,
	notFoundComponent: NotFoundComponent,
})

function RouteComponent() {
	return (
		<>
			<App />
			{/* <TanStackRouterDevtools  /> */}
		</>
	)
}
function NotFoundComponent() {
	const currentLocation = useLocation().pathname
	return (
		<NotFoundPageNL
			linkTo={currentLocation.includes("/clients") ? `${currentLocation}/..` : "/"}
			labelTo="Go back"
		/>
	)
}
