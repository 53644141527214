import { GeoJSON, LayerGroup, Map as LeafletMap } from "leaflet"

export type LayerConstructor = (
	layerName: string,
	zIndex: number,
	map: LeafletMap
) => LayerGroup | GeoJSON | undefined

export const defaultLayerConstructor: LayerConstructor = (layerName, zIndex, map) => {
	if (map === undefined) {
		return
	}
	const layerPaneName = `${layerName}Pane`
	map.createPane(layerPaneName)
	map.getPane(layerPaneName)!.style.zIndex = `${zIndex}`
	const layerGroup = L.layerGroup([], {
		pane: layerPaneName,
	})
	layerGroup.addTo(map)
	return layerGroup
}
