import { useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { Link } from "@tanstack/react-router"
import { Button as ButtonLib } from "@ui-lib/index"
import { ReactElement } from "react"

const getComponents = (companyName: string | undefined): [ReactElement, string] => {
	const buttonsList: { [companyKey: string]: ReactElement } = {}
	const iconsList: { [companyKey: string]: string } = {}

	const fallbackButton: ReactElement = (
		<ButtonLib variant={"filled-primary"} className={`w-[135px]`}>
			<ButtonLib.Label>{"Go to Home"}</ButtonLib.Label>
		</ButtonLib>
	)
	const fallbackIcon = "/assets/img/icons/BrokenLinkIcon.svg"
	return [
		buttonsList[companyName !== undefined ? companyName : "no company"] ?? fallbackButton,
		iconsList[companyName !== undefined ? companyName : "no company"] ??
			"/assets/img/icons/BrokenLinkIcon.svg",
	]
}

const getCustomButtonByCompanyName = (
	companyName: string | undefined,
	label: string
): ReactElement => {
	const buttonsList: { [companyKey: string]: ReactElement } = {}
	const fallbackButton: ReactElement = (
		<ButtonLib variant={"filled-primary"} className={`w-[135px] px-2`}>
			<ButtonLib.Label>{label}</ButtonLib.Label>
		</ButtonLib>
	)
	return buttonsList[companyName !== undefined ? companyName : "no company"] ?? fallbackButton
}

type NotFoundPageColorsProp = {
	bg: string
}

const getCompanyColorsByCompanyName = (companyName: string | undefined): NotFoundPageColorsProp => {
	const componentColorsList: { [companyKey: string]: NotFoundPageColorsProp } = {
		Sølvtrans: { bg: "bg-gray-666" },
	}
	const componentColorsFallback: NotFoundPageColorsProp = {
		bg: "bg-dipai-secondary-901",
	}
	return (
		componentColorsList[companyName !== undefined ? companyName : "no company"] ??
		componentColorsFallback
	)
}

export default function NotFoundPage({
	linkTo,
	labelTo,
}: {
	linkTo?: string
	labelTo?: string
}): ReactElement {
	const companyName = useAppSelector(getCurrentCompany)
	return (
		<div className={`h-[100vh] ${getCompanyColorsByCompanyName(companyName).bg} text-white`}>
			<div className="grid h-24 grid-cols-2">
				<img className="ml-12 mt-9 h-7" src="/assets/img/logos/Dipai.png" alt="Dipai AS" />
				<div className="relative">
					<div className="absolute mt-12 right-12 text-tiny">
						Problems? Please contact <span className="underline">Dipai Support</span>.
					</div>
				</div>
			</div>

			<div className="m-auto mt-[calc(50vh-96px-260px)] flex w-[450px] flex-col items-center">
				<img className="mb-8 w-[100px]" src={getComponents(companyName)[1]} alt="" />
				<p className="mb-5 h-[49px] text-center text-title2">This Page Isn't Available</p>
				<p className="m-auto mb-8 text-center text-subheadline">
					{" "}
					The link may be broken, or the page may have been removed. Check to see if the
					link you're trying to open is correct.{" "}
				</p>
				<Link to={linkTo !== undefined ? linkTo : "/"}>
					{labelTo !== undefined
						? getCustomButtonByCompanyName(companyName, labelTo)
						: getComponents(companyName)[0]}
				</Link>
			</div>
		</div>
	)
}

export function NotFoundPageNL({
	linkTo,
	labelTo,
}: {
	linkTo?: string
	labelTo?: string
}): ReactElement {
	const companyName = useAppSelector(getCurrentCompany)
	return (
		<div className={`h-[100vh] ${getCompanyColorsByCompanyName(companyName).bg} text-white`}>
			<div className="flex h-[52px] flex-row items-center justify-end pr-5 text-button">
				<p>
					Problems? Please contact <span className="underline">Dipai Support</span>.
				</p>
			</div>

			<div className="m-auto mt-[calc((100vh-52px)/2-155px)] flex w-[450px] flex-col items-center">
				<img className="mb-8 w-[100px]" src={getComponents(companyName)[1]} alt="" />
				<p className="mb-5 h-[49px] text-center text-title2">This Page Isn't Available</p>
				<p className="m-auto mb-8 text-center text-subheadline">
					{" "}
					The link may be broken, or the page may have been removed. Check to see if the
					link you're trying to open is correct.{" "}
				</p>
				<Link to={linkTo !== undefined ? linkTo : "/"}>
					{labelTo !== undefined
						? getCustomButtonByCompanyName(companyName, labelTo)
						: getComponents(companyName)[0]}
				</Link>
			</div>
		</div>
	)
}
