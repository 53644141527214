import { Dispatch, ReactElement, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineZoomOutMap } from "react-icons/md"
import { IconButton } from ".."
import { GlobalFilter } from "./GlobalFilter"

export type FilterSectionProps = {
	globalFilter: string
	setGlobalFilter: Dispatch<SetStateAction<string>>
	showGlobalFilter?: boolean
	translationFunc: ReturnType<typeof useTranslation>["t"]
	onClick?: () => void
	company?: "dipai" | "soelvtrans"
}

export function FilterSectionWithExpandButton(props: FilterSectionProps): ReactElement {
	return (
		<div
			className={`flex items-center ${
				props.showGlobalFilter === true ? "justify-between" : "justify-end"
			} w-auto px-4 pb-1 text-headline text-gray-333`}
		>
			{props.showGlobalFilter === true ? (
				<GlobalFilter
					globalFilter={props.globalFilter}
					setGlobalFilter={props.setGlobalFilter}
					translationFunc={props.translationFunc}
				/>
			) : null}
			{props.onClick !== undefined ? (
				<IconButton
					variant={"filled-primary"}
					company={props.company === "soelvtrans" ? "soelvtrans" : "dipai"}
					size={"sm"}
					onClick={props.onClick}
				>
					<IconButton.Icon>{MdOutlineZoomOutMap}</IconButton.Icon>
				</IconButton>
			) : null}
		</div>
	)
}
