import FeatureGuard from "@components/featureGuard/FeatureGuard"
import { MapButtons } from "@components/map/mapComponents/MapButtons"
import useAddLayersDataToMap from "@components/map/utils/useAddLayersDataToMap"
import useAssignWindyMapDiv from "@components/map/windyMap/useAssignWindyMapDiv"
import useInitWindyMap from "@components/map/windyMap/useInitWindyMap"
import { MapOptions, WindyMapType } from "@components/map/windyMap/windyMap"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getMapFiltersPanelState,
	updateMapFiltersPanelState,
} from "@redux/features/mapFiltersPanelState/mapFiltersPanelStateSlice"
import { getPanelState } from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import MapFiltersFacilitiesContent from "@sop/MapPage/MapFiltersFacilitiesContent"
import MapFiltersFishContent from "@sop/MapPage/MapFiltersFishContent"
import MapFiltersOilContent from "@sop/MapPage/MapFiltersOilContent"
import { useNavigate } from "@tanstack/react-router"
import { IconButton, Tooltip } from "@ui-lib/index"
import { common } from "@utils/common"
import ErrorBoundary from "@utils/errorBoundary/ErrorBoundary"
import { DipaiModule, PlantInfo, PlantObject, PortalFeature } from "api"
import { Dispatch, memo, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClear, MdClose, MdSearch } from "react-icons/md"
import { useQueryClient } from "react-query"
import ErrorMessageMap from "../mapComponents/ErrorMessageMap"
import { LayerData } from "../utils/mapLayers"
import { useSetMapBounds, useSetMapBoundsV2, useSetMapBoundsV3 } from "../utils/useSetMapBounds"
import { PointCollection } from "../utils/utils"
import "./windyMap.css"

type MapData<T> = {
	layersData: LayerData[]
	onSearchChange?: (searchString: string) => void
	searchString?: string | null
	setSearchString?: Dispatch<SetStateAction<string | null>>
	inputsForBoundCalc?: T
	customerName: string
	showGlobalFilter?: boolean
	options?: MapOptions
	isLoading?: boolean
}

export function MapComponentBoundsV1(
	props: MapData<
		| {
				shipData: PlantInfo[]
				fishGeoJson: PointCollection
		  }
		| undefined
	>
) {
	return (
		<ErrorBoundary fallback={ErrorMessageMap}>
			<MapComponentBodyBounds1 {...props} />
		</ErrorBoundary>
	)
}
function MapComponentBodyBounds1({
	layersData,
	onSearchChange,
	searchString,
	setSearchString,
	inputsForBoundCalc,
	customerName,
	showGlobalFilter = true,
	options,
	isLoading,
}: MapData<
	| {
			shipData: PlantInfo[]
			fishGeoJson: PointCollection
	  }
	| undefined
>) {
	useAssignWindyMapDiv()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { isOpen, selectedTab } = useAppSelector(getPanelState)
	const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen)
	const [windyMap, setWindyMap] = useState<WindyMapType>()
	const companyData = common.companyDataFromCompanyName.get(customerName)

	const mapFiltersPanelState = useAppSelector(getMapFiltersPanelState)

	useInitWindyMap(
		dispatch,
		queryClient,
		setWindyMap,
		windyMap,
		companyData?.module === DipaiModule.OPERATION2 || companyData?.module === DipaiModule.DIPAI
			? navigate
			: undefined,
		options
	)

	useEffect(() => {
		const target = document.getElementById("windyHolder")
		// Create a new resize event
		if (target?.firstChild !== undefined && isPanelOpen !== isOpen) {
			windyMap?.resizemap()
			// var event = new Event("resize")
			// setTimeout(() => {
			// 	// Dispatch the event on the window object
			// 	window.dispatchEvent(event)
			// }, 500)
			setIsPanelOpen(isOpen)
		}
	}, [isOpen, windyMap])

	const selectedVessel = useAppSelector(getSelectedVessel)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)
	useAddLayersDataToMap(
		layersData,
		{ map: windyMap, options },
		undefined,
		customerName,
		plantData
	)
	useSetMapBounds(windyMap, inputsForBoundCalc)
	const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null)

	return (
		<>
			{/* WINDY MAP CONTAINER */}
			<div
				ref={(newRef) => setMapRef(newRef)}
				id="windyHolder"
				className={`z-0 h-full w-full`}
			/>
			{/* GLOBAL FILTER */}
			{showGlobalFilter === true &&
			onSearchChange !== undefined &&
			setSearchString !== undefined ? (
				<div
					id={"globalFilter"}
					className="absolute top-[14px] left-16 inline-block rounded-md border-2 border-[rgba(0,0,0,0.2)]"
				>
					<div className="absolute inset-x-1 inset-y-1 h-6 w-6">
						<MdSearch className={`h-6 w-6`} />
					</div>
					<input
						className="text-menu shadow-card hover:border-alert-focus focus:border-alert-focus h-8 rounded-sm bg-white px-8 focus:border-1 focus:outline-hidden"
						value={searchString ?? ""}
						onChange={(e) => {
							setSearchString(e.target.value)
							onSearchChange(e.target.value)
						}}
						placeholder={t("SEARCH VESSEL BY NAME")}
					/>
					{searchString !== "" && searchString !== undefined && (
						<div
							className="text-gray-666 absolute inset-y-1 right-1 h-6 w-6 cursor-pointer"
							onClick={() => {
								setSearchString("")
								onSearchChange("")
							}}
						>
							<MdClear className={`h-6 w-6`} />
						</div>
					)}
				</div>
			) : null}

			{/* MAP BUTTONS */}
			<MapButtons map={windyMap} />

			{/* FILTERS PANEL */}
			<FiltersPanelMemo
				dispatch={dispatch}
				isOpen={mapFiltersPanelState.isOpen}
				mapRef={mapRef}
				plantData={plantData}
			/>

			{/* MAPTILE ATTRIBUTION */}
			<a
				id="maptilerLink"
				href="https://www.maptiler.com"
				className="absolute bottom-4 left-4 z-999 hidden"
			>
				<img
					src="https://api.maptiler.com/resources/logo.svg"
					alt="MapTiler logo"
				/>
			</a>
		</>
	)
}

export const MapComponentBoundsV2 = memo(
	(
		props: MapData<
			| {
					shipData: PlantInfo[]
					trackData:
						| GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]
						| undefined
			  }
			| undefined
		>
	) => {
		return (
			<ErrorBoundary fallback={ErrorMessageMap}>
				<MapComponentBodyBounds2 {...props} />
			</ErrorBoundary>
		)
	}
)
function MapComponentBodyBounds2({
	layersData,
	onSearchChange,
	searchString,
	setSearchString,
	inputsForBoundCalc,
	customerName,
	showGlobalFilter = true,
	options,
	isLoading,
}: MapData<
	| {
			shipData: PlantInfo[]
			trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
	  }
	| undefined
>) {
	useAssignWindyMapDiv()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { isOpen, selectedTab } = useAppSelector(getPanelState)
	const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen)
	const [windyMap, setWindyMap] = useState<WindyMapType>()
	const companyData = common.companyDataFromCompanyName.get(customerName)

	const mapFiltersPanelState = useAppSelector(getMapFiltersPanelState)

	useInitWindyMap(
		dispatch,
		queryClient,
		setWindyMap,
		windyMap,
		companyData?.module === DipaiModule.OPERATION2 || companyData?.module === DipaiModule.DIPAI
			? navigate
			: undefined,
		options
	)

	useEffect(() => {
		const target = document.getElementById("windyHolder")
		// Create a new resize event
		if (target?.firstChild !== undefined && isPanelOpen !== isOpen) {
			windyMap?.resizemap()
			// var event = new Event("resize")
			// setTimeout(() => {
			// 	// Dispatch the event on the window object
			// 	window.dispatchEvent(event)
			// }, 500)
			setIsPanelOpen(isOpen)
		}
	}, [isOpen, windyMap])

	const selectedVessel = useAppSelector(getSelectedVessel)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)

	useAddLayersDataToMap(
		layersData,
		{ map: windyMap, options },
		undefined,
		customerName,
		plantData
	)
	useSetMapBoundsV2(windyMap, inputsForBoundCalc)
	const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null)

	return (
		<>
			{/* WINDY MAP CONTAINER */}
			<div
				ref={(newRef) => setMapRef(newRef)}
				id="windyHolder"
				className={`z-0 h-full w-full`}
			/>
			{/* GLOBAL FILTER */}
			{showGlobalFilter === true &&
			onSearchChange !== undefined &&
			setSearchString !== undefined ? (
				<div
					id={"globalFilter"}
					className="absolute top-[14px] left-16 z-100000 inline-block rounded-md border-2 border-[rgba(0,0,0,0.2)]"
				>
					<div className="absolute inset-x-1 inset-y-1 h-6 w-6">
						<MdSearch className={`h-6 w-6`} />
					</div>
					<input
						className="text-menu shadow-card hover:border-alert-focus focus:border-alert-focus h-8 rounded-sm bg-white px-8 focus:border-1 focus:outline-hidden"
						value={searchString ?? ""}
						onChange={(e) => {
							setSearchString(e.target.value)
							onSearchChange(e.target.value)
						}}
						placeholder={t("SEARCH VESSEL BY NAME")}
					/>
					{searchString !== "" && searchString !== undefined && (
						<div
							className="text-gray-666 absolute inset-y-1 right-1 h-6 w-6 cursor-pointer"
							onClick={() => {
								setSearchString("")
								onSearchChange("")
							}}
						>
							<MdClear className={`h-6 w-6`} />
						</div>
					)}
				</div>
			) : null}

			{/* MAP BUTTONS */}
			<MapButtons map={windyMap} />

			{/* FILTERS PANEL */}
			<FiltersPanelMemo
				dispatch={dispatch}
				isOpen={mapFiltersPanelState.isOpen}
				mapRef={mapRef}
				plantData={plantData}
			/>

			{/* MAPTILE ATTRIBUTION */}
			<a
				id="maptilerLink"
				href="https://www.maptiler.com"
				className="absolute bottom-4 left-4 z-999 hidden"
			>
				<img
					src="https://api.maptiler.com/resources/logo.svg"
					alt="MapTiler logo"
				/>
			</a>
		</>
	)
}

export function MapComponentBoundsV3(
	props: MapData<
		| {
				shipData: PlantInfo[]
				trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
		  }
		| undefined
	>
) {
	return (
		<ErrorBoundary fallback={ErrorMessageMap}>
			<MapComponentBodyBounds3 {...props} />
		</ErrorBoundary>
	)
}
function MapComponentBodyBounds3({
	layersData,
	onSearchChange,
	searchString,
	setSearchString,
	inputsForBoundCalc,
	customerName,
	showGlobalFilter = true,
	options,
	isLoading,
}: MapData<
	| {
			shipData: PlantInfo[]
			trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
	  }
	| undefined
>) {
	useAssignWindyMapDiv()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { isOpen, selectedTab } = useAppSelector(getPanelState)
	const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen)
	const [windyMap, setWindyMap] = useState<WindyMapType>()
	const companyData = common.companyDataFromCompanyName.get(customerName)

	const mapFiltersPanelState = useAppSelector(getMapFiltersPanelState)

	useInitWindyMap(
		dispatch,
		queryClient,
		setWindyMap,
		windyMap,
		companyData?.module === DipaiModule.OPERATION2 || companyData?.module === DipaiModule.DIPAI
			? navigate
			: undefined,
		options
	)

	useEffect(() => {
		const target = document.getElementById("windyHolder")
		// Create a new resize event
		if (target?.firstChild !== undefined && isPanelOpen !== isOpen) {
			windyMap?.resizemap()
			// var event = new Event("resize")
			// setTimeout(() => {
			// 	// Dispatch the event on the window object
			// 	window.dispatchEvent(event)
			// }, 500)
			setIsPanelOpen(isOpen)
		}
	}, [isOpen, windyMap])

	const selectedVessel = useAppSelector(getSelectedVessel)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)
	useAddLayersDataToMap(
		layersData,
		{ map: windyMap, options },
		undefined,
		customerName,
		plantData
	)
	useSetMapBoundsV3(windyMap, inputsForBoundCalc)
	const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null)

	return (
		<>
			{/* WINDY MAP CONTAINER */}
			<div
				ref={(newRef) => setMapRef(newRef)}
				id="windyHolder"
				className={`z-0 h-full w-full`}
			/>
			{/* GLOBAL FILTER */}
			{showGlobalFilter === true &&
			onSearchChange !== undefined &&
			setSearchString !== undefined ? (
				<div
					id={"globalFilter"}
					className="absolute top-[14px] left-16 inline-block rounded-md border-2 border-[rgba(0,0,0,0.2)]"
				>
					<div className="absolute inset-x-1 inset-y-1 h-6 w-6">
						<MdSearch className={`h-6 w-6`} />
					</div>
					<input
						className="text-menu shadow-card hover:border-alert-focus focus:border-alert-focus h-8 rounded-sm bg-white px-8 focus:border-1 focus:outline-hidden"
						value={searchString ?? ""}
						onChange={(e) => {
							setSearchString(e.target.value)
							onSearchChange(e.target.value)
						}}
						placeholder={t("SEARCH VESSEL BY NAME")}
					/>
					{searchString !== "" && searchString !== undefined && (
						<div
							className="text-gray-666 absolute inset-y-1 right-1 h-6 w-6 cursor-pointer"
							onClick={() => {
								setSearchString("")
								onSearchChange("")
							}}
						>
							<MdClear className={`h-6 w-6`} />
						</div>
					)}
				</div>
			) : null}

			{/* MAP BUTTONS */}
			<MapButtons map={windyMap} />

			{/* FILTERS PANEL */}
			<FiltersPanelMemo
				dispatch={dispatch}
				isOpen={mapFiltersPanelState.isOpen}
				mapRef={mapRef}
				plantData={plantData}
			/>

			{/* MAPTILE ATTRIBUTION */}
			<a
				id="maptilerLink"
				href="https://www.maptiler.com"
				className="absolute bottom-4 left-4 z-999 hidden"
			>
				<img
					src="https://api.maptiler.com/resources/logo.svg"
					alt="MapTiler logo"
				/>
			</a>
		</>
	)
}

export const FiltersPanelMemo = memo(
	({
		isOpen,
		dispatch,
		plantData,
		mapRef,
	}: {
		isOpen: boolean
		dispatch: ReturnType<typeof useAppDispatch>
		plantData: PlantObject | undefined
		mapRef: HTMLDivElement | null
	}) => {
		const { t } = useTranslation()
		return isOpen === true ? (
			<div
				style={{ maxHeight: mapRef !== null ? mapRef.clientHeight - 32 : undefined }}
				className="absolute top-[15px] left-[58px] z-401 flex w-[366px] flex-col gap-[0.125rem] overflow-auto rounded-sm border-0 bg-white/100 p-[2px]"
			>
				<div className="bg-dipai-secondary-901 flex h-10 min-h-10 w-[calc(100%)] items-center justify-between px-2">
					<p className={"text-title5 font-bold text-white"}>{t("MAP FILTERS")}</p>
					<Tooltip customContent={t("CLOSE")}>
						<IconButton
							size={"sm"}
							variant={"text-light"}
							onClick={() =>
								dispatch(
									updateMapFiltersPanelState({
										isOpen: false,
									})
								)
							}
						>
							<IconButton.Icon>{MdClose}</IconButton.Icon>
						</IconButton>
					</Tooltip>
				</div>
				<div className={`flex flex-col overflow-auto`}>
					<FeatureGuard
						featureName={PortalFeature.FACILITIES}
						plant={plantData}
					>
						<MapFiltersFacilitiesContent />
					</FeatureGuard>
					<FeatureGuard
						featureName={PortalFeature.OIL_AND_GAS}
						plant={plantData}
					>
						<MapFiltersOilContent />
					</FeatureGuard>
					<FeatureGuard
						featureName={PortalFeature.FISH_FARM}
						plant={plantData}
					>
						<MapFiltersFishContent />
					</FeatureGuard>
				</div>
			</div>
		) : null
	}
)
