import { RegularMapType } from "@components/map/regularMap/regularMap"
import { WindyMapType } from "@components/map/windyMap/windyMap"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getMapFiltersPanelState,
	updateMapFiltersPanelState,
} from "@redux/features/mapFiltersPanelState/mapFiltersPanelStateSlice"
import { Tooltip } from "@ui-lib/index"
import { t } from "i18next"
import { memo, useCallback, useState } from "react"
import { MdAir, MdCloudySnowing, MdFilterAlt, MdOutlineFilterAlt } from "react-icons/md"

type MapButtonsProps = {
	map?: WindyMapType | RegularMapType
}

export const MapButtons = memo(function MapButtons(props: MapButtonsProps) {
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
	const [isParticlesAnimation, setIsParticlesAnimation] = useState<boolean>(
		localStorage.getItem("settings_particlesAnim") !== null
			? JSON.parse(localStorage.getItem("settings_particlesAnim")!) === "off"
				? false
				: true
			: true
	)
	const dispatch = useAppDispatch()
	const mapFiltersPanelState = useAppSelector(getMapFiltersPanelState)

	const [isWeatherLayer, setIsWeatherLayer] = useState<boolean>(
		props.map?.getShowMapBoxTiles() ?? true
	)

	const myDocument = document.documentElement

	const mapMethods: Object | null | undefined =
		props.map === undefined ? undefined : Object.getPrototypeOf(props.map)

	const setFullScreen = () => {
		if (isFullScreen !== true) {
			if (myDocument.requestFullscreen !== undefined) {
				myDocument.requestFullscreen()
			}
			setIsFullScreen(true)
		} else {
			if (document.exitFullscreen !== undefined) {
				document.exitFullscreen()
			}
			setIsFullScreen(false)
		}
	}

	const onIsParticleAnimationChanged = useCallback(() => {
		if (props.map === undefined) {
			return
		}
		if ((mapMethods as Object).hasOwnProperty("setParticlesAnimation") === false) {
			return
		}
		;(props.map as WindyMapType).setParticlesAnimation()
		setIsParticlesAnimation(!isParticlesAnimation)
	}, [isParticlesAnimation, mapMethods, props.map])

	const onIsWeatherLayerChanged = useCallback(() => {
		if (props.map === undefined) {
			return
		}
		if ((mapMethods as Object).hasOwnProperty("setMapTilesVisibility") === false) {
			return
		}
		setIsWeatherLayer(props.map?.getShowMapBoxTiles())
		;(props.map as WindyMapType).setMapTilesVisibility()
	}, [props.map, mapMethods])

	const onIsShowFiltersPanelChanged = useCallback(() => {
		dispatch(
			updateMapFiltersPanelState({
				isOpen: mapFiltersPanelState.isOpen === true ? false : true,
			})
		)
	}, [dispatch, mapFiltersPanelState])

	return props.map !== undefined ? (
		<div
			style={{
				height:
					mapMethods !== undefined &&
					(mapMethods as Object).hasOwnProperty("setParticlesAnimation") === true
						? 96 + 2 - 0.4
						: 32 + 1,
			}}
			className="absolute top-20 z-402 ml-[14px] h-20 w-[35.4px] rounded-b-sm border-[1.7px] border-t-0 border-[rgba(0,0,0,0.2)]"
		>
			{/* FULL SCREEN BUTTON */}
			{/* {props.map !== undefined ? (
				<Tooltip side={"right"} customContent={t("FULL SCREEN")}>
					<div className="absolute left-[14px] top-20 z-401 h-8 w-9 border-x-2 border-[rgba(0,0,0,0.2)]">
						<button
							className="h-8 w-8 border-b-1 bg-white px-1 hover:bg-[#eee]"
							onClick={() => setFullScreen()}
						>
							{isFullScreen === false ? (
								<MdFullscreen className={`h-6 w-6`} />
							) : (
								<MdFullscreenExit className={`h-6 w-6`} />
							)}
						</button>
					</div>
				</Tooltip>
			) : null} */}
			{/* WEATHER DATA TOGGLE */}
			{props.map !== undefined &&
			mapMethods !== undefined &&
			(mapMethods as Object).hasOwnProperty("setParticlesAnimation") === true ? (
				<WeatherLayerTooltipMemo
					isWeatherLayer={isWeatherLayer}
					onIsWeatherLayerChanged={onIsWeatherLayerChanged}
				/>
			) : null}
			{/* PARTITLES ANIMATION TOGGLE */}
			{props.map !== undefined &&
			mapMethods !== undefined &&
			(mapMethods as Object).hasOwnProperty("setParticlesAnimation") === true ? (
				<ParticleAnimationTooltipMemo
					isParticlesAnimation={isParticlesAnimation}
					onIsParticleAnimationChanged={onIsParticleAnimationChanged}
				/>
			) : null}
			{/* FILTERS TOGGLE */}
			{props.map !== undefined ? (
				<MapFiltersTooltipMemo
					isOpen={mapFiltersPanelState.isOpen}
					mapMethods={mapMethods}
					onIsShowFiltersPanelChanged={onIsShowFiltersPanelChanged}
				/>
			) : null}
		</div>
	) : null
})

const WeatherLayerTooltipMemo = memo(function WeatherLayerTooltip({
	isWeatherLayer,
	onIsWeatherLayerChanged,
}: {
	isWeatherLayer: boolean
	onIsWeatherLayerChanged: () => void
}) {
	return (
		<Tooltip
			side={"right"}
			customContent={t(isWeatherLayer === true ? "HIDE WEATHER LAYER" : "SHOW WEATHER LAYER")}
		>
			<div className="absolute top-0 left-[0px] z-401 h-[34px] w-8 border-y-0 border-b-0 border-[rgba(0,0,0,0.2)]">
				<button
					className={`h-[32px] w-[32px] border-y-1 ${
						isWeatherLayer === true ? "bg-white" : "bg-slate-300 text-slate-700"
					} px-1 hover:bg-[#eee]`}
					onClick={onIsWeatherLayerChanged}
				>
					<MdCloudySnowing className={`h-6 w-6`} />
				</button>
			</div>
		</Tooltip>
	)
})

const MapFiltersTooltipMemo = memo(function MapFiltersTooltip({
	mapMethods,
	isOpen,
	onIsShowFiltersPanelChanged,
}: {
	mapMethods: Object | null | undefined
	isOpen: boolean
	onIsShowFiltersPanelChanged: () => void
}) {
	return (
		<Tooltip
			side={"right"}
			customContent={t("MAP FILTERS")}
		>
			<div
				style={{
					top:
						mapMethods !== undefined &&
						(mapMethods as Object).hasOwnProperty("setParticlesAnimation") === true
							? 64
							: -1,
				}}
				className="absolute left-[0px] z-401 h-[34px] w-8 rounded-b-md border-0 border-t-0 border-[rgba(0,0,0,0.2)]"
			>
				<button
					className={`h-[32px] w-[32px] rounded-b-sm border-t-1 ${
						isOpen === true ? "bg-white" : "bg-slate-300 text-slate-700"
					} px-1 hover:bg-[#eee]`}
					onClick={onIsShowFiltersPanelChanged}
				>
					{isOpen === true ? (
						<MdFilterAlt className={`h-6 w-6`} />
					) : (
						<MdOutlineFilterAlt className={`h-6 w-6`} />
					)}
				</button>
			</div>
		</Tooltip>
	)
})

const ParticleAnimationTooltipMemo = memo(function ParticleAnimationTooltip({
	isParticlesAnimation,
	onIsParticleAnimationChanged,
}: {
	isParticlesAnimation: boolean
	onIsParticleAnimationChanged: () => void
}) {
	return (
		<Tooltip
			side={"right"}
			customContent={t(
				isParticlesAnimation === false
					? "SHOW PARTICLES ANIMATION"
					: "HIDE PARTICLES ANIMATION"
			)}
		>
			<div className="absolute top-8 left-[0px] z-401 h-[34px] w-8 border-x-0 border-[rgba(0,0,0,0.2)]">
				<button
					className={`h-[32px] w-[32px] ${
						isParticlesAnimation === true ? "bg-white" : "bg-slate-300 text-slate-700"
					} px-1 hover:bg-[#eee]`}
					onClick={onIsParticleAnimationChanged}
				>
					<MdAir className={`h-6 w-6`} />
				</button>
			</div>
		</Tooltip>
	)
})
