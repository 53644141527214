import { Trigger } from "@radix-ui/react-tabs"
import { ReactElement } from "react"
import { useTranslation } from "react-i18next"

type TabHeaderProps = {
	activeTab: string
	tabIndex: number
	tabName: string
}

export function TabHeader({ activeTab, tabIndex, tabName }: TabHeaderProps): ReactElement {
	const { t } = useTranslation()

	return (
		<Trigger
			value={`${tabIndex}`}
			className={`border-b-2 ${
				activeTab === `${tabIndex}` ? "border-dipai-primary-501" : "border-transparent"
			}`}
		>
			<p
				className={`whitespace-nowrap rounded-md px-4 text-menu font-bold leading-[40px] ${
					activeTab === `${tabIndex}`
						? "cursor-default text-dipai-secondary-901"
						: "text-dipai-tertiary-801 hover:cursor-pointer hover:bg-gray-eee"
				}`}
			>
				{t(tabName)}
			</p>
		</Trigger>
	)
}
