export function decimalDegreesToDMS(decimalDegrees: number, isLongitude: boolean): string {
	// Degrees are the whole number part of the decimal
	const degrees = Math.floor(decimalDegrees)

	// Multiply the decimal fraction by 60 and take the whole number part for minutes
	const minutesDecimal = (decimalDegrees - degrees) * 60
	const minutes = Math.floor(minutesDecimal)

	// Multiply the decimal fraction of the minutes by 60 for seconds
	const seconds = (minutesDecimal - minutes) * 60
	const degreePadLength = isLongitude ? 3 : 2
	const paddedDegrees = degrees.toString().padStart(degreePadLength, "0")
	const paddedMinutes = minutes.toString().padStart(2, "0")
	const paddedSeconds = seconds.toFixed(2).padStart(5, "0") // 5 to account for decimal and two digits after decimal

	// Construct the string in DMS format
	return `${paddedDegrees}° ${paddedMinutes}' ${paddedSeconds}"`
}
