import { debounce } from "@ui-lib/index"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"

function SearchIcon() {
	return (
		<svg
			className=""
			viewBox="0 0 19.9 19.7"
		>
			<g
				className="search-path"
				fill="none"
				strokeWidth="2px"
				stroke="#848F91"
			>
				<path
					strokeLinecap="square"
					d="M18.5 18.3l-5.4-5.4"
				/>
				<circle
					cx="8"
					cy="8"
					r="7"
				/>
			</g>
		</svg>
	)
}

function ClearIcon() {
	return (
		<svg
			className="h-3 w-3"
			fill="none"
			viewBox="0 0 24 24"
			stroke="#848F91"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="4px"
				d="M0 24L24 0M0 0l24 24"
			/>
		</svg>
	)
}

export function GlobalFilter({
	globalFilter,
	setGlobalFilter,
	translationFunc,
}: {
	globalFilter: string
	setGlobalFilter: Dispatch<SetStateAction<string>>
	translationFunc: ReturnType<typeof useTranslation>["t"]
}) {
	const [value, setValue] = useState(globalFilter)
	const onChange = debounce((value: string) => {
		setGlobalFilter(value ?? undefined)
	}, 200)

	return (
		<div className="flex justify-end">
			<div className="relative inline-block">
				<div className="absolute inset-x-[6px] inset-y-[6px] h-5 w-5">
					<SearchIcon />
				</div>

				<input
					className="border-gray-ccc text-menu hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 h-8 w-[235px] rounded-sm border-1 bg-white pr-8 pl-8 focus:border-2 focus:pl-[31px] focus:outline-hidden"
					value={value ?? ""}
					onChange={(e) => {
						setValue(e.target.value)
						onChange(e.target.value)
					}}
					placeholder={`${translationFunc("SEARCH")} ${translationFunc(
						"ALL"
					)} ${translationFunc("RECORDS")}...`}
				/>

				{value !== "" && value !== undefined && (
					<div
						className="absolute inset-y-[10px] right-[2px] h-5 w-5 cursor-pointer"
						onClick={() => {
							setValue("")
							onChange("")
						}}
					>
						<ClearIcon />
					</div>
				)}
			</div>
		</div>
	)
}
