import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils"
import {
	ColumnDef,
	ExpandedState,
	FilterFn,
	FilterMeta,
	Row,
	SortingState,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FilterSectionProps } from "./FilterSectionWithExpandButton"
import { PaginationControlsProps } from "./PaginationControls"
import { TableHeaderProps } from "./TableHeader"
import { GenericTableLoadingBarProps } from "./TableLoadingBar"

declare module "@tanstack/table-core" {
	interface FilterFns {
		fuzzy: FilterFn<unknown>
	}
	interface FilterMeta {
		itemRank: RankingInfo
	}
}

export type TableBodyProps<T> = {
	columnsLayout?: string
	rows: Row<T>[]
	BodyContentLayout: React.FC<{ row: Row<T> }>
	columnsLayoutStyle?: string
}

export type TableProps<T> = {
	company?: "dipai" | "soelvtrans"
	buttonFunction?: () => void
	data: T[]
	columns: ColumnDef<T>[]
	columnVisibility?: { [columnName: string]: boolean }
	showGlobalFilter?: boolean
	hidePaginationControl?: boolean
	itemsPerPage?: number
	loading?: boolean
	columnsLayout?: string
	columnsLayoutStyle?: string
	TableBody: React.FC<TableBodyProps<T>>
	translationFunc: ReturnType<typeof useTranslation>["t"]
	headerStyle?: string
}

interface GenericTableProps<T> extends TableProps<T> {
	BodyContentLayout: React.FC<{ row: Row<T> }>
	FilterSection: React.FC<FilterSectionProps>
	PaginationControls: React.FC<PaginationControlsProps>
	TableHeader: React.FC<TableHeaderProps<T>>
	TableLoadingBar: React.FC<GenericTableLoadingBarProps>
}

function fuzzyFilter<T>(
	row: Row<T>,
	columnId: string,
	value: string,
	addMeta: (meta: FilterMeta) => void
) {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value)
	// Store the itemRank info
	addMeta({
		itemRank,
	})
	// Return if the item should be filtered in/out
	return itemRank.passed
}

export function Table<T>(props: GenericTableProps<T>): ReactElement {
	const [sorting, setSorting] = useState<SortingState>([])
	const [globalFilter, setGlobalFilter] = useState<string>("")
	const [expanded, setExpanded] = useState<ExpandedState>({})

	const [columnVisibility, setColumnVisibility] = useState<{ [columnName: string]: boolean }>(
		props.columnVisibility ?? {}
	)
	useEffect(() => {
		setExpanded({}) //closes expanded rows when data range or op mode change
	}, [props.data])

	useEffect(() => {
		setColumnVisibility((prev) =>
			props.columnVisibility !== undefined ? { ...props.columnVisibility } : {}
		)
	}, [props.columnVisibility])

	const table = useReactTable<T>({
		data: props.data,
		columns: props.columns,
		filterFns: {
			fuzzy: fuzzyFilter,
		},
		initialState: {},
		state: {
			sorting,
			globalFilter,
			expanded,
			columnVisibility,
		},
		autoResetPageIndex: false,
		globalFilterFn: fuzzyFilter,
		onGlobalFilterChange: setGlobalFilter,
		onExpandedChange: setExpanded,
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
	})

	return (
		<>
			<div className="mt-4 w-auto">
				{/* GLOBAL FILTER */}
				<props.FilterSection
					translationFunc={props.translationFunc}
					globalFilter={globalFilter}
					setGlobalFilter={setGlobalFilter}
					showGlobalFilter={props.showGlobalFilter}
					company={props.company}
					onClick={props.buttonFunction}
				/>
				{/* LOADING BAR */}
				<props.TableLoadingBar
					companyColors={{
						loadingBar: props.company === "soelvtrans" ? "#4FC7E7" : "#a2c0d7",
					}}
					loading={props.loading ?? false}
				/>
				{/* TABLE HEADER */}
				<div className={`mb-0 border-b-1 border-gray-300`}>
					<props.TableHeader
						columnsLayout={props.columnsLayout}
						columnsLayoutStyle={props.columnsLayoutStyle}
						headerGroups={table.getHeaderGroups()}
						className={props.headerStyle}
					/>
				</div>
				{/* TABLE BODY */}
				<props.TableBody
					BodyContentLayout={props.BodyContentLayout}
					columnsLayout={props.columnsLayout}
					columnsLayoutStyle={props.columnsLayoutStyle}
					rows={table.getRowModel().rows}
				/>
			</div>
			{/* PAGINATION CONTROLS */}
			{props.hidePaginationControl !== true ? (
				<props.PaginationControls
					translationFunc={props.translationFunc}
					getCanNextPage={table.getCanNextPage}
					getCanPreviousPage={table.getCanPreviousPage}
					getPageCount={table.getPageCount}
					nRecords={props.data.length}
					nextPage={table.nextPage}
					previousPage={table.previousPage}
					setPageIndex={table.setPageIndex}
					setPageSize={table.setPageSize}
					tableState={table.getState()}
				/>
			) : null}
		</>
	)
}
