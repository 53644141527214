import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import {
	createIntervalOffset,
	endpoints,
	GetLiveDataArgs,
	GetTimeseriesDataArgs,
	LastSensorChange,
	LiveData,
	TimeseriesData,
	TimeseriesInterval,
} from "api"
import { useQuery } from "react-query"
import { TopicObj } from "./types"

export function useGetLastTimeDataChangedQuery(args: { topics: TopicObj[] | undefined }) {
	let keys: string[] = []
	if (args.topics !== undefined) {
		keys = args.topics.map((topic) => topic.sensorId.toString())
	} else {
		keys[0] = "no source defined"
	}

	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetLastTimeDataChanged`,
			`endpoints=[${endpoints.getLastSensorChange.name}]`,
			...keys,
		],
		async (): Promise<LastSensorChange[] | undefined> => {
			if (args.topics === undefined) {
				return undefined
			}
			const lastSensorChanges = await requestApi2(endpoints.getLastSensorChange, {
				sensorIds: args.topics?.map((topic) => topic.sensorId),
			})
			if (lastSensorChanges === null) {
				return []
			}
			return lastSensorChanges
		},
		{
			staleTime: 60000,
			refetchInterval: 60000,
		}
	)
	return res
}

type GetLiveDataQueryArgs = { topics: TopicObj[] | undefined; listOfTopics: GetLiveDataArgs }
export function useGetLiveDataQuery(args: GetLiveDataQueryArgs) {
	let keys: string[] = []
	if (args.topics !== undefined) {
		keys = args.topics.map((topic) => topic.sensorId.toString())
	} else {
		keys[0] = "no source defined"
	}
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetLiveData`,
			`endpoints=[${endpoints.getLiveData.name}]`,
			...keys,
		],
		async (): Promise<LiveData[] | undefined> => {
			if (args.topics === undefined) {
				return undefined
			}
			const liveData = await requestApi2(endpoints.getLiveData, args.listOfTopics)
			if (liveData === null) {
				return []
			}
			return liveData
		},
		{
			staleTime: 60000,
			refetchInterval: 60000,
		}
	)

	return res
}

export function fetchRealTimeValue(
	topics: TopicObj[],
	minutes: number | undefined,
	interval: TimeseriesInterval | undefined
): Promise<TimeseriesData>

export async function fetchRealTimeValue(
	topics: TopicObj[],
	minutes: number | undefined,
	interval: TimeseriesInterval | undefined
): Promise<TimeseriesData> {
	const listOfTopics: GetLiveDataArgs = {
		sensors: [],
	}

	const postData: GetTimeseriesDataArgs = {
		interval:
			minutes !== undefined
				? {
						gte: new Date(createIntervalOffset(-minutes).from),
						lt: new Date(createIntervalOffset(-minutes).to),
				  }
				: interval !== undefined
				? { lt: new Date(interval?.to), gte: new Date(interval?.from) }
				: { lt: new Date(), gte: new Date(new Date().getTime() - 12 * 60 * 60 * 1000) },
		sensorIds: [],
	}

	const signalsData: number[] = []

	for (const topic of topics) {
		signalsData.push(topic.sensorId)
	}

	let resSensorData: TimeseriesData = { sensors: [] }
	if (signalsData.length > 0) {
		postData.sensorIds = signalsData
		const sensorDataTemp = await requestApi2(endpoints.getTimeseriesData4, postData)
		if (sensorDataTemp !== null) {
			resSensorData = sensorDataTemp
		}
	}

	return resSensorData
}

const createDataAgeString = (
	timeIntervalInSeconds: number,
	amountSec: number,
	timeRef: string
): string => {
	return `${Math.round(timeIntervalInSeconds / amountSec)} ${timeRef} ago`
}

export const getDataAge = (timestamp: string): string | undefined => {
	const currentTime: Date = new Date()
	const timeStampTime: Date = new Date(timestamp)
	if (isNaN(timeStampTime.getTime())) {
		return undefined
	}
	const timeIntervalInSeconds: number = (currentTime.getTime() - timeStampTime.getTime()) / 1000
	let dataAge: string

	const amountSecs = {
		sec: 1,
		min: 60,
		hour: 60 * 60,
		day: 24 * 60 * 60,
	}

	if (timeIntervalInSeconds / amountSecs["min"] < 1) {
		dataAge = createDataAgeString(timeIntervalInSeconds, amountSecs["sec"], "secs")
	} else if (timeIntervalInSeconds / amountSecs["hour"] < 1) {
		dataAge = createDataAgeString(timeIntervalInSeconds, amountSecs["min"], "mins")
	} else if (timeIntervalInSeconds / amountSecs["day"] < 1) {
		dataAge = createDataAgeString(timeIntervalInSeconds, amountSecs["hour"], "hours")
	} else {
		dataAge = createDataAgeString(timeIntervalInSeconds, amountSecs["day"], "days")
	}
	return dataAge
}
