import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { endpoints, FuelPageOpModeEventData } from "api"
import { useQuery } from "react-query"

type FuelEventsMetaDataQueryArgs = {
	plantId: number
	opModesId: number[]
	gte: Date
	lt: Date
}

export function useFuelEventsMetaDataQuery(args: FuelEventsMetaDataQueryArgs) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=FuelEventsMetaData`,
			`endpoints=[${endpoints.fuelPageGetEvents.name}]`,
			`plantId=${args.plantId}`,
			`opModesId=[${args.opModesId.sort()}]`,
			`gte=${args.gte?.toISOString !== undefined ? args.gte?.toISOString() : args.gte}`,
			`lt=${args.lt?.toISOString !== undefined ? args.lt?.toISOString() : args.lt}`,
		],
		async (): Promise<FuelPageOpModeEventData[] | undefined> => {
			const validOpModesId = args.opModesId.filter((opModeId) => opModeId !== -1)
			if (validOpModesId !== undefined && validOpModesId.length === 0) {
				return undefined
			}
			const response = await requestApi2(endpoints.fuelPageGetEvents, {
				plantId: args.plantId,
				opModeId: validOpModesId,
				interval: {
					t1: args.gte,
					t2: args.lt,
				},
			})

			return response !== null ? response : []
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}
