import { ReactElement, ReactNode } from "react"

export enum IconSize {
	small = "SMALL",
	medium = "MEDIUM",
	large = "LARGE",
}

export type IconProps = {
	size: IconSize
}

type IconSvgContainerProps = {
	size: IconSize
	fill?: boolean
	stroke?: boolean
	viewBox?: string
	children: ReactNode
}

export default function IconSvgContainer({
	size,
	children,
	viewBox = "0 0 24 24",
	fill = false,
	stroke = true,
}: IconSvgContainerProps): ReactElement {
	return (
		<svg
			className={`${
				size === IconSize.small
					? "h-4 w-4"
					: size === IconSize.medium
					? "h-6 w-6"
					: "h-12 w-12"
			}`}
			fill={`${fill === false ? "none" : "currentColor"}`}
			viewBox={viewBox}
			stroke={`${stroke === false ? "none" : "currentColor"}`}
		>
			{children}
		</svg>
	)
}
