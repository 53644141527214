import { InformationContainer } from "@components/containers/Containers"
import { IconId } from "@components/icon/IconId"
import KpiVesselsContent from "@components/kpi/KpiVesselsContent"
import { getFileName } from "@helpers/getFileName"
import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { LoadingCircle } from "@ui-lib/src"
import { common } from "@utils/common"
import { requestApi2 } from "@utils/http"
import { AggregationMethod, endpoints, EndpointSpec } from "api"
import { useQuery } from "react-query"

interface GetFuelConsumersOrProducersListQueryArgs<O> {
	plantId: number
	queryLabel: string
	endpoint: EndpointSpec<{ plantId: number }, O[]>
}
function useGetFuelConsumersOrProducersListQuery<O extends { sensorId: number; name: string }>(
	args: GetFuelConsumersOrProducersListQueryArgs<O>
) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=${args.queryLabel}`,
			`endpoints=[${args.endpoint.name}]`,
			`plantId=${args.plantId}`,
		],
		async (): Promise<O[] | undefined | null> => {
			if (args.plantId === -1) {
				return undefined
			}
			const listOfSourcers = await requestApi2(args.endpoint, { plantId: args.plantId })
			return listOfSourcers
		},
		{
			staleTime: 60000,
			refetchInterval: 60000,
		}
	)
	return res
}

export default function FuelAndPowerKpis({
	customerName,
	plantId,
}: {
	customerName: string
	plantId: number
}) {
	const selectedVessel = useAppSelector(getSelectedVessel)

	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)
	const {
		isLoading: isPowerProducersListLoading,
		isError: isPowerProducersListError,
		data: powerProducersList,
	} = useGetFuelConsumersOrProducersListQuery({
		plantId: plantData?.id ?? -1,
		queryLabel: "powerProducersLiveData",
		endpoint: endpoints.fuelPageGetPowerProducers,
	})

	const {
		isLoading: isFuelConsumersListLoading,
		isError: isFuelConsumersListError,
		data: fuelConsumersList,
	} = useGetFuelConsumersOrProducersListQuery({
		plantId: plantData?.id ?? -1,
		queryLabel: "fuelConsumersLiveData",
		endpoint: endpoints.fuelPageGetFuelConsumers,
	})

	return (
		<InformationContainer>
			<div className="grid grid-cols-[266.5px_2px_266.5px]">
				{powerProducersList !== undefined ? (
					powerProducersList !== null ? (
						<KpiVesselsContent
							customerName={customerName}
							iconId={IconId.Energy}
							label={"Energy production"}
							unit={"kW"}
							sensors={powerProducersList.map((producerData) => producerData.name)}
							topics={powerProducersList.map((producerData) => {
								return {
									sensorId: producerData.sensorId,
									aggregationMethod: AggregationMethod.SNAP_FIRST,
								}
							})}
						/>
					) : (
						<div className={``}>{"Data unavailable"}</div>
					)
				) : (
					<LoadingCircle containerHeightWithUnit="60px" />
				)}
				<div className="my-5 bg-gray-eee" />
				{fuelConsumersList !== undefined ? (
					fuelConsumersList !== null ? (
						<KpiVesselsContent
							customerName={customerName}
							iconId={IconId.Fuel}
							label={"Fuel consumption"}
							unit={"L/h"}
							sensors={fuelConsumersList.map((consumerData) => consumerData.name)}
							topics={fuelConsumersList.map((consumerData) => {
								return {
									sensorId: consumerData.sensorId,
									aggregationMethod: AggregationMethod.SNAP_FIRST,
								}
							})}
						/>
					) : (
						<div className={``}>{"Data unavailable"}</div>
					)
				) : (
					<LoadingCircle containerHeightWithUnit="60px" />
				)}
			</div>
		</InformationContainer>
	)
}
