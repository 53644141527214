import { ValueLabelPair } from "@utilityTypes/ValueLabelPair"
import { KeyboardEventHandler, ReactElement } from "react"
import ReactSelect, { ActionMeta, GroupBase, MultiValue, StylesConfig } from "react-select"

type MultiSelectArgs = {
	options: ValueLabelPair[]
	value: MultiValue<ValueLabelPair>
	onChange: (args: MultiValue<ValueLabelPair>, am: ActionMeta<ValueLabelPair>) => void
	required?: boolean
	error?: boolean
	warning?: boolean
	onKeyDown?: KeyboardEventHandler
	isClearable: boolean
	tabIndex?: number
	maxAmountItems?: number
	minAmountItems?: number
	placeHolderText?: string
	isDisabled?: boolean
}

export default function MultiSelect({
	options,
	value,
	onChange,
	required = false,
	error = false,
	warning = false,
	onKeyDown,
	isClearable,
	tabIndex,
	maxAmountItems,
	minAmountItems,
	placeHolderText,
	isDisabled = false,
}: MultiSelectArgs): ReactElement {
	const styles = (
		stateValue: MultiValue<ValueLabelPair>
	): StylesConfig<ValueLabelPair, true, GroupBase<ValueLabelPair>> => ({
		menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 }),
		valueContainer: (baseStyles) => ({ ...baseStyles, padding: "0px 0px 0px 0px" }),
		container: (baseStyles) => ({ ...baseStyles, padding: "0px 0px 0px 0px" }),
		control: (baseStyles, state) => {
			return {
				...baseStyles,
				minHeight: 32,
				borderColor:
					(stateValue.length === 0 && required) || error
						? state.menuIsOpen === true
							? "rgb(185 28 28)"
							: "rgb(239 68 68)"
						: warning
						? state.menuIsOpen === true
							? "rgb(180 83 9)"
							: "rgb(245 158 11)"
						: "#ccc",
				boxShadow:
					state.menuIsOpen === true
						? (stateValue.length === 0 && required) || error
							? "0 0 0 1px rgb(185 28 28)"
							: warning
							? "0 0 0 1px rgb(180 83 9)"
							: "0 0 0 1px #2684FF"
						: "0",
				borderRadius: 2,
				":hover": {
					borderColor:
						(stateValue.length === 0 && required) || error
							? "rgb(185 28 28)"
							: warning
							? "rgb(180 83 9)"
							: "#ccc",
				},
			}
		},
		input: (baseStyles) => ({ ...baseStyles, height: 20, padding: 0 }),
		placeholder: (baseStyles) => ({
			...baseStyles,
			padding: 0,
			margin: "0px 0px 3px 4px",
		}),
		multiValue: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 2px 4px" }),
		multiValueLabel: (baseStyles) => ({ ...baseStyles, padding: 0, margin: 0 }),
		dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		indicatorsContainer: (baseStyles) => ({ ...baseStyles, height: 32 }),
		clearIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		option: (baseStyles) => ({
			...baseStyles,
			padding: 4,
			fontSize: "14px",
			borderRadius: 2,
			border: "1px solid rgba(204, 204, 204, 0.5)",
		}),
		menuList: (baseStyles) => ({
			...baseStyles,
			gap: 4,
			gridTemplateColumns: "repeat(auto-fit, minmax(33%, 1fr))",
			display: "grid",
			padding: 4,
		}),
	})

	const selectAllOption: ValueLabelPair | undefined =
		options.length < 2 ||
		(maxAmountItems !== undefined && options.length > maxAmountItems) ||
		minAmountItems !== undefined
			? undefined
			: {
					value: "<SELECT_ALL>",
					label: "All items",
			  }

	const isSelectAllSelected = () => value.length === options.length

	const isOptionSelected = (option: ValueLabelPair) =>
		value.some(({ value }) => value === option.value) || isSelectAllSelected()

	const getOptions = () =>
		selectAllOption !== undefined ? [selectAllOption, ...options] : options

	const getValue = () => {
		return isSelectAllSelected() && selectAllOption !== undefined ? [selectAllOption] : value
	}
	const onSelectionChange = (
		newValue: MultiValue<ValueLabelPair>,
		actionMeta: ActionMeta<ValueLabelPair>
	) => {
		const { action, option, removedValue } = actionMeta
		if (
			maxAmountItems !== undefined &&
			value.length >= maxAmountItems &&
			action === "select-option"
		) {
			return
		}
		if (
			minAmountItems !== undefined &&
			value.length <= minAmountItems &&
			(action === "deselect-option" || action === "remove-value")
		) {
			return
		}
		if (
			action === "select-option" &&
			selectAllOption !== undefined &&
			option?.value === selectAllOption.value
		) {
			onChange(options, actionMeta)
		} else if (
			(action === "deselect-option" &&
				selectAllOption !== undefined &&
				option?.value === selectAllOption.value) ||
			(action === "remove-value" &&
				selectAllOption !== undefined &&
				removedValue.value === selectAllOption.value)
		) {
			onChange([], actionMeta)
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			onChange(
				options.filter(({ value }) => value !== option?.value),
				actionMeta
			)
		} else {
			onChange(newValue ?? [], actionMeta)
		}
	}

	return (
		<ReactSelect
			isDisabled={isDisabled}
			placeholder={placeHolderText}
			tabIndex={tabIndex}
			classNamePrefix="mySelect"
			isOptionSelected={isOptionSelected}
			options={getOptions()}
			value={getValue()}
			onChange={onSelectionChange}
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			isMulti
			isClearable={isClearable}
			styles={styles(getValue())}
			isSearchable={true}
			menuPortalTarget={document.body}
			onKeyDown={onKeyDown}
		/>
	)
}
