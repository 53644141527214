import { Label } from "@components/toggleSwitch/ToggleSwitch"
import { LabelPosition, ToggleStyle } from "@components/toggleSwitch/types"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { ReactElement } from "react"

export type RadioGroupProps = {
	disabled?: boolean | boolean[]
	initialState?: string
	labelPosition?: LabelPosition
	labels: string[]
	onChange?: (value: string) => void
	style?: ToggleStyle
	textColor?: string
}

export default function RadioGroup({
	disabled = false,
	initialState = "0",
	labelPosition = LabelPosition.right,
	labels,
	onChange,
	style = ToggleStyle.Rounded,
	textColor,
}: RadioGroupProps): ReactElement {
	const onValueChanged = (value: string) => {
		if (onChange !== undefined) {
			onChange(value)
		}
	}

	return (
		<RadioGroupPrimitive.Root
			value={initialState}
			aria-label="View density"
			onValueChange={(value) => onValueChanged(value)}
		>
			{labels.map((label, index) => (
				<div
					key={index}
					className={`${
						Array.isArray(disabled) === true
							? disabled[index] === true
								? "opacity-35"
								: ""
							: disabled === true
							? "opacity-35"
							: ""
					} flex items-center pl-[10px] hover:bg-gray-f4`}
				>
					<RadioGroupPrimitive.Item
						className={`h-[17px] w-[17px] ${
							style === ToggleStyle.Rounded ? "rounded-full" : "rounded-md"
						} border-1 ${
							initialState === `${index}` ? "border-gray-666" : "border-gray-999"
						} bg-dipai-secondary-800 outline-hidden hover:border-gray-666`}
						disabled={Array.isArray(disabled) === true ? disabled[index] : disabled}
						value={`${index}`}
						id={`r${index}`}
					>
						<RadioGroupPrimitive.Indicator
							// className={`ml-[1.2px] mt-[1px] block h-[12px] w-[12px] ${
							// 	style === ToggleStyle.Rounded ? "rounded-full" : "rounded-md"
							// } bg-blue-500`}
							className={`relative flex h-full w-full items-center justify-center after:block after:h-[9px] after:w-[9px] ${
								style === ToggleStyle.Rounded
									? "after:rounded-full"
									: "after:rounded-md"
							} after:bg-white after:content-[""]`}
						/>
					</RadioGroupPrimitive.Item>

					<Label
						id={`r${index}`}
						label={label}
						labelPosition={labelPosition}
						textColor={textColor ?? "text-gray-666"}
					/>
				</div>
			))}
		</RadioGroupPrimitive.Root>
	)
}
