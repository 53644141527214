import { PageInfo } from "edge-admin-api"
import { useEffect, useState } from "react"
import Button from "../Button"

export default function NewPlantCell(args: {
	pageInfo: PageInfo | undefined
	deviceIp: string
	onSubmit: (
		companyId: number,
		plantName: string,
		mmsi: number | null,
		imo: number | null
	) => void
}) {
	const [companyId, setCompanyId] = useState<number>()
	const [plantName, setPlantName] = useState("")
	const [mmsi, setMmsi] = useState<string>()
	const [imo, setImo] = useState<string>()

	useEffect(() => {
		const companies_0 = args.pageInfo?.companies[0]
		if (companies_0 !== undefined) {
			setCompanyId(companies_0.id)
		}
	}, [args])

	const onSubmit = () => {
		const company = args.pageInfo?.companies.find((c) => c.id == companyId)
		if (company == undefined) {
			throw new Error(`Could not find company by id: ${companyId}`)
		}
		const confirmed = confirm(
			`Warning! You are about to create a new plant and give the edge device permission to send data as its device\nPlease make sure this information is correct\n\ndevice ip: ${
				args.deviceIp
			}\nowner of plant: ${company.name} (id: ${
				company.id
			})\nplant display name: "${plantName}"\nplant mmsi: ${
				mmsi ?? "(no mmsi)"
			}\n\nAre you sure? Click OK to confirm.`
		)
		if (confirmed == true) {
			const mmsiNumber = mmsi != undefined ? parseFloat(mmsi) : null
			const imoNumber = imo != undefined ? parseFloat(imo) : null
			args.onSubmit(company.id, plantName, mmsiNumber, imoNumber)
		}
	}

	return (
		<div className={`flex flex-row gap-2 px-1`}>
			{args.pageInfo == undefined ? (
				<p>loading...</p>
			) : (
				<>
					<input
						className={`h-8 px-2 py-0`}
						style={{ border: "1px solid lightgray" }}
						placeholder="(plant display name)"
						onChange={(evt) => setPlantName(evt.target.value)}
					/>
					<input
						className={`h-8 px-2 py-0`}
						style={{ border: "1px solid lightgray" }}
						placeholder="(mmsi)"
						onChange={(evt) => setMmsi(evt.target.value)}
					/>
					<input
						className={`h-8 px-2 py-0`}
						style={{ border: "1px solid lightgray" }}
						placeholder="(imo)"
						onChange={(evt) => setImo(evt.target.value)}
					/>
					<select
						className={`h-8 px-2 py-0`}
						onChange={(evt) => setCompanyId(parseFloat(evt.target.value))}
					>
						{args.pageInfo.companies.map((p, index) => (
							<option key={index} value={p.id} label={p.name} />
						))}
					</select>
					<Button label="permit" onClick={onSubmit} />
				</>
			)}
		</div>
	)
}
