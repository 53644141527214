import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { getListOfRanges } from "@sop/FuelPage/Total/utils/useGetRangesDateswithQuarters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

const ConditionBasedMaintenanceModules = ["engines", "thrusters", "utilization"] as const
export type ConditionBasedMaintenanceModulesList = (typeof ConditionBasedMaintenanceModules)[number]

const dateSchema = z.preprocess((val) => {
	if (typeof val === "string") {
		const parsedDate = new Date(val)
		if (!isNaN(parsedDate.getTime())) {
			return parsedDate.getTime()
		}
	} else if (typeof val === "number" && !isNaN(val)) {
		return val
	}
	return undefined
}, z.number().int().min(0).optional())

const MIN_YEAR_CBM = 2020
const minDateFrom = new Date(`${MIN_YEAR_CBM}-01-01`).getTime()

export const cbmPageSearchSchema = z
	.object({
		vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
		opMode: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of opModes
		tab: z.optional(z.enum(ConditionBasedMaintenanceModules)).catch("engines"),
		toCBM: dateSchema,
		fromCBM: dateSchema,
		rangeCBM: z
			.optional(
				z.union(
					getListOfRanges().map((role) => z.literal(role)) as [
						z.ZodLiteral<string>,
						Zod.ZodLiteral<string>,
						...Zod.ZodLiteral<string>[]
					]
				)
			)
			.catch("custom"),
		performanceParamCBM: z.optional(z.string()).catch(undefined),
	})
	.superRefine((data, ctx) => {
		let hasErrors = false
		if (
			(data.fromCBM !== undefined && data.toCBM === undefined) ||
			(data.fromCBM === undefined && data.toCBM !== undefined)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Both 'fromCBM' and 'toCBM' must be provided together",
				path: ["fromCBM"],
			})
			hasErrors = true
		}

		if (data.fromCBM !== undefined && data.toCBM !== undefined && data.fromCBM >= data.toCBM) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromCBM' must be before 'toCBM'",
				path: ["fromCBM"],
			})
			hasErrors = true
		}

		if (data.fromCBM !== undefined && data.fromCBM < minDateFrom) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromCBM' cannot be earlier than 2022-01-01",
				path: ["fromCBM"],
			})
			hasErrors = true
		}

		if (hasErrors === true) {
			data.fromCBM = undefined
			data.toCBM = undefined
			data.rangeCBM = "last 4 quarters"
		} else if (data.fromCBM !== undefined && data.toCBM !== undefined) {
			data.rangeCBM = "custom"
		}
	})

export const validateCbmSearchParams = (
	inputParams: Record<string, unknown>
): z.infer<typeof cbmPageSearchSchema> => {
	const result = cbmPageSearchSchema.safeParse(inputParams)

	if (result.success === false) {
		return {
			...inputParams,
			fromCBM: undefined,
			toCBM: undefined,
			rangeCBM: "last 4 quarters",
		}
	}

	return { ...inputParams, ...result.data }
}

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/cbm")({
	component: RouteComponent,
	validateSearch: validateCbmSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(cbmPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
